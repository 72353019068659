import { createFeatureSelector, createSelector } from '@ngrx/store';
import { employeesFeatureKey, employeesPageAdapter, EmployeeState } from './employees.reducer';
import { Dictionary } from '@ngrx/entity';
import { Employee } from '@pos-common/classes/employee.class';

export const selectEmployeesPage = createFeatureSelector(employeesFeatureKey);

const { selectAll, selectEntities } = employeesPageAdapter.getSelectors();

const selectAllEmployees = selectAll;
const selectAllEntities = selectEntities;

const selectEmployeesEntities = createSelector(
  selectEmployeesPage,
  selectAllEntities
);

export const selectLoadingStatusEmployees = createSelector(
  selectEmployeesPage,
  (employeesPage: EmployeeState): boolean => employeesPage.isLoading
);

export const selectEmployees = createSelector(
  selectEmployeesPage,
  selectAllEmployees
);

export const selectActiveEmployeeUuid = createSelector(
  selectEmployeesPage,
  (employeesPage: EmployeeState): string => employeesPage.activeEmployeeUuid
);

export const selectActiveEmployee = createSelector(
  selectEmployeesEntities,
  selectActiveEmployeeUuid,
  (entities: Dictionary<Employee>, uuid: string): Employee => {
    return entities[uuid];
  }
);

export const selectEmployeeByUuid = (uuid: string) => createSelector(
  selectEmployeesEntities,
  (entities: Dictionary<Employee>): Employee => {
    return entities[uuid];
  }
);
