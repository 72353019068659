import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicImageLoaderModule } from '@spryrocks/ionic-image-loader-v5';
import { AppMainModule } from './app.component';
import { AppCommonModule } from '../common/app-common.module';
import { PaymentProcessingModule } from '../common/components/payment-processing/payment-processing.module';
import { GiftCardModule } from '../modules/assing-gift-card';
import { AppRoutingModule } from './app-routing.module';
import { IonicModule } from '@ionic/angular';
import { MyPosModule } from '../modules/my-pos/my-pos.module';
import { MyPosMiniModule } from '../modules/my-pos-mini/my-pos-mini.module';
import { ServicesModule } from '../common/services/services.module';
import { CurrentUserModule } from '@pos-common/components/current-user/current-user.component';
import { NotifyPopupsModule } from '@pos-common/components/notify-popups/notify-popups.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage';
import { ApiModule } from '@pos-common/services/api/api.module';
import { ReceiptPrintersModule } from '@pos-common/services/system/receipt-printers/receipt-printers.module';
import { DbModule } from '@pos-common/services/db/db.module';
import { LoggerModule } from '@pos-common/services/system/logger/logger.module';
import { NullifyModalModule } from '@pos-common/components/nullify-modal/nullify-modal.component';
import { PreloadNextModules } from '@pos-common/services/system';
import { initSentry } from './sentry';
import { MenuComponentModule } from '@pos-modules/shared/components/menu/menu.component';
import { PAYMASH_ENV } from '@environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@pos-stores/reducers';
import { effects } from '@pos-stores/effects';
import { facades } from '@pos-stores/facades';

const disableAnimations =
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent) && window.cordova);

if (window.cordova) {
  initSentry();
}

export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicImageLoaderModule,
    AppCommonModule,
    GiftCardModule.forRoot(),
    MyPosModule,
    MyPosMiniModule.forRoot(),
    PaymentProcessingModule.forRoot(),
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true } as any),
    AppRoutingModule,
    HammerModule,
    ServicesModule,
    CurrentUserModule,
    NotifyPopupsModule,
    IonicStorageModule,
    ApiModule.forRoot(),
    ReceiptPrintersModule.forRoot(),
    DbModule,
    LoggerModule,
    NullifyModalModule,
    MenuComponentModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: PAYMASH_ENV.enabledConsoleOutput, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
  ],
  providers: [
    PreloadNextModules,
    facades
  ],
  declarations: [AppMainModule],
  bootstrap: [AppMainModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
