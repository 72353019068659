import { ICustomPrice, IDiscountUpdate, IInventoryUpdate } from '@pos-common/interfaces';
import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Product } from '../../../common/classes/product.class';
import { ProductVariant } from '../../../common/classes/product-variant.class';
import { InvoiceEntry, SpecialTaxing } from '../../../common/classes/invoice-entry.class';
import { Store } from '../../../common/classes/store.class';
import { DISCOUNTS_TYPES } from '../../../common/constants/discounts-types.const';
import { DbDaoService } from '../../../common/services/db/db-dao.service';
import { CartService } from '../../../common/services/system/cart.service';
import { MathUtils } from '../../../common/services/utils/math.utils';
import { SecurityService } from '../../../common/services/system/security.service';
import { UPDATES_TYPES } from '../../../common/constants/updates-types.const';
import { PRODUCT_TYPES } from '../../../common/constants/product-types';
import { TableEnforceService } from '../../../common/services/system/table-select-enforcement.service';
import { IMAGE_SIZES } from '../../../common/classes/image.class';
import { LogService } from '../../../common/services/system/logger/log.service';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { SubSink } from 'subsink';
import { modalPageAnimation } from 'app/app.animations';
import { Employee } from '@pos-common/classes/employee.class';
import { contains, oneOf, query } from '@paymash/capacitor-database-plugin';
import { FormControl, FormGroup } from '@angular/forms';
import { QuantityModificationActions } from '@pos-common/constants/quantity-modification.actions';
import { GenerateRandomColor } from '@pos-common/classes';
import { EmployeesFacadeStore } from '@pos-stores/employees';

@Component({
  selector: 'product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [modalPageAnimation()],
})
export class ProductDetailsModal {
  // tabs
  public tabs: any = {
    IMAGES: 'slider',
    INFO: 'info',
  };
  public currentTab: string = this.tabs.INFO;
  // images
  public IMAGE_SIZES: any = IMAGE_SIZES;
  public imagesLoaded: boolean = false;
  public imageStatusCallback: Function;
  // inbox data
  public modalSource: string = null;
  public product: Product = null;
  public productCategory: any = null;
  public variants: Array<ProductVariant> = [];
  public invoiceEntry: InvoiceEntry = null;
  public currentStore: Store;
  public inventories: any;
  // ui fields
  public newEntry: boolean = false;
  public selectedVariant: ProductVariant = null;
  public variantShortName: string = '';
  public note: string = '';
  public priceCents: any = 0;
  public priceDecimal: any = 0;
  public quantity: number = 1;
  public discountedTotalAmount: number = 0;
  // inventory info
  public selectedVariantInventoryInCurrentStore: number = 0;
  public selectedVariantInventoryInOtherStore: number = 0;
  public inventoryActive: boolean = false;
  public inventoriesActive: boolean = false;
  public stores: Array<Store> = [];
  // variants selection
  public variantSelectionActive: boolean = false;
  public variantSelectedCallback: Function;
  // free pricing
  public tempPriceCents: any = 0;
  public tempPriceDecimal: any = 0;
  public freePricingActive: boolean = false;
  // discount
  public discountType: string = DISCOUNTS_TYPES.PERCENTAGE;
  public discountTypeTmp: string = DISCOUNTS_TYPES.PERCENTAGE;
  public discount: any = 0;
  public discountPercentage: any = 0;
  public discountCents: any = '';
  public discountDecimal: any = 0;
  public discountCentsTmp: any = '';
  public discountDecimalTmp: any = 0;
  public discountActive: boolean = false;
  //current company
  public currentCompany: any;
  public defaultTakeAwayCompanyVATRateValue: number = null;
  public assignedGiftCardNumber: string = null;
  public productTypes = PRODUCT_TYPES;
  public slideOpts = {
    pagination: false,
  };
  public productForm: FormGroup;

  public activeEmployee: Employee;
  private guestNumber: number = null;
  private subs = new SubSink();
  private readonly logger = this.logService.createLogger('ProductDetailsModal');

  constructor(
    public DbDaoService: DbDaoService,
    public CartService: CartService,
    private viewController: ModalController,
    public navParams: NavParams,
    public MathUtils: MathUtils,
    public SecurityService: SecurityService,
    public TableEnforceService: TableEnforceService,
    public assignGiftCardService: GiftCardProvider,
    private invoicesService: InvoicesService,
    private logService: LogService,
    private employeeFacadeStore: EmployeesFacadeStore
  ) {
    this.variantSelectedCallback = this.variantSelected.bind(this);
    this.imageStatusCallback = this.imageStatusChanged.bind(this);
    this.productForm = new FormGroup({
      quantity: new FormControl(),
    });
  }

  ngOnInit() {
    // get params data
    this.product = this.navParams.get('product') ? new Product(this.navParams.get('product')) : null;
    this.updateSlidePagination(this.product);
    this.productCategory = this.navParams.get('productCategory');
    this.modalSource = this.navParams.get('source');

    // get current store
    this.currentStore = this.SecurityService.getActiveStore();

    this.activeEmployee = this.employeeFacadeStore.activeEmployeeSnapshot;
    //get current company
    this.currentCompany = this.SecurityService.getLoggedCompanyData();
    this.subs.sink = this.SecurityService.loggedCompanyData.subscribe((data) => {
      if (data) this.currentCompany = data;
      if (this.currentCompany?.isTakeAwayEnabled && this.currentCompany?.defaultTakeAwayCompanyVATRate) {
        this.DbDaoService.getDataByUUID(UPDATES_TYPES.VATRate.type, this.currentCompany.defaultTakeAwayCompanyVATRate['uuid']).then(
          (data) => {
            this.defaultTakeAwayCompanyVATRateValue = data?.data?.value || null;
          }
        );
      }
    });

    // detect is newEntry
    if (this.modalSource === 'collection') {
      this.newEntry = true;
    } else {
      this.newEntry = false;
      this.invoiceEntry = this.navParams.get('invoiceEntry');

      // set data from invoice entry to ui
      this.note = this.invoiceEntry.note;
      this.quantity = this.invoiceEntry.quantity;
      this.assignedGiftCardNumber = this.invoiceEntry.giftCardPhysicalCode;
      this.guestNumber = this.invoiceEntry.guestNumber;
    }
    this.setFormValues(this.invoiceEntry);
    this.subs.sink = this.productForm.controls.quantity.valueChanges.subscribe((value) => this.setNewQuantity(value));

    if (!this.product && this.invoiceEntry) {
      if (this.invoiceEntry) this.setInitialDiscounts();
      const queryParams = query({
        productVariantsUuid: contains(this.invoiceEntry.productVariant['uuid']),
      });
      this.DbDaoService.getAllData(UPDATES_TYPES.Product.type, queryParams)
        .then((data) => {
          this.product = new Product(data['data'][0]);
          this.product.bgColor = this.product.bgColor || new GenerateRandomColor().generateColorByText(this.product.name);
          this.updateSlidePagination(this.product);
          return this.setVariants();
        })
        .then(() => this.setInventories())
        .catch(() => {
          // TODO ADJUST THIS LOGIC
          // REMOVE ENTRY FROM CART, BECAUSE VARIANT WAS DELETED
          if (this.invoiceEntry) {
            this.CartService.changeEntryQuantity(this.invoiceEntry, -this.invoiceEntry.quantity);
          }
          this.dismissModal();
        });
    } else {
      this.setVariants()
        .then(() => this.setInventories())
        .catch((err) => this.logger.error(err, 'ngOnInit:setVariants'));
    }
  }

  get quantityInvalid(): boolean {
    return this.productForm?.get('quantity')?.invalid;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setFormValues(invoiceEntryData: InvoiceEntry) {
    let quantity = 1;
    if (invoiceEntryData) {
      quantity = invoiceEntryData.quantity;
    }
    this.productForm.patchValue({ quantity });
  }

  private dismissModal() {
    this.viewController.dismiss().catch((err) => this.logger.error(err, 'dismissModal:dismiss'));
  }

  private setVariants(): Promise<any> {
    return this.DbDaoService.getAllData(UPDATES_TYPES.ProductVariant.type, { productUuid: this.product.uuid }).then((data) => {
      this.variants = data['data'];
    });
  }

  public setInventories() {
    this.DbDaoService.getAllData(UPDATES_TYPES.Store.type, { isWebshopStore: false })
      .then((dbResponse) => {
        this.stores = dbResponse.data.map((store) => new Store(store));
        const productVariantUuids = this.variants.map((variant) => variant.uuid);
        return this.DbDaoService.getAllData(UPDATES_TYPES.Inventory.type, { productVariantUuid: oneOf(...productVariantUuids) });
      })
      .then((dbResponse) => this.calculateInventories(dbResponse.data))
      .catch((err) => this.logger.error(err, 'setInventories:getAllDataFromCollection'));
  }

  public calculateInventories(inventories: any[]) {
    const result = {};
    const storesGrouped = this.stores.reduce((r, store) => ({ ...r, [store.uuid]: store }), {});

    for (let i = 0; i < inventories.length; i++) {
      const current = inventories[i];
      if (!storesGrouped[current.store.uuid]) {
        continue;
      }

      if (result[current.productVariant.uuid]) {
        if (!result[current.productVariant.uuid][current.store.uuid]) {
          result[current.productVariant.uuid][current.store.uuid] = {
            count: 0,
            uuid: current.store.uuid,
            name: storesGrouped[current.store.uuid].name || 'store',
          };
        }
      } else {
        result[current.productVariant.uuid] = {};
        result[current.productVariant.uuid].inOtherStore = 0;
        result[current.productVariant.uuid][current.store.uuid] = {
          count: 0,
          uuid: current.store.uuid,
          name: storesGrouped[current.store.uuid].name || 'store',
        };
      }

      result[current.productVariant.uuid][current.store.uuid].count += current.quantity;

      if (current.store.uuid !== this.currentStore.uuid) {
        result[current.productVariant.uuid].inOtherStore += current.quantity;
      }
    }

    this.inventories = result;

    this.detectCurrentVariant();
  }

  public toggleInventories() {
    if (this.stores.length < 2 || !this.selectedVariant || (this.selectedVariant && !this.selectedVariant.trackInventory)) return false;
    this.inventoriesActive = !this.inventoriesActive;
  }

  public toogleInventory() {
    if (this.selectedVariant && this.selectedVariant.trackInventory && this.inventories && this.activeEmployee?.hasAdminPermission) {
      this.inventoryActive = !this.inventoryActive;
    }
  }

  updateInventory(inventoryUpdate?: IInventoryUpdate) {
    this.toogleInventory();
    if (inventoryUpdate) {
      this.selectedVariantInventoryInCurrentStore = inventoryUpdate.inventory;
      this.selectedVariant.defaultCost = inventoryUpdate.defaultCost;
    }
  }

  public detectCurrentVariant() {
    if (!this.product.hasCustomOptions) {
      this.setSelectedVariant(this.variants[0]);
    } else if (this.invoiceEntry) {
      let variantToSet = this.variants.find((variant) => variant.uuid === this.invoiceEntry.productVariant.uuid);
      // TODO HANDLE CASE WHEN ADDED TO CART VARIANT WAS DELETED
      if (!variantToSet) variantToSet = this.variants[0];
      this.setSelectedVariant(variantToSet);
    } else {
      let firstVariant = this.variants[0];
      this.priceCents = '0' + MathUtils.roundHalfUp(firstVariant.price * 100, 2).toString();
      this.priceDecimal = firstVariant.price;
      this.calculateDiscountedTotalAmount();
    }
  }

  public toggleVariantsSelection() {
    this.variantSelectionActive = !this.variantSelectionActive;
  }

  public variantSelected(variant?: ProductVariant) {
    this.toggleVariantsSelection();
    if (variant) this.setSelectedVariant(variant);
  }

  public setSelectedVariant(variant: ProductVariant) {
    // TODO REMOVE DUPLICATION WHEN PRICE ISSUES WILL BE ADJUSTED
    if (this.selectedVariant && this.selectedVariant.uuid !== variant.uuid) this.selectedVariant = variant;

    if (variant.options) this.variantShortName = this.generateVariantShortName(variant, ', ');
    if (!this.selectedVariant && this.invoiceEntry) {
      this.priceCents = '0' + MathUtils.roundHalfUp(this.invoiceEntry.price * 100, 2).toString();
      this.priceDecimal = this.invoiceEntry.price;
    } else {
      this.priceCents = '0' + MathUtils.roundHalfUp(variant.price * 100, 2).toString();
      this.priceDecimal = variant.price;
    }

    // TODO REMOVE DUPLICATION WHEN PRICE ISSUES WILL BE ADJUSTED
    if (!this.selectedVariant || (this.selectedVariant && this.selectedVariant.uuid !== variant.uuid)) {
      this.selectedVariant = variant;
    }

    if (!this.selectedVariant.isTakeAwayAllowed) {
      this.selectedVariant.isTakeAwayAllowed = false;
    }
    if (this.selectedVariant.isTakeAwayAllowed && this.invoiceEntry && !this.invoiceEntry.specialTaxing) {
      this.invoiceEntry.specialTaxing = SpecialTaxing.inHouse;
    }

    this.calculateDiscountedTotalAmount();

    // get inventories for selected variant
    this.selectedVariantInventoryInOtherStore = 0;
    this.selectedVariantInventoryInCurrentStore = 0;
    if (this.inventories && this.inventories[this.selectedVariant.uuid]) {
      this.selectedVariantInventoryInOtherStore = this.inventories[this.selectedVariant.uuid].inOtherStore;
      if (this.inventories[this.selectedVariant.uuid].hasOwnProperty(this.currentStore.uuid)) {
        this.selectedVariantInventoryInCurrentStore = this.inventories[this.selectedVariant.uuid][this.currentStore.uuid].count;
      }
    }
  }

  public sortVariants(variants: Array<any>) {
    return variants.sort((a: any, b: any) => {
      return a.name > b.name ? 1 : 0;
    });
  }

  public generateVariantShortName(variant: ProductVariant, separator: string) {
    let variantShortName = '';
    for (let i = 0; i < variant.options.length; i++) {
      let current = variant.options[i];
      variantShortName += current['value'] + separator;
    }
    return variantShortName.slice(0, -separator.length);
  }

  public toggleFreePricing() {
    if (this.product.forcePriceInputOnSale) this.freePricingActive = !this.freePricingActive;
    if (this.freePricingActive) {
      this.setTempPriceValue({ customPriceCents: this.priceCents, customPrice: this.priceDecimal });
    }
  }

  public setTempPriceValue(data: ICustomPrice) {
    this.tempPriceCents = data.customPriceCents;
    this.tempPriceDecimal = data.customPrice;
  }

  // set new cents/decimal to scope
  public setNewValue(data: ICustomPrice) {
    this.priceCents = data.customPriceCents;
    this.priceDecimal = data.customPrice;
    this.toggleFreePricing();
    this.calculateDiscountedTotalAmount();
  }

  public updateDiscount(discountUpdate: IDiscountUpdate) {
    this.discountTypeOrAmountChanged(discountUpdate);
    this.toggleDiscounts(true);
  }

  public toggleDiscounts(saveDiscounts: boolean) {
    this.discountActive = !this.discountActive;
    if (this.discountActive) {
      this.discountTypeTmp = this.discountType;
    } else if (!this.discountActive && !saveDiscounts && this.discountTypeTmp !== this.discountType) {
      this.discountType = this.discountTypeTmp;
    }
    if (saveDiscounts) {
      let discountDecimalTmp = this.discountDecimalTmp;
      if (this.discountType === DISCOUNTS_TYPES.DISCOUNT) {
        discountDecimalTmp = Math.min(discountDecimalTmp, this.priceDecimal);
      }
      this.setDiscountCents(this.discountCentsTmp, discountDecimalTmp);
      this.setDiscountToEntry();
    } else {
      //this.setDiscountCentsTmp('', 0);
    }
  }

  public setDiscountToEntry() {
    if (this.discountDecimal === 0) {
      this.discount = 0;
      this.discountPercentage = 0;
    } else {
      if (this.discountType === DISCOUNTS_TYPES.PERCENTAGE) {
        this.discount = 0;
        this.discountPercentage = this.discountDecimal;
      } else {
        this.discount = this.discountDecimal;
        this.discountPercentage = 0;
      }
    }

    this.calculateDiscountedTotalAmount();
  }

  public setInitialDiscounts() {
    if (this.invoiceEntry) {
      if (this.invoiceEntry.discount) {
        this.discountType = DISCOUNTS_TYPES.DISCOUNT;
        this.discount = this.invoiceEntry.discount;
        this.discountPercentage = 0;
        this.setDiscountCents('0' + MathUtils.roundHalfUp(this.invoiceEntry.discount * 100, 2).toString(), this.invoiceEntry.discount);
      } else if (this.invoiceEntry.discountPercentage) {
        this.discountType = DISCOUNTS_TYPES.PERCENTAGE;
        this.discount = 0;
        this.discountPercentage = this.invoiceEntry.discountPercentage;
        this.setDiscountCents('0' + this.invoiceEntry.discountPercentage.toString(), this.invoiceEntry.discountPercentage);
      } else {
        this.setDiscountCents(0, 0);
      }
    } else {
      if (this.discount === 0 && this.discountPercentage === 0) {
        this.setDiscountCents(0, 0);
      } else if (this.discountType === DISCOUNTS_TYPES.DISCOUNT) {
        this.setDiscountCents('0' + MathUtils.roundHalfUp(this.discount * 100, 2).toString(), this.discount);
      } else if (this.discountType === DISCOUNTS_TYPES.PERCENTAGE) {
        this.setDiscountCents('0' + this.discountPercentage.toString(), this.discountPercentage);
      }
    }
  }

  public setDiscountCents(discountCents: any, discountDecimal: number) {
    this.discountCents = 0;
    this.discountDecimal = 0;
    this.discountCents = discountCents;
    this.discountDecimal = discountDecimal;
  }

  public setDiscountCentsTmp(discountCents: any, discountDecimal: number) {
    this.discountCentsTmp = 0;
    this.discountDecimalTmp = 0;
    this.discountCentsTmp = discountCents;
    this.discountDecimalTmp = discountDecimal;
  }

  public discountTypeOrAmountChanged(discountUpdate: IDiscountUpdate) {
    const { discountType, discountCents, discountDecimal } = discountUpdate;
    this.discountType = discountType;
    this.setDiscountCentsTmp(discountCents, discountDecimal);
  }

  public changeQuantity(type: string) {
    let { quantity = 0 } = this.productForm.value;
    switch (type) {
      case QuantityModificationActions.subtract:
        quantity -= 1;
        if (quantity === 0) {
          quantity -= 1;
        }
        break;
      case QuantityModificationActions.add:
        quantity += 1;
        if (quantity === 0) {
          quantity += 1;
        }
        break;
    }
    this.setNewQuantity(quantity);
  }

  private setNewQuantity(value: string) {
    let newQuantity;
    const quantity = !isNaN(Number(value)) ? parseInt(value) : null;
    if (quantity < 10000 && quantity > -10000) {
      newQuantity = quantity !== 0 ? quantity : 1;
    }
    this.productForm.controls.quantity.setValue(newQuantity, { emitEvent: false });
    this.quantity = newQuantity;
    this.calculateDiscountedTotalAmount();
  }

  public calculateDiscountedTotalAmount() {
    this.discountedTotalAmount = 0;

    let entry: any = {
      price: this.priceDecimal,
      discountedPrice: this.priceDecimal,
    };
    if (this.discountDecimal === 0) {
      entry.discount = 0;
      entry.discountPercentage = 0;
    } else {
      if (this.discountType === DISCOUNTS_TYPES.PERCENTAGE) {
        entry.discount = 0;
        entry.discountPercentage = this.discountDecimal;
      } else {
        entry.discount = this.discountDecimal;
        entry.discountPercentage = 0;
      }
    }

    InvoiceEntry.calculateEntryDiscountedPrice(entry);
    this.discountedTotalAmount = entry.discountedPrice * this.quantity;
  }

  public closeModal(addProductToCart, type?) {

    if (this.quantityInvalid && ['footer-done', 'header-confirm', 'footer-add'].includes(type)) {
      return;
    }

    setTimeout(() => {
      if (addProductToCart && this.newEntry) {
        if (this.TableEnforceService.checkForTableEnforcementAndShowAlert()) return;
        const productType = this.product?.type || PRODUCT_TYPES.PRODUCT;
        this.CartService.addEntry(this.createFutureInvoiceEntry(), productType, this.quantity);
      } else if (this.invoiceEntry) {
        if (type === 'footer-remove') {
          this.CartService.changeEntryQuantity(this.invoiceEntry, -this.invoiceEntry.quantity);
        } else {
          const newEntry = this.createFutureInvoiceEntry(this.invoiceEntry.specialTaxing);
          this.invoiceEntry.update(newEntry);
          this.CartService.changeEntryQuantity(this.invoiceEntry, this.quantity - this.invoiceEntry.quantity);
        }
      }
      this.dismissModal();
    }, 100);
  }

  private createFutureInvoiceEntry(specialTaxing?: string) {
    if (!this.selectedVariant) {
      this.selectedVariant = this.variants[0];
    }
    const newEntryData = this.invoicesService.makeInvoiceEntryToAddData(this.selectedVariant, this.product, '', this.productCategory);
    if (this.product.hasCustomOptions && this.selectedVariant) {
      newEntryData.name += ` (${this.generateVariantShortName(this.selectedVariant, ', ')})`;
      newEntryData.shortName = this.generateVariantShortName(this.selectedVariant, ' ');
    }
    this.setDiscountToEntry();

    newEntryData.note = this.note;
    newEntryData.price = this.priceDecimal;
    newEntryData.discount = this.discount;
    newEntryData.discountPercentage = this.discountPercentage;
    newEntryData.image = this.CartService.getEntryImage(this.product, this.selectedVariant);
    newEntryData.giftCardPhysicalCode = this.assignedGiftCardNumber;
    newEntryData.guestNumber = this.guestNumber;
    newEntryData.wasPrice = this.selectedVariant?.wasPrice || 0;
    if (specialTaxing === SpecialTaxing.takeAway && this.selectedVariant.isTakeAwayAllowed && this.defaultTakeAwayCompanyVATRateValue) {
      newEntryData.taxRate = this.defaultTakeAwayCompanyVATRateValue;
    }

    if (this.newEntry) {
      newEntryData.bgColor = new GenerateRandomColor().generateColorByText(newEntryData.name);
    }
    return newEntryData;
  }

  public imageStatusChanged(status) {
    this.imagesLoaded = status;
  }

  public changeTab(newTab: string) {
    if (this.currentTab !== newTab) {
      this.currentTab = newTab;
    }
  }

  public onRateChange(param: SpecialTaxing) {
    if (param === SpecialTaxing.takeAway || param === SpecialTaxing.inHouse) {
      this.invoiceEntry.specialTaxing = param;
    }
  }

  openAssignGiftCardModal() {
    const activeInvoice = this.CartService.getActiveInvoice();
    const existingGiftCarts = activeInvoice
      .getGiftCardsEntries()
      .filter((entry) => entry.giftCardPhysicalCode && entry.giftCardPhysicalCode !== this.assignedGiftCardNumber)
      .map((entry) => entry.giftCardPhysicalCode);

    this.assignGiftCardService
      .openAssignGiftCardModal(this.assignedGiftCardNumber, { alreadyExist: true, existingGiftCarts }, true)
      .then((giftCard) => {
        this.assignedGiftCardNumber = '';
        if (giftCard) {
          this.assignedGiftCardNumber = giftCard.physicalCode;
        }
        this.logger.debug('openAssignGiftCardModal:assignedGiftCardNumber', { assignedGiftCardNumber: this.assignedGiftCardNumber });
      })
      .catch((error) => {
        this.logger.error(error, 'openAssignGiftCardModal');
      });
  }

  trackByFn(index: number, item: any) {
    return item && item.uuid ? item.uuid : index;
  }

  private updateSlidePagination(product: Product | null) {
    this.slideOpts.pagination = !!product?.images.length;
  }
}
