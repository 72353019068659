import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, observeOn } from 'rxjs/operators';
import {
  ClearActiveEmployee,
  LoadedListEmployeesFromLocalDBFailure,
  LoadedListEmployeesFromLocalDBSuccess,
  LoadListEmployeesFromLocalDB,
  SelectActiveEmployee,
  UpdatedListEmployeesSuccess,
  UpdateListEmployees,
} from './employees.actions';
import { EmployeesProvider } from '@pos-common/services/resources/employees-db-entity.provider';
import { Employee } from '@pos-common/classes/employee.class';
import { asyncScheduler, of } from 'rxjs';
import { LogService } from '@pos-common/services/system/logger';
import { ILogger } from '@spryrocks/logger';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { StorageKeys } from '@pos-common/constants';

@Injectable()
export class EmployeesPageEffects {
  private readonly logger: ILogger = this.logService.createLogger('EmployeesPageStateEffects');

  constructor(
    private actions$: Actions,
    private logService: LogService,
    private localStorage: LocalStorage,
    private employeesDBProvider: EmployeesProvider,
    ) {}

  loadListEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadListEmployeesFromLocalDB),
      exhaustMap(() => this.employeesDBProvider.getList()
        .pipe(
          observeOn(asyncScheduler),
          map((employees: Employee[]) => LoadedListEmployeesFromLocalDBSuccess({ list: employees })),
          catchError((error: Error) => of(LoadedListEmployeesFromLocalDBFailure({ error })))
        )))
  });

  updateListEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateListEmployees),
      observeOn(asyncScheduler),
      map(({ data }) => {
        if (data?.['properties']) {

          const list: Employee[] = [data?.['properties']];

          return UpdatedListEmployeesSuccess({ list })
        } else {
          return UpdatedListEmployeesSuccess({ list: [] });
        }
      })
    )
  });

  selectActiveEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectActiveEmployee),
      observeOn(asyncScheduler),
      map(({ employee: { uuid } }) => this.localStorage.set(StorageKeys.activeEmployee, uuid))
    )
  }, { dispatch: false });

  clearActiveEmployee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClearActiveEmployee),
      observeOn(asyncScheduler),
      map(() => this.localStorage.remove(StorageKeys.activeEmployee))
    )
  }, { dispatch: false });

  sendErrorsToLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadedListEmployeesFromLocalDBFailure),
      map((action) => this.logger.error(action.error, action.type))
    )
  }, { dispatch: false })
}
