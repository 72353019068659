import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { SalesSubChannelTypesEnum, SelfOrderInvoiceStatusTypesEnum } from '@pos-common/constants';
import {
  ClearOpenSelfOrderInvoices,
  LoadedSelfOrderInvoicesFromLocalDBFailure,
  LoadedSelfOrderInvoicesFromLocalDBSuccess,
  LoadSelfOrderInvoicesFromLocalDB,
  ReceivedNewOpenSelfOrderInvoice,
  SetCounterOpenSelfOrderInvoices,
  UpdateInvoices,
  UpdateOpenSelfOrderInvoice,
  UpdateSelfOrderInvoice,
} from './invoices.actions';
import { catchError, delay, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { Store as StoreNgRx } from '@ngrx/store';
import { InvoicesState, SelfOrderOpenInvoice } from '@pos-stores/invoices/invoices.reducer';
import { selectOpenInvoiceByUuidStore } from '@pos-stores/invoices/invoices.selectors';
import { Invoice } from '@pos-common/classes/invoice.class';
import { SelfOrdersService } from '@pos-common/services/system';
import { of } from 'rxjs';
import {
  ClearActiveEmployee,
  SelectActiveEmployee,
} from '@pos-stores/employees';
import { SecurityService } from '@pos-common/services/system/security.service';
import { UpdateEmptyAction } from '@pos-stores/root';
import { ILogger } from '@spryrocks/logger';
import { LogService } from '@pos-common/services/system/logger';
import { ReceiptAutoPrintingService } from '@pos-common/services/system/receipt-auto-printing/receipt-auto-printing.service';
import { Store } from '@pos-common/classes/store.class';
import moment from 'moment';
import { PosSettingsService } from '@pos-common/services/system/pos-settings.service';

@Injectable()
export class InvoicesEffects {
  private readonly logger: ILogger = this.logService.createLogger('InvoicesStateEffects');

  constructor(
    private logService: LogService,
    private actions$: Actions,
    private store: StoreNgRx<InvoicesState>,
    private securityService: SecurityService,
    private selfOrderService: SelfOrdersService,
    private receiptAutoPrintingService: ReceiptAutoPrintingService,
    private posSettingsService: PosSettingsService
    ) {}

  selectActiveEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SelectActiveEmployee),
      filter((): boolean => this.securityService.getLoggedCompanyData()?.isSelfOrderingEnabled),
      map(() => LoadSelfOrderInvoicesFromLocalDB())
    )
  })

  clearActiveEmployees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClearActiveEmployee),
      map(() => ClearOpenSelfOrderInvoices())
    )
  })

  LoadSelfOrderInvoicesFromLocalDB$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadSelfOrderInvoicesFromLocalDB),
      exhaustMap(() => this.selfOrderService.listOpenSelfOrderInvoicesFromLocalDB.pipe(
        map((invoices: Invoice[]) => {
          return LoadedSelfOrderInvoicesFromLocalDBSuccess({ invoices })
        }),
        catchError((error) => of(LoadedSelfOrderInvoicesFromLocalDBFailure({ error })))
      ))
    )
  })

  updateSelfOrderOpenInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateInvoices),
      map(({ invoice }) => {

          const isSelfOrderingEnabled: boolean = this.securityService.getLoggedCompanyData()?.isSelfOrderingEnabled;
          if (isSelfOrderingEnabled && invoice?.['properties'] && invoice?.['properties']?.uuid && invoice?.['properties']?.subChannel === SalesSubChannelTypesEnum.SELFORDER) {
            return UpdateSelfOrderInvoice({ invoice: invoice['properties'] })
          } else {
            return UpdateEmptyAction()
          }

      })
    )
  })

  triggerReceivedSelfOrderOpenInvoice = createEffect(() => this.actions$.pipe(
    ofType(ReceivedNewOpenSelfOrderInvoice),
    filter(({ isNew }): boolean => isNew),
    delay(1000),
    map(() => ReceivedNewOpenSelfOrderInvoice({ isNew: false }))
  ))

  setCounterSelfOrderOpenInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadedSelfOrderInvoicesFromLocalDBSuccess),
      filter(({ invoices }) => !!invoices && Array.isArray(invoices) && invoices.length > 0),
      map(({ invoices }) => {
          const entities: SelfOrderOpenInvoice[] = mapperInvoicesToSelfOrderOpenInvoices(invoices);
          return SetCounterOpenSelfOrderInvoices({ entities });
      })
    )
  })

  changeCounterSelfOrderOpenInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateSelfOrderInvoice),
      filter(({ invoice }): boolean => {
        const duration: string = this.posSettingsService.getInvoicesKeepDuration();
        const durationTime: string = moment.utc().subtract(duration, 'day').startOf('day').toISOString();
        return invoice.date > durationTime
      }),
      concatLatestFrom(({ invoice }) => [this.store.select(selectOpenInvoiceByUuidStore(invoice?.store?.uuid))]),
      switchMap(([ { invoice }, data]: [{ invoice: Invoice }, string[]]) => {
          const [entity, isNew]: [SelfOrderOpenInvoice, boolean] = updaterEntitySelfOrderOenInvoices(data, invoice);
          return isNew ?
            of(
              UpdateOpenSelfOrderInvoice({ entity }),
              ReceivedNewOpenSelfOrderInvoice({ isNew })
            ) :
            of(UpdateOpenSelfOrderInvoice({ entity }));
      })
    )
  })

  autoPrintingSelfOrderOpenInvoices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateSelfOrderInvoice),
      map(({ invoice }) => {
          const isAutoPrintingOPenInvoice: boolean = this.selfOrderService.localSettingsAutoPrintingOrder;
          const activeStore: Store = this.securityService?.activeStore;

          if(invoice?.selfOrder?.fulfillmentState === SelfOrderInvoiceStatusTypesEnum.OPEN
            && isAutoPrintingOPenInvoice
            && invoice?.store?.uuid === activeStore.uuid
          ) {
            this.receiptAutoPrintingService.processAutoPrintingKitchenReceiptOnlineInvoice(invoice)
          }
      })
    )
  }, { dispatch: false })

  sendErrorsToLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadedSelfOrderInvoicesFromLocalDBFailure),
      map((action) => this.logger.error(action.error, action.type))
    )
  }, { dispatch: false })
}

const updaterEntitySelfOrderOenInvoices = (data: string[], invoice: Invoice):  [SelfOrderOpenInvoice, boolean] => {
  const uuids: Set<string> = new Set(data);
  const storeUuid: string = invoice?.store?.uuid;

  const isOpenSelfOrderInvoice: boolean = invoice.selfOrder.fulfillmentState === SelfOrderInvoiceStatusTypesEnum.OPEN;
  const hasInListOPenInvoices: boolean = uuids.has(invoice.uuid);

  if (isOpenSelfOrderInvoice && !hasInListOPenInvoices) {
    uuids.add(invoice.uuid);
    return [{ storeUuid, uuids: Array.from(uuids) }, true];
  }

  if (hasInListOPenInvoices && !isOpenSelfOrderInvoice) {
    uuids.delete(invoice.uuid);
  }

  return [{ storeUuid, uuids: Array.from(uuids) }, false];
}

const mapperInvoicesToSelfOrderOpenInvoices = (invoices: Invoice[]):  SelfOrderOpenInvoice[] => {
 return invoices.reduce((acc: SelfOrderOpenInvoice[], item: Invoice): SelfOrderOpenInvoice[] => {
    const storeUuidIdx: number = acc.findIndex((soi: SelfOrderOpenInvoice): boolean => soi.storeUuid === item.store.uuid);

    if (storeUuidIdx !== -1) {
      acc[storeUuidIdx].uuids.push(item.uuid)
    } else {
      acc.push({
        storeUuid: item.store.uuid,
        uuids: [item.uuid]
      })
    }

    return acc;
  }, [])
}
