import { Injectable } from '@angular/core';
import { LoadShiftsOptions, ShiftApiResponse, ShiftUpdateRequest } from '@paymash/paymash-shifts/loader';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { SecurityService } from '../system/security.service';
import moment from "moment";

@Injectable()
export class ShiftsApiService {
  constructor(private securityService: SecurityService) {}

  getShifts(options: LoadShiftsOptions): Promise<ShiftApiResponse> {
    const storeUuid = this.securityService.getActiveStore().uuid;
    const formattedFrom = moment(options.dateFrom, "DD/MM/YYYY").format("DD.MM.YYYY");
    const formattedTo = moment(options.dateTo, "DD/MM/YYYY").format("DD.MM.YYYY");
    const query = `/find?dateFrom=${formattedFrom}&dateTo=${formattedTo}&storeUuid=${storeUuid}`;
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_LEGACY_URL}shifts${query}`, 'get').then((response) => response.data);
  }

  updateShift(shiftData: ShiftUpdateRequest): Promise<ShiftApiResponse> {
    shiftData.storeUuid = this.securityService.getActiveStore().uuid;
    const method = shiftData.id === 0 ? 'post' : 'put';
    return this.securityService
      .doSecureRequest(`${SERVER_CONFIG.API_LEGACY_URL}shifts/${shiftData.id}`, method, shiftData)
      .then((response) => response.data);
  }
}
