import { Injectable } from '@angular/core';
import { Invoice } from '../../../classes/invoice.class';
import { PrinterService } from '../printer.service';
import { LogService } from '../logger/log.service';
import { ReceiptPrinterModeTypes } from '../../../constants/receipt-printer-mode-types';
import { ReceiptPrinter } from '../receipt-printers/classes/receipt-printer.class';
import { DefaultPaymentMethods } from '../../../constants/default-payment-methods.enum';
import { CartService } from '../cart.service';
import { ReceiptBuilder } from '../receipt-printers/classes/receipt-builder.class';
import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { MyPosService } from '../devices/my-pos/my-pos.service';
import { IPrintersSettings } from '@pos-common/interfaces/printers-settings.interface';
import { SelfOrdersService } from '@pos-common/services/system';
import { SelfOrderInvoiceStatusTypesEnum } from '@pos-common/constants';

@Injectable()
export class ReceiptAutoPrintingService {
  private readonly logger = this.logService.createLogger('ReceiptAutoPrintigService');
  constructor(
    private printerService: PrinterService,
    private cartService: CartService,
    private myPosService: MyPosService,
    private logService: LogService,
    private selfOrderService: SelfOrdersService
  ) {}

  public processAutoPrinting(invoice: Invoice, method: string): void {
    const printersSetting = this.printerService.getPrintersSettings();

    if (
      method === DefaultPaymentMethods.CASH &&
      printersSetting.openCashRegisterAutomatically &&
      !printersSetting.printReceiptAutomatically
    ) {
      this.printerService.openCashRegister().catch((err) => this.logger.error(err, 'processAutoPrinting:openCashRegister'));
    }

    if (printersSetting.openCashRegisterAutomatically && printersSetting.printReceiptAutomatically) {
      if (invoice.isPaid || method === DefaultPaymentMethods.ON_INVOICE) {
        const openCashRegister = method === DefaultPaymentMethods.CASH && printersSetting.openCashRegisterAutomatically;
        this.printReceiptAfterPayment(invoice, openCashRegister)
          .then(() => this.printKitchenReceiptAfterPayment(invoice, printersSetting))
          .catch(() => this.printKitchenReceiptAfterPayment(invoice, printersSetting));
      } else {
        if (method !== DefaultPaymentMethods.GIFTCARD) {
          this.printerService.openCashRegister().catch((err) => this.logger.error(err, 'processAutoPrinting:openCashRegister'));
        }
      }
    }
    if (
      (invoice.isPaid || method === DefaultPaymentMethods.ON_INVOICE) &&
      printersSetting.printReceiptAutomatically &&
      !printersSetting.openCashRegisterAutomatically
    ) {
      this.printReceiptAfterPayment(invoice)
        .then(() => this.printKitchenReceiptAfterPayment(invoice, printersSetting))
        .catch(() => this.printKitchenReceiptAfterPayment(invoice, printersSetting));
    }

    if (
      (invoice.isPaid || method === DefaultPaymentMethods.ON_INVOICE) &&
      printersSetting.printKitchenReceiptAutomatically &&
      !printersSetting.printReceiptAutomatically
    ) {
      this.printKitchenReceiptAfterPayment(invoice, printersSetting);
    }
  }

  public processAutoPrintingKitchenReceiptOnlineInvoice(invoice: Invoice): void {
    this.printKitchenReceipt(invoice)
  }

  private printReceiptAfterPayment(invoice: Invoice, openCashRegister?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (openCashRegister && this.myPosService.isMyPosHubDevice) {
        this.printerService.openCashRegister().catch((err) => this.logger.error(err, 'printReceiptAfterPayment:openCashRegister'));
      }

      let isPrinted = false;
      this.getPrinterList(ReceiptPrinterModeTypes.POS)
        .pipe(
          mergeMap((printers) =>
            this.createReceiptFromInvoiceForMultiplePrinters(printers, invoice, ReceiptPrinterModeTypes.POS, openCashRegister)
          )
        )
        .subscribe(
          (item) => {
            this.printReceipt(item.printer, item.receipt, invoice, () => {
              if (invoice.isPaid && !invoice.isPrinted && !isPrinted) {
                this.cartService.setPrintedStatusToInvoice(invoice);
                isPrinted = true;
              }
            });
          },
          reject,
          resolve
        );
    });
  }

  private printKitchenReceiptAfterPayment(invoice: Invoice, printersSetting: IPrintersSettings): void {
    if (!printersSetting.printKitchenReceiptAutomatically) {
      return;
    }

    this.printKitchenReceipt(invoice);
  }

  private printKitchenReceipt(invoice: Invoice): void {
    const invoiceForPrint = new Invoice(invoice);

    this.getPrinterList(ReceiptPrinterModeTypes.KITCHEN)
      .pipe(mergeMap((printers) => this.createReceiptFromInvoiceForMultiplePrinters(printers, invoiceForPrint, ReceiptPrinterModeTypes.KITCHEN)))
      .subscribe((item) => {
        this.printReceipt(item.printer, item.receipt, invoiceForPrint, () => {
          this.selfOrderChangeStatusAfterKitchenPrinting(invoiceForPrint);
        });
      });
  }

  private printReceipt(printer: ReceiptPrinter, receipt: ReceiptBuilder, invoice: Invoice, callback?: () => void) {
    if (!receipt) {
      return;
    }

    this.printerService
      .printReceipt(printer, receipt, {
        uuid: invoice.uuid,
        name: invoice.invoiceDisplayId,
      })
      .then(() => {

        if (callback) {
          callback();
        }

      })
      .catch((err) => this.logger.error(err, 'printReceipt:printerService.printReceipt'));
  }

  private selfOrderChangeStatusAfterKitchenPrinting(invoice: Invoice): void {

    const { selfOrder } = invoice;

    if (selfOrder && selfOrder.fulfillmentState === SelfOrderInvoiceStatusTypesEnum.OPEN) {
      this.selfOrderService.updatePreparationStatusFromCheckoutPage(invoice, SelfOrderInvoiceStatusTypesEnum.IN_PREPARATION);
    }

  }

  private getPrinterList(filterType: ReceiptPrinterModeTypes): Observable<ReceiptPrinter[]> {
    return this.printerService.getPrinterListOnce(filterType).pipe(filter((list) => list.length > 0));
  }

  private createReceiptFromInvoiceForMultiplePrinters(
    printers: ReceiptPrinter[],
    invoice: Invoice,
    receiptType?: string,
    openCashRegister?: boolean
  ): Observable<{ receipt: ReceiptBuilder | null; printer: ReceiptPrinter }> {
    this.logger.debug('Invoice will be printered for multiple printers', { invoiceUuid: invoice.uuid });
    return new Observable((observer) => {
      let counter = 0;
      const completion = (receipt: ReceiptBuilder | null, printer: ReceiptPrinter) => {
        observer.next({ receipt, printer });
        counter++;
        if (counter === printers.length) {
          observer.complete();
        }
      };
      printers.forEach((printer) => {
        this.printerService
          .createReceiptFromInvoice(invoice, printer, receiptType, openCashRegister)
          .then((receipt) => completion(receipt, printer))
          .catch(() => completion(null, printer));
      });
    });
  }
}
