import { SecurityService } from '@pos-common/services/system/security.service';
import { AdyenPaymentError, AdyenTerminalInfo } from './classes';
import { IActiveTerminal } from '@pos-common/interfaces';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { Injectable } from '@angular/core';
import { CommonRequestOptions } from '@pos-common/services/system/adyen/adyen-types';
import { TranslateService } from '@ngx-translate/core';
import { Buffer } from 'buffer';
import { ILogger } from '@spryrocks/logger';
import { LogService } from '@pos-common/services/system/logger';
import { ErrorCondition } from '@pos-common/services/system/adyen/enums';
import { NetworkService } from '@pos-common/services/system/network.service';

@Injectable()
export class AdyenUtils {
  constructor(
    private readonly securityService: SecurityService,
    private readonly localStorage: LocalStorage,
    private readonly translateService: TranslateService,
    private logService: LogService,
    private networkService: NetworkService,
  ) {}
  private readonly logger: ILogger = this.logService.createLogger('AdyenUtils');

  private generateServiceID(): string {
    const time = new Date().getTime().toString();
    const maxLength = 10;
    return time.length > maxLength ? time.substring(time.length - maxLength) : time;
  }

  private createSaleID(): string {
    const deviceIdentifier = this.securityService.getDeviceIdentifier();
    const company = this.securityService.getLoggedCompanyData();
    return !!company?.uuid && !!deviceIdentifier ? `POS_${company?.uuid}_${deviceIdentifier}` : null;
  }

  private getTerminalInternal(): IActiveTerminal {
    const terminal: IActiveTerminal = this.localStorage.getObject(StorageKeys.activeTerminal);
    const isAdyenTerminal: boolean = ['paymashpay','softpos'].includes(terminal?.name);
    if (!isAdyenTerminal) return undefined;
    return terminal;
  }

  get isPaymashPayTerminal(): boolean {
    return !!this.getTerminal();
  }

  get isSoftPosTerminal(): boolean {
    const terminal: IActiveTerminal = this.getTerminalInternal();
    return terminal?.name === 'softpos'
  }

  getTerminal(): AdyenTerminalInfo | undefined {
    const terminal: IActiveTerminal = this.getTerminalInternal();
    if (!terminal) return undefined;
    return new AdyenTerminalInfo(terminal.deviceModel, terminal.serialNumber);
  }

  checkNetworkConnection(): void {
    if (this.networkService.getConnectionStatus()) {
      const message: string = this.translateService.instant('global_error_no_connection_with_internet');
      throw new AdyenPaymentError(
        message,
        ErrorCondition.SoftPosError,
        ErrorCondition.SoftPosError,
        message,
        true
      );
    }
  }

  createCommonRequestOptions(): CommonRequestOptions {
    const terminal: AdyenTerminalInfo = this.getTerminal();
    const saleID: string = this.createSaleID();
    const message: string = this.translateService.instant('settings_provide_terminal_info');
    if (!terminal || !saleID) throw new Error(message);
    return {
      serviceID: this.generateServiceID(),
      saleID,
      poiID: {
        deviceModel: terminal.deviceModel,
        serialNumber: terminal.serialNumber,
      },
    };
  }

  static encodeBase64URL(message: string): string {
    const base64String: string = btoa(message);
    return base64String
      .replace(/\+/g, '-') // Replace '+' with '-'
      .replace(/\//g, '_') // Replace '/' with '_'
      .replace(/=+$/, '');
  }

  static decodeBase64URL(input: string): string {
    const base64 = input
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .padEnd(input.length + (4 - input.length % 4) % 4, '=');

    return Buffer.from(base64, 'base64').toString('utf-8');
  }

  stringify(request: unknown): string {
    try {
      return JSON.stringify(request);
    } catch (e) {
      this.logger.error(e, 'AdyenUtils.stringify error');
      return '';
    }
  }

  parse<T>(value: string): T {
    try {
      return JSON.parse(value);
    } catch (e) {
      this.logger.error(e, 'AdyenUtils.parse error');
      return null;
    }
  }

}
