import { Injectable } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';

import { Employee } from '@pos-common/classes/employee.class';
import { EmployeeState } from "./employees.reducer";
import {
  selectActiveEmployee,
  selectEmployeeByUuid,
  selectEmployees,
  selectLoadingStatusEmployees,
} from './employees.selectors';
import {
  ClearActiveEmployee,
  LoadListEmployeesFromLocalDB,
  SelectActiveEmployee,
  UpdateListEmployees,
} from './employees.actions';

import { EmployeeLogout } from '@pos-stores/router';
import { first, map } from 'rxjs/operators';

@Injectable()
export class EmployeesFacadeStore {
  private activeEmployeeBehaivorSubject: BehaviorSubject<Employee | null> = new BehaviorSubject(null);
  constructor(private store: Store<EmployeeState>) {
  }

  public get employees$(): Observable<Employee[]> {
    return this.store.select(selectEmployees)
      .pipe(
        map((employees: Employee[]): Employee[] =>
        [...employees].sort((a: Employee, b: Employee): number => {
          const aFirstName = a.firstName || '';
          const bFirstName = b.firstName || '';
          return aFirstName.localeCompare(bFirstName)
        })),
        shareReplay(1)
      );
  }

  public get isLoading$(): Observable<boolean> {
    return this.store.select(selectLoadingStatusEmployees);
  }

  public get activeEmployee$(): Observable<Employee> {
    return this.store.pipe(
      select(selectActiveEmployee),
      tap((employee: Employee): Employee => {

        if (!employee && this.isNeedReSelectEmployee) {
          this.store.dispatch(EmployeeLogout());
        }

        return employee;
      }),
      shareReplay(1)
      )
  }

  public get activeEmployeeSnapshot(): Employee | null {
    this.activeEmployee$.pipe(first()).subscribe(this.activeEmployeeBehaivorSubject);
    return this.activeEmployeeBehaivorSubject.getValue();
  }

  public set selectActiveEmployee(employee: Employee) {
    this.store.dispatch(SelectActiveEmployee({ employee }));
  }

  public set activeEmployee(employee: Employee) {
    this.store.dispatch(SelectActiveEmployee({ employee }));
  }

  public getEmployeeByUuid$(uuid: string): Observable<Employee> {
    return this.store.select(selectEmployeeByUuid(uuid));
  }

  public loadEmployees(): void {
    this.store.dispatch(LoadListEmployeesFromLocalDB());
  }

  public updateEmployees(data: Employee[]): void {
    this.store.dispatch(UpdateListEmployees({ data }));
  }

  public clearActiveEmployee(): void {
    this.store.dispatch(ClearActiveEmployee());
  }

  private get isNeedReSelectEmployee(): boolean {
    const currentUrl: string = window.location.hash;

    if (!currentUrl) {
      return true;
    }

    return !['settings', 'employee'].some((url: string) => currentUrl.includes(url));
  }
}
