import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AddSoftPosAdyenTerminal,
  AddSoftPosTerminalAdyenSuccess,
  BoardingSoftPosAdyenTerminal,
  BoardSoftPosAdyenTerminal,
  ErrorActionTerminal,
} from '@pos-stores/settings/terminal/terminal.actions';
import { delay, map, switchMap } from 'rxjs/operators';
import { AdyenTerminalInfo } from '@pos-common/services/system/adyen/classes';
import { AdyenTerminalProvider } from '@pos-common/services/system/adyen';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import { Observable, tap } from 'rxjs';
import { ApiSoftPosBoardedSuccess } from '@pos-common/services/system/adyen/adyen-types';
import { AdyenSoftPosService } from '@pos-common/services/system/adyen/services/adyen-soft-pos.service';
import { AlertService } from '@pos-common/services/system/alert.service';
import { AdyenDeviceName, AdyenSoftPosTargetAction } from '@pos-common/services/system/adyen/enums';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TerminalEffects {

  constructor(
    private actions$: Actions,
    private adyenTerminalProvider: AdyenTerminalProvider,
    private softPosService: AdyenSoftPosService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  setTerminalSoftPosAdyen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardSoftPosAdyenTerminal),
      map(({ info, target }) => {
        return AddSoftPosAdyenTerminal({ info, target })
      })
    )
  })

  addSoftPosTerminalAdyenProcess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddSoftPosAdyenTerminal),
      map(({ info, target }) => {

        if (info.boarded) {
          const terminal: AdyenTerminalInfo = new AdyenTerminalInfo (
            AdyenDeviceName.softpos,
            info.installationId
          );
          return AddSoftPosTerminalAdyenSuccess({ terminal });
        } else if (!info.boarded && target === AdyenSoftPosTargetAction.Boarded) {
          return BoardingSoftPosAdyenTerminal({ boardingRequestToken: info.boardingRequestToken });
        } else {
          return ErrorActionTerminal({
            error: { info, target },
          });
        }
      })
    )
  })

  processBoardingSoftPosAdyenTerminal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BoardingSoftPosAdyenTerminal),
      switchMap(({ boardingRequestToken }): Observable<ApiSoftPosBoardedSuccess> =>
        fromPromise(this.adyenTerminalProvider.connectToSoftPOSTerminal(boardingRequestToken)).pipe(
          delay(1000),
          tap(async (boardedSuccess: ApiSoftPosBoardedSuccess): Promise<void> => {
            if (boardedSuccess) {
              const dialog: HTMLIonAlertElement = await this.alertService.create({
                header: this.translateService.instant('settings.terminals-page.adyen-tap-to-pay.app-not-boarding.header'),
                buttons: [
                  {
                    text: this.translateService.instant('settings.terminals-page.adyen-tap-to-pay.app-not-boarding.btn-boarding'),
                    handler: () => this.softPosService.softPosBoardingProcess(boardedSuccess.boardingToken).then()
                  },
                  { text: this.translateService.instant('common_cancel'), role: 'cancel' },
                ],
              });
              dialog.present().catch((err) => console.error('boarding error', err));
            }
          })
      ))
    )
  }, { dispatch: false })

  addSoftPosTerminalAdyenProcessSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AddSoftPosTerminalAdyenSuccess),
      tap(({ terminal }): void => {
        this.softPosService.saveActiveTerminal(terminal);
      })
    )
  }, { dispatch: false })

}
