/**
 * Created by y.belinsky on 12/16/16.
 */

import { Injectable } from '@angular/core';

import { Invoice } from '../../classes/invoice.class';
import { InvoicePayment } from '../../classes/invoice-payment.class';
import { InvoiceEntry, SpecialTaxing } from '../../classes/invoice-entry.class';

import { UPDATES_TYPES } from '../../constants/updates-types.const';

import { UuidService } from '../utils/uuid.utils';
import { SyncService } from './sync.service';
import { DbDaoService } from '../db/db-dao.service';
import * as moment from 'moment';
import { SecurityService } from './security.service';
import { PaymentMethod } from '../../classes/payment-method.class';
import { PaymentService } from './payment.service';
import { PRODUCT_TYPES } from '../../constants/product-types';
import { CustomerService } from './customers.service';
import { LoadingService } from './loading.service';
import { GoogleAnalyticsService } from './google-analitycs.service';
import { Product } from '../../classes/product.class';
import { ProductVariant } from '../../classes/product-variant.class';
import { PaymentResult } from '../../classes/payment-result.class';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '../../constants/payment-providers.const';
import { SyncListItem } from '../../classes/sync-list-item.class';
import { LogService } from './logger/log.service';
import { PosSettingsService } from './pos-settings.service';
import { ReferenceEntity } from '../../classes/reference-entity';
import { ICancellationInvoiceParams } from '@pos-common/interfaces/cancellation-invoice-params.interface';
import { InvoicesApiService } from '../api/invoices-api.service';
import { InvoicePartiallyCancel } from '@pos-common/classes/invoice/invoice-partially-cancel.class';
import { PaymentError } from '@pos-common/constants/payment-error.enum';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
import { CancellationTypes } from '@pos-common/constants/cancellation-types.enum';
import { IMergeUpdateAndActiveInvoiceEntries } from '@pos-common/interfaces/merge-invoice-entries.interface';
import { InvoiceDetailsTaxItem } from '@pos-modules/invoice-details/interfaces/invoice-details-tax-item';
import { MathUtils } from '../utils/math.utils';
import { LOCALE } from '@pos-common/constants/locale.const';
import { ISplitInvoices } from '@pos-common/interfaces/split-invoices.interface';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';
import { DISCOUNT_CODE_TYPES, REGEXPS, SplitInvoiceTypes, StorageKeys } from '@pos-common/constants';
import { arrayIsNotEmpty, greaterOrEqualsThan, greaterThan, group, lessOrEqualsThan, query } from '@paymash/capacitor-database-plugin';
import { ignoreImageProps } from '@pos-common/services/system/logger';
import { LocalStorage } from '../utils/localstorage.utils';
import { IActiveTerminal } from '@pos-common/interfaces';
import { DbResponse } from '@pos-common/services/db/db-dao.utils';
import { EmployeesFacadeStore } from '@pos-stores/employees';
import { Employee } from '@pos-common/classes/employee.class';

@Injectable()
export class InvoicesService {
  private logger = this.logService.createLogger('InvoicesService');
  constructor(
    public UuidService: UuidService,
    public SyncService: SyncService,
    public DbDaoService: DbDaoService,
    public SecurityService: SecurityService,
    public PaymentService: PaymentService,
    public LoadingService: LoadingService,
    public CustomerService: CustomerService,
    public GoogleAnalyticsService: GoogleAnalyticsService,
    private posSettingsService: PosSettingsService,
    public invoicesApiService: InvoicesApiService,
    private multipleGuestsService: MultipleGuestsService,
    private localStorage: LocalStorage,
    private logService: LogService,
    private facadeEmployeeState: EmployeesFacadeStore
  ) {}

  public createInvoice(): Invoice {
    const invoiceUUID = this.UuidService.generate();
    const newInvoiceData: Invoice = new Invoice({
      uuid: invoiceUUID,
      publicUuid: invoiceUUID.replace(/-/g, ''),
      // TODO ADJUST INVOICE PREPARATION
      invoiceId: null,
      isDraft: true,
    });

    const activeEmployee: Employee = this.facadeEmployeeState.activeEmployeeSnapshot;
    if (activeEmployee) {
      newInvoiceData.employee = new ReferenceEntity(activeEmployee.uuid);
    }
    const activeStore = this.SecurityService.getActiveStore();
    if (activeStore) {
      const store = new ReferenceEntity(activeStore.uuid);
      newInvoiceData.store = store;
      newInvoiceData.inventoryStore = store;
    }
    const company = this.SecurityService.getLoggedCompanyData();
    if (company) {
      newInvoiceData.isNet = company.isInvoiceNet;
    }

    newInvoiceData.setLocalCreationDate();

    this.logger.info('Invoice has been created', { invoiceUuid: newInvoiceData.uuid });
    return newInvoiceData;
  }

  async addPaymentToInvoice(
    invoice: Invoice,
    method: string,
    amount: any,
    amountGiven: any,
    transactionType: string,
    giftCard?: GiftCard
  ): Promise<Invoice> {
    this.logger.debug('Add payment to invoice', { invoiceUuid: invoice.uuid });
    const newInvoicePayment: InvoicePayment = new InvoicePayment({
      uuid: this.UuidService.generate(),
      method,
      amount: parseFloat(amountGiven),
      amountGiven: 0,
      merchantReceipt: null,
      cardholderReceipt: null,
      giftCard: method === DefaultPaymentMethods.GIFTCARD ? giftCard : null,
    });

    newInvoicePayment.setLocalCreationDate();
    newInvoicePayment.increaseLocalModificationDate();
    invoice.employee = new ReferenceEntity(this.facadeEmployeeState.activeEmployeeSnapshot.uuid);

    if (method === DefaultPaymentMethods.CASH) {
      newInvoicePayment.amount = amount > amountGiven ? parseFloat(amountGiven) : parseFloat(amount);
      newInvoicePayment.amountGiven = parseFloat(amountGiven);
    } else if (this.isTerminalMethod(method)) {
      const data = await this.DbDaoService.getAllData(UPDATES_TYPES.PaymentMethod.type, { method });
      const paymentMethod = new PaymentMethod(data.data[0]);
      const paymentProvider = this.getProviderByMethodWithSupportedTwint(paymentMethod.name);
      if (!!data.data.length && paymentMethod.name && paymentProvider) {
        const amountToPay = amount > amountGiven ? parseFloat(amountGiven) : parseFloat(amount);
        const currency = this.SecurityService.getLoggedCompanyData()?.locale?.currency || '';
        const paymentResult: PaymentResult = await this.PaymentService.makeTransaction({
          paymentProvider,
          amount: amountToPay,
          currency,
          transactionType,
          invoiceUuid: invoice.uuid,
          paymentUuid: newInvoicePayment.uuid,
          // TODO: PMH-3624
          // paymentMethod: method,
          paymentMethod: paymentMethod.name,
        });
        const methodName = await this.getPaymentMethodByPaymentType(paymentResult.cardName, method);
        newInvoicePayment.method = methodName;
        newInvoicePayment.setPaymentResultData(paymentResult);
        this.logger.info('Payment method has been added to invoice from terminal', {
          methodName,
          paymentProvider: newInvoicePayment.paymentProvider,
          invoiceUuid: invoice.uuid,
          amount,
          amountGiven,
        });
      }
    }

    return this.addPaymentAndCalculate(invoice, newInvoicePayment);
  }

  private isTerminalMethod = (method: string) =>
    method.includes('CUSTOM')
    || method === DefaultPaymentMethods.SUMUP
    || method === DefaultPaymentMethods.TWINT
    || method === DefaultPaymentMethods.MYPOS;

  private getPaymentMethodByPaymentType(cardType: string, fallback: string): Promise<string> {
    const cartTypeValue = cardType?.toLowerCase().replace(REGEXPS.ANY_SPACES, '') ?? null;
    if (!cartTypeValue) {
      return Promise.resolve(fallback);
    }
    return this.DbDaoService.getAllData(UPDATES_TYPES.PaymentMethod.type, { deleted: false })
      .then((data) => {
        const currentPaymentMethod = data.data.find((paymentMethod) => {
          const method = paymentMethod?.method?.toLowerCase().replace(REGEXPS.ANY_SPACES, '');
          const name = paymentMethod?.name?.toLowerCase().replace(REGEXPS.ANY_SPACES, '');
          return (
            method?.indexOf(cartTypeValue) > -1 ||
            name?.indexOf(cartTypeValue) > -1 ||
            cartTypeValue?.indexOf(method) > -1 ||
            cartTypeValue?.indexOf(name) > -1
          );
        });
        this.logger.debug('getPaymentMethodByPaymentType:cardType', { cardType });
        return currentPaymentMethod?.method ?? fallback;
      })
      .catch((err) => {
        this.logger.error(err, 'getPaymentMethodByPaymentType:DbDaoService:getAllDataFromCollection');
        return fallback;
      });
  }

  private addPaymentAndCalculate(invoice: Invoice, payment: InvoicePayment, onlyCalculation?: boolean): Invoice {
    if (!onlyCalculation) {
      invoice.invoicePayments.push(payment);
    }
    const invoicePaymentsTotal = invoice.getPaymentsTotal();
    if (invoicePaymentsTotal >= invoice.amount) {
      invoice.setInvoiceAsPaid();
      if (invoice.customer && invoice.date) {
        this.CustomerService.updateLastVisitDate(invoice.customer, invoice.date);
      }
    }
    this.logger.info('The payment was added to the invoice', { payment, invoiceUuid: invoice.uuid });
    return invoice;
  }

  getProviderByMethod(methodName: string): PAYMENT_PROVIDERS {
    const currentName = methodName.toLowerCase().replace(REGEXPS.ANY_SPACES, '');
    if (this.isPaymentMethod(currentName, 'sumup')) return PAYMENT_PROVIDERS.SUMUP;
    if (this.isPaymentMethod(currentName, 'ccvfly')) return PAYMENT_PROVIDERS.CCV;
    if (this.isPaymentMethod(currentName, 'terminalsix')) return PAYMENT_PROVIDERS.SIX;
    if (this.isPaymentMethod(currentName, 'terminalopi')) return PAYMENT_PROVIDERS.OPI;
    if (this.isPaymentMethod(currentName, 'myposglass')) return PAYMENT_PROVIDERS.MYPOSGLASS;
    if (this.isPaymentMethod(currentName, 'mypos')) return PAYMENT_PROVIDERS.MYPOS;
    if (this.isPaymentMethod(currentName, 'paymashpay')) return PAYMENT_PROVIDERS.PAYMASH_PAY;
    return null;
  }

  private getProviderByMethodWithSupportedTwint(methodName: string): PAYMENT_PROVIDERS {
    methodName = methodName.toUpperCase();
    if (methodName === DefaultPaymentMethods.TWINT) {
      const currency = this.SecurityService.getLoggedCompanyData()?.locale?.currency || '';
      if (currency === LOCALE.Currency.CHF) {
        const paymentProvider = this.getProviderByActiveTerminal();
        if (paymentProvider) {
          return paymentProvider;
        }
      }
    }
    return this.getProviderByMethod(methodName);
  }

  private getProviderByActiveTerminal(): PAYMENT_PROVIDERS {
    const activeTerminal: IActiveTerminal = this.localStorage.getObject(StorageKeys.activeTerminal);
    if (!activeTerminal.title) {
      return null;
    }
    const paymentProvider = this.getProviderByMethod(activeTerminal.title);
    if (paymentProvider === PAYMENT_PROVIDERS.PAYMASH_PAY) {
      return paymentProvider;
    }
    return null;
  }

  private isPaymentMethod(method: string, paymentMethodName: string): boolean {
    return method.indexOf(paymentMethodName) !== -1;
  }

  public splitInvoice(invoice: Invoice): ISplitInvoices {
    this.logger.debug('Start splitting invoice', { invoiceUuid: invoice.uuid });
    const { first, second } = SplitInvoiceTypes;
    const firstInvoice = new Invoice(invoice);
    let { invoiceEntries } = firstInvoice;
    invoiceEntries = this.multipleGuestsService.calculateSplitInvoiceWithGuests(invoiceEntries);
    const secondInvoice = this.createInvoice();
    firstInvoice.invoiceEntries = [];
    for (let i = 0; i < invoiceEntries.length; i++) {
      const currentEntry = invoiceEntries[i];
      if (currentEntry.deleted) {
        firstInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, first, true));
        continue;
      }
      if (currentEntry.quantity === 0) {
        firstInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, first, true));
        secondInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, second));
        continue;
      }
      if (currentEntry.quantityForSubtract === 0) {
        firstInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, first));
        continue;
      }
      firstInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, first));
      secondInvoice.invoiceEntries.push(this.adjustInvoiceEntryForSplit(currentEntry, second));
    }

    if (secondInvoice.invoiceEntries.length > 0) {
      secondInvoice.store = invoice.store;
      secondInvoice.employee = invoice.employee;
      secondInvoice.customer = invoice.customer;
      secondInvoice.customerDiscount = invoice.customerDiscount;
      secondInvoice.customerDiscountPercentage = invoice.customerDiscountPercentage;
      secondInvoice.gastronomyTable = invoice.gastronomyTable;
      secondInvoice.gastronomyTableName = invoice.gastronomyTableName;
    }

    firstInvoice && this.calculateInvoiceAmountAfterDiscount(firstInvoice);
    secondInvoice && this.calculateInvoiceAmountAfterDiscount(secondInvoice);

    this.logger.info('Invoice was split on to firstInvoice and secondInvoice', {
      invoiceUuid: invoice.uuid,
      firstInvoiceUuid: firstInvoice.uuid,
      secondInvoiceUuid: secondInvoice.uuid,
    });
    return {
      firstInvoice,
      secondInvoice,
    };
  }

  public async splitInvoicesByGuest(invoice: Invoice, guestNumber: number): Promise<ISplitInvoices> {
    try {
      this.logger.debug('Start splitting invoice by guest', { invoiceUuid: invoice.uuid, guestNumber });
      const newInvoice = new Invoice(invoice);
      newInvoice.invoiceEntries.forEach((invoiceEntry) => {
        if (invoiceEntry.guestNumber === guestNumber) {
          invoiceEntry.quantityForSubtract = invoiceEntry.quantity;
          invoiceEntry.quantity = 0;
        }
      });

      const { firstInvoice, secondInvoice } = this.splitInvoice(newInvoice);
      firstInvoice.invoiceEntries = this.multipleGuestsService.updateGuestNumbers(firstInvoice.invoiceEntries, guestNumber);
      secondInvoice.invoiceEntries = this.multipleGuestsService.updateGuestNumbers(secondInvoice.invoiceEntries, guestNumber);

      secondInvoice.customer = null;
      secondInvoice.isSeparated = false;
      secondInvoice.gastronomyTable = null;
      secondInvoice.gastronomyTableName = null;

      this.logger.info('Invoice was split by guestNumber on firstInvoice and secondInvoice', {
        invoiceUuid: invoice.uuid,
        guestNumber,
        firstInvoiceUuid: firstInvoice.uuid,
        secondInvoiceUuid: secondInvoice.uuid,
      });
      return {
        firstInvoice,
        secondInvoice,
      };
    } catch (error) {
      this.logger.error(error, 'splitInvoicesByGuest:saveInvoice');
      throw new Error('First invoice save error');
    }
  }

  public checkInvoiceForReimburseItems(invoice: Invoice) {
    if (!invoice && !invoice.invoiceEntries) return false;
    let isInvoiceHasReimburseItems: boolean = false;
    for (let i = 0; i < invoice.invoiceEntries.length; i++) {
      if (!invoice.invoiceEntries[i].deleted && invoice.invoiceEntries[i].quantity < 0) {
        isInvoiceHasReimburseItems = true;
      }
    }
    return isInvoiceHasReimburseItems;
  }

  public saveInvoice(invoice: Invoice, withoutSync?: boolean): Promise<Invoice> {
    invoice.localModificationDate = moment.utc().toISOString();
    return new Promise((resolve, reject) => {
      this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.Invoice.type, [invoice])
        .then((data) => {
          const newInvoiceData = new Invoice(data['data'][0]);
          if (!withoutSync) {
            this.SyncService.addDataToSyncList(new SyncListItem(newInvoiceData, UPDATES_TYPES.Invoice.type)).catch((err) =>
              this.logger.error(err, 'saveInvoice:SyncService:addDataToSyncLis')
            );
          }
          resolve(newInvoiceData);
        })
        .catch(reject);
    });
  }

  public getInvoiceId(): string {
    let loggedUserData: any = this.SecurityService.getLoggedUserData();
    let invoiceId: string = loggedUserData.pos.channelId + '-' + this.SecurityService.getNextInvoiceNumber();
    this.SecurityService.increaseNextInvoiceNumber();
    this.logger.info('New invoice id has been generated', { invoiceId });
    return invoiceId;
  }

  public getInvoiceEntriesForKitchen(invoiceEntries: InvoiceEntry[], guestNumber: number = 0): InvoiceEntry[] {
    return invoiceEntries.filter((invoiceEntry) => {
      if (invoiceEntry.quantityForKitchenReceipt > 0 || invoiceEntry.isGuest) {
        const isEqualGuestNumbers = guestNumber && guestNumber === invoiceEntry.guestNumber;
        return !guestNumber || isEqualGuestNumbers;
      }
    });
  }

  private adjustInvoiceEntryForSplit(invoiceEntry: InvoiceEntry, invoiceType: SplitInvoiceTypes, deleted: boolean = false): InvoiceEntry {
    const newInvoiceEntry = new InvoiceEntry(invoiceEntry);
    newInvoiceEntry.increaseLocalModificationDate();

    let { quantityForKitchenReceipt } = invoiceEntry;
    const subQuantity = invoiceType === SplitInvoiceTypes.second ? invoiceEntry.quantity : invoiceEntry.quantityForSubtract;
    quantityForKitchenReceipt = quantityForKitchenReceipt - subQuantity;
    newInvoiceEntry.quantityForKitchenReceipt = quantityForKitchenReceipt > 0 ? quantityForKitchenReceipt : 0;

    if (invoiceType === SplitInvoiceTypes.second) {
      newInvoiceEntry.quantity = newInvoiceEntry.quantityForSubtract;
      newInvoiceEntry.uuid = this.UuidService.generate();
    }

    if (deleted) {
      newInvoiceEntry.quantityForKitchenReceipt = 0;
    }

    newInvoiceEntry.quantityForSubtract = 0;
    newInvoiceEntry.deleted = deleted;
    return newInvoiceEntry;
  }

  public getEntryByUuidAndCategory(type: string, data: any, invoiceEntries: InvoiceEntry[]): number[] {
    let foundValues: number[] = [];
    const productTypes = [PRODUCT_TYPES.PRODUCT, PRODUCT_TYPES.TIPS, PRODUCT_TYPES.SERVICE];
    if (productTypes.includes(type)) {
      const uuidToFound = data.variant?.uuid || data.productVariant?.uuid;
      let newCategory = data.productCategory ? (data.productCategory.uuid ? data.productCategory.uuid : data.productCategory) : null;
      if (newCategory === 'root') newCategory = null;

      for (let i = 0; i < invoiceEntries.length; i++) {
        const current = invoiceEntries[i];
        if (!current.deleted && productTypes.includes(current.type)) {
          let currentCategory = current.productCategory ? current.productCategory['uuid'] : null;
          if (currentCategory === 'root') currentCategory = null;
          if (currentCategory === newCategory) {
            data = new InvoiceEntry(data);
            const isEqualGuestNumber = data.guestNumber === current.guestNumber;
            if (data.hasOwnProperty('position') && data.position === current.position && data.uuid === current.uuid && isEqualGuestNumber) {
              foundValues = [...foundValues, i];
            } else if (current.discountedPrice === data.discountedPrice) {
              if (current.productVariant['uuid'] === uuidToFound && current.note === data.note && isEqualGuestNumber) {
                foundValues = [...foundValues, i];
              } else if (current.uuid && data.uuid && current.uuid === data.uuid && current.note === data.note && isEqualGuestNumber) {
                foundValues = [...foundValues, i];
              }
            }
          }
        }
      }
    } else if (type === PRODUCT_TYPES.INDIVIDUAL) {
      for (let i = 0; i < invoiceEntries.length; i++) {
        const current = invoiceEntries[i];
        const isIndividualProduct = current.type === PRODUCT_TYPES.INDIVIDUAL && !current.deleted;
        const isEqualProductUuid = data.product && current.product && data.product.uuid === current.product.uuid;
        if (isIndividualProduct && (current.uuid === data.uuid || isEqualProductUuid)) {
          foundValues = [...foundValues, i];
        }
      }
    }
    return foundValues;
  }

  public mergeInvoiceEntries(invoiceEntries: Array<InvoiceEntry>): Array<InvoiceEntry> {
    let mergedInvoiceEntries: Array<InvoiceEntry> = [];
    let duplicatedIndexes: Array<Array<number>> = [];
    for (let i = 0; i < invoiceEntries.length; i++) {
      let currentEntryIndexes: Array<number> = this.getEntryByUuidAndCategory(invoiceEntries[i].type, invoiceEntries[i], invoiceEntries);
      if (currentEntryIndexes.length > 1 && !this.checkForExistingItems(duplicatedIndexes, currentEntryIndexes)) {
        duplicatedIndexes.push(currentEntryIndexes);
      }
      if (currentEntryIndexes.length < 2) {
        mergedInvoiceEntries.push(invoiceEntries[i]);
      }
    }
    for (let j = 0; j < duplicatedIndexes.length; j++) {
      mergedInvoiceEntries.push(this.mergeDuplicatedEntries(duplicatedIndexes[j], invoiceEntries));
    }
    return mergedInvoiceEntries;
  }

  private checkForExistingItems(duplicatedIndexes: Array<Array<number>>, currentEntryIndexes: Array<number>): boolean {
    for (let i = 0; i < duplicatedIndexes.length; i++) {
      let sortFunction = (a, b) => {
        return a - b;
      };
      duplicatedIndexes[i].sort(sortFunction);
      currentEntryIndexes.sort(sortFunction);
      if (duplicatedIndexes[i].toString().indexOf(currentEntryIndexes.toString()) !== -1) return true;
    }
    return false;
  }

  private mergeDuplicatedEntries(duplicatedIndexes: Array<number>, invoiceEntries: Array<InvoiceEntry>): InvoiceEntry {
    let mergedInvoiceEntry: InvoiceEntry;
    for (let i = 0; i < duplicatedIndexes.length; i++) {
      if (!mergedInvoiceEntry) {
        mergedInvoiceEntry = invoiceEntries[duplicatedIndexes[i]];
      } else {
        mergedInvoiceEntry.totalAmount += invoiceEntries[duplicatedIndexes[i]].totalAmount;
        mergedInvoiceEntry.quantity += invoiceEntries[duplicatedIndexes[i]].quantity;
        mergedInvoiceEntry.quantityForKitchenReceipt += invoiceEntries[duplicatedIndexes[i]].quantityForKitchenReceipt;
      }
    }
    return mergedInvoiceEntry;
  }

  cancelInvoice(invoice: Invoice, paymentMethod: string, cancellationInvoiceParams: ICancellationInvoiceParams): Promise<Invoice> {
    this.logger.debug('Start cancelling invoice by paymentMethod', { invoiceUuid: invoice.uuid, paymentMethod });
    const employee: Employee = this.facadeEmployeeState.activeEmployeeSnapshot;
    const { cancellationReason = '', restockOnCancellation, refundToGiftCardIdentifier, cancellationType } = cancellationInvoiceParams;
    invoice.cancellationReason = cancellationReason;
    invoice.restockOnCancellation = restockOnCancellation;
    invoice.cancellingEmployee = employee;

    const invoicePartiallyCancel = new InvoicePartiallyCancel(invoice, paymentMethod);
    invoicePartiallyCancel.setInvoiceEntries(invoice.invoiceEntries);
    return this.DbDaoService.getAllData(UPDATES_TYPES.PaymentMethod.type, { method: paymentMethod })
      .then((data) => {
        const selectedPaymentMethod = new PaymentMethod(data.data[0] || {});
        const paymentProvider = this.getProviderByMethodWithSupportedTwint(selectedPaymentMethod.name);
        const supportedPaymentProviders = [PAYMENT_PROVIDERS.MYPOS, PAYMENT_PROVIDERS.MYPOSGLASS, PAYMENT_PROVIDERS.PAYMASH_PAY];
        if (supportedPaymentProviders.includes(paymentProvider)) {
          const currency = this.SecurityService.getLoggedCompanyData()?.locale?.currency || '';
          return this.cancelInvoiceOnTerminal(
            invoice.amount,
            currency,
            // TODO: PMH-3624 selectedPaymentMethod.method,
            selectedPaymentMethod.name,
            invoice.uuid,
            this.UuidService.generate(),
            paymentProvider
          );
        }

        if (paymentProvider) {
          throw PaymentError.REFUND_NOT_SUPPORTED;
        }

        if (selectedPaymentMethod.method === DefaultPaymentMethods.GIFTCARD) {
          invoicePartiallyCancel.refundToGiftCardIdentifier = refundToGiftCardIdentifier;
        }
      })
      .then((methodName) => {
        if (methodName) {
          invoicePartiallyCancel.refundPaymentMethod = methodName;
        }
        this.logger.info('Invoice will send to server', { invoiceUuid: invoice.uuid, invoicePartiallyCancel });

        if (cancellationType === CancellationTypes.full) {
          return this.invoicesApiService.upsertCancelFully(invoicePartiallyCancel);
        }
        return this.invoicesApiService.upsertCancelPartially(invoicePartiallyCancel);
      })
      .then((invoice) => {
        this.logger.info('Invoice was successfully canceled', { invoiceUuid: invoice.uuid });
        return this.saveInvoice(invoice, true);
      });
  }

  private cancelInvoiceOnTerminal(
    amount: number,
    currency: string,
    paymentMethod: string,
    invoiceUuid: string,
    paymentUuid: string,
    paymentProvider: PAYMENT_PROVIDERS
  ): Promise<string> {
    return this.PaymentService.makeTransaction({
      paymentProvider,
      amount,
      currency,
      invoiceUuid,
      paymentMethod,
      paymentUuid,
      transactionType: PAYMENT_TRANSACTION.PAYMENT_REFUND,
    }).then((paymentResult) => this.getPaymentMethodByPaymentType(paymentResult.cardName, paymentMethod));
  }

  public removeOldInvoicesFromLocalDb() {
    const duration = this.posSettingsService.getInvoicesKeepDuration();
    const queryParams = query(
      {
        isDraft: false,
        sequentId: greaterThan(0),
      },
      group(
        { date: greaterOrEqualsThan(0) },
        { date: lessOrEqualsThan(moment.utc().subtract(duration, 'day').startOf('day').toISOString()) }
      )
    );
    this.DbDaoService.remove(UPDATES_TYPES.Invoice.type, queryParams)
      .then(() => this.logger.debug('removeOldInvoicesFromLocalDb'))
      .catch((err) => this.logger.error(err, 'removeOldInvoicesFromLocalDb:removeDataByParams'));
  }

  public makeInvoiceEntryToAddData(variant: ProductVariant, product: Product, productShortName: string, productCategoryUuid: string): any {
    let productName = product.name;
    if (productShortName.length > 0) {
      productName += ` (${productShortName})`;
    }

    //TODO adjust image search
    return {
      variant: variant,
      price: variant.price,
      taxRate: variant.vatRate['value'],
      productCategory: productCategoryUuid,
      name: productName,
      shortName: productShortName,
      image: null,
      wasPrice: variant.wasPrice,
    };
  }

  public addInvoiceEntryToInvoice(invoice: Invoice, newEntryData: any, entryType: string, quantity: number): Invoice {
    this.logger.debug('Start adding invoice entry to invoice', { invoiceUuid: invoice.uuid });
    const invoiceEntry = this.createInvoiceEntry(newEntryData, entryType, quantity);
    invoiceEntry.position = invoice.invoiceEntries.length;
    invoice.invoiceEntries.push(invoiceEntry);
    this.logger.info('Invoice entry has been added with quantity to invoice', {
      invoiceEntryUuid: invoiceEntry.uuid,
      quantity: invoiceEntry.quantity,
      invoiceUuid: invoice.uuid,
    });
    return new Invoice(invoice);
  }

  public createInvoiceEntry(newEntryData: any, entryType: string, quantity: number): InvoiceEntry {
    // if product NOT exists in cart
    // push product to cart
    // set product quantity to 1 if it not already have quantity field

    let taxRate = 0;
    if (newEntryData.taxRate) {
      taxRate = newEntryData.taxRate;
    } else if (newEntryData.variant) {
      taxRate = newEntryData.variant.vatRate.value;
    }
    const entryToAdd: any = {
      type: entryType,
      name: newEntryData.name,
      shortName: newEntryData.shortName,
      image: newEntryData.image,
      note: newEntryData.note,
      quantity: quantity,
      quantityForKitchenReceipt: quantity || 1,
      price: newEntryData.price,
      taxRate: taxRate,
      discountedPrice: newEntryData.discountedPrice,
      discount: newEntryData.discount,
      discountPercentage: newEntryData.discountPercentage,
      bgColor: newEntryData.bgColor,
      employee: {
        uuid: this.facadeEmployeeState.activeEmployeeSnapshot.uuid,
      },
      uuid: this.UuidService.generate(),
    };

    entryToAdd.store = {
      uuid: this.SecurityService.getActiveStore().uuid,
    };

    const types = [PRODUCT_TYPES.PRODUCT, PRODUCT_TYPES.GIFT_CARD, PRODUCT_TYPES.TIPS, PRODUCT_TYPES.SERVICE];
    if (types.includes(entryType)) {
      entryToAdd.productVariant = {
        uuid: newEntryData.variant ? newEntryData.variant.uuid : newEntryData.productVariant['uuid'],
      };

      if (newEntryData.productCategory) {
        entryToAdd.productCategory = {
          uuid: newEntryData.productCategory,
        };
      } else {
        entryToAdd.productCategory = null;
      }

      entryToAdd.inventoryStore = {
        uuid: this.SecurityService.getActiveStore().uuid,
      };
    }
    return new InvoiceEntry(entryToAdd);
  }

  public deleteInvoice(invoiceToDelete: Invoice) {
    invoiceToDelete.deleted = true;
    this.logger.info('Draft invoice has been removed', { invoiceUuid: invoiceToDelete.uuid });
    return this.saveInvoice(invoiceToDelete);
  }

  public combineInvoices(firstInvoiceUuid: string, secondInvoiceUuid: string): Promise<Invoice> {
    return new Promise((resolve, reject) => {
      this.logger.debug('Start to combine invoices firstInvoiceUuid and secondInvoiceUuid', { firstInvoiceUuid, secondInvoiceUuid });
      let firstInvoice: Invoice;
      let secondInvoice: Invoice;

      this.DbDaoService.getDataByUUID(UPDATES_TYPES.Invoice.type, firstInvoiceUuid)
        .then((data) => {
          firstInvoice = new Invoice(data['data']);
          this.logger.debug('combineInvoices:firstInvoice', { firstInvoice: ignoreImageProps(firstInvoice) });
          return this.DbDaoService.getDataByUUID(UPDATES_TYPES.Invoice.type, secondInvoiceUuid);
        })
        .then((data) => {
          secondInvoice = new Invoice(data['data']);
          this.logger.debug('combineInvoices:secondInvoice', { secondInvoice: ignoreImageProps(secondInvoice) });
          firstInvoice.customer = firstInvoice.customer || secondInvoice.customer;

          let entriesToBePushed: InvoiceEntry[] = [];
          let maxGuestNumber = 0;
          const hasFirstInvoiceEntryGuests = firstInvoice.hasGuestInvoiceEntry();
          if (hasFirstInvoiceEntryGuests) {
            const guestNumbers = firstInvoice.getGuestInvoiceEntries().map((invoiceEntry) => invoiceEntry.guestNumber);
            maxGuestNumber = Math.max(...guestNumbers, maxGuestNumber);
          }

          const hasSecondInvoiceEntryGuests = secondInvoice.hasGuestInvoiceEntry();
          secondInvoice.invoiceEntries.forEach((item) => {
            if (hasFirstInvoiceEntryGuests && hasSecondInvoiceEntryGuests) {
              const currentGuestNumber = item.guestNumber;
              item.guestNumber += maxGuestNumber;
              if (item.isGuest) {
                item.name = this.multipleGuestsService.getGuestName(currentGuestNumber, item.name, item.guestNumber);
              }
            }

            const entryIndexes = this.getEntryByUuidAndCategory(item.type, item, firstInvoice.invoiceEntries);

            if (entryIndexes.length > 0) {
              const itemForUpdate = firstInvoice.invoiceEntries[entryIndexes[0]];

              itemForUpdate.quantity += item.quantity;
              itemForUpdate.quantityForKitchenReceipt += item.quantityForKitchenReceipt;
              if (itemForUpdate.specialTaxing !== item.specialTaxing) {
                itemForUpdate.specialTaxing = SpecialTaxing.inHouse;
              }
            } else {
              const entryToPush = new InvoiceEntry(item);
              entryToPush.uuid = this.UuidService.generate();
              entriesToBePushed = [...entriesToBePushed, entryToPush];
            }
          });

          firstInvoice.invoiceEntries = firstInvoice.invoiceEntries.concat(entriesToBePushed);
          firstInvoice.employee = { uuid: this.facadeEmployeeState.activeEmployeeSnapshot.uuid };
          firstInvoice.modificationDate = moment.utc().toISOString();

          if (firstInvoice.discountPercentage === 0 && firstInvoice.discount === 0) {
            firstInvoice.discountPercentage = secondInvoice.discountPercentage;
            firstInvoice.discount = secondInvoice.discount;
          }

          if (firstInvoice.customerDiscount === 0) {
            firstInvoice.customerDiscount = secondInvoice.customerDiscount;
          }

          if (firstInvoice.customerDiscountPercentage === 0 && firstInvoice.customerDiscount === 0) {
            firstInvoice.customerDiscountPercentage = secondInvoice.customerDiscountPercentage;
          }

          if (firstInvoice.invoiceEntries.some(({ specialTaxing }): boolean => specialTaxing === SpecialTaxing.takeAway)) {
            return this.recalculateTaxRateInvoiceToInHouse(firstInvoice);
          }
          return firstInvoice;
        })
        .then((mainInvoice) => {
          this.calculateInvoiceAmountAfterDiscount(mainInvoice);
          this.saveInvoice(mainInvoice)
            .then((invoice) => {
              this.logger.info(`Invoices: first ${firstInvoiceUuid}, second ${secondInvoiceUuid} were combined`, { mainInvoice, secondInvoice });
              resolve(invoice);
            })
            .catch((err) => {
              this.logger.error(err, 'combineSelectedInvoices:saveInvoice');
              reject(new Error('First invoice save error'));
            });
          this.deleteInvoice(secondInvoice).catch((err) => {
            this.logger.error(err, 'combineSelectedInvoices:deleteInvoice');
            reject(new Error('Second invoice deletion error'));
          });
        })
        .catch((err) => {
          this.logger.error(err, 'combineSelectedInvoices:getDataByUUID');
          reject(new Error("Can't get invoices from DB"));
        });
    });
  }

  private async recalculateTaxRateInvoiceToInHouse(mainInvoice: Invoice): Promise<Invoice> {
    const invoiceEntries = mainInvoice.invoiceEntries.filter(({ specialTaxing }): boolean => specialTaxing === SpecialTaxing.takeAway);
    for (const entry of invoiceEntries) {
      if (entry.productVariant) {
        const data: DbResponse = await this.DbDaoService.getDataByUUID(UPDATES_TYPES.ProductVariant.type, entry.productVariant.uuid);
        const rate = data.data?.vatRate.value;
        if (rate) {
          entry.specialTaxing = SpecialTaxing.inHouse;
          entry.taxRate = rate;
        }
      }
    }
    return mainInvoice;
  }

  public subInvoices(invoice: Invoice, subInvoices: Invoice[]): Invoice {
    if (!subInvoices.length) {
      return invoice;
    }
    const invoiceEntries = this.multipleGuestsService.calculateSplitInvoiceWithGuests(invoice.invoiceEntries);
    const subInvoiceEntries = [].concat(...subInvoices.map((invoice) => invoice.invoiceEntries));
    subInvoiceEntries.forEach((subInvoiceEntry) => {
      const entryIndexes = this.getEntryByUuidAndCategory(subInvoiceEntry.type, subInvoiceEntry, invoiceEntries);
      const currentEntry = invoiceEntries[entryIndexes[0]];

      if (currentEntry) {
        currentEntry.quantity -= subInvoiceEntry.quantity;
        const guestInvoiceEntry = invoice.getGuestInvoiceEntryByGuestNumber(currentEntry.guestNumber);
        if (guestInvoiceEntry) {
          guestInvoiceEntry.quantity -= subInvoiceEntry.quantity;
        }
      }

    });
    this.calculateInvoiceAmountAfterDiscount(invoice);
    return invoice;
  }

  public isPartiallyCancelled(invoice: Invoice, cancellationInvoices: Invoice[]): boolean {
    if (invoice.invoiceType !== INVOICE_TYPES.INVOICE || !cancellationInvoices.length) {
      return false;
    }

    const newInvoice = new Invoice(invoice);
    if (newInvoice.isUnpaidInvoiceWithOnInvoicePaymentMethod()) {
      newInvoice.invoiceEntries = newInvoice.getActiveInvoiceEntries().filter((entry) => entry.type !== PRODUCT_TYPES.GIFT_CARD);
    }

    let totalProductsCount = this.getTotalProductsCountByInvoiceEntries(newInvoice.invoiceEntries);
    cancellationInvoices
      .filter(
        (cancellationInvoice) =>
          cancellationInvoice.originalInvoiceReference && cancellationInvoice.originalInvoiceReference.uuid === newInvoice.uuid
      )
      .map((cancellationInvoice) => {
        totalProductsCount -= this.getTotalProductsCountByInvoiceEntries(cancellationInvoice.invoiceEntries);
      });
    return totalProductsCount !== 0;
  }

  private getTotalProductsCountByInvoiceEntries(invoiceEntries: InvoiceEntry[]): number {
    return invoiceEntries.reduce((totalProductsCount, entry) => {
      if (!entry.deleted && entry.type !== PRODUCT_TYPES.CATEGORY) {
        totalProductsCount += Math.abs(entry.quantity);
      }
      return totalProductsCount;
    }, 0);
  }

  public mergeUpdateAndActiveInvoiceEntries(
    invoiceEntries: InvoiceEntry[],
    activeInvoiceEntries: InvoiceEntry[]
  ): IMergeUpdateAndActiveInvoiceEntries {
    let saveRequired = false;
    const entries = invoiceEntries.map((invoiceEntry) => {
      const activeInvoiceEntry = activeInvoiceEntries.find((i) => i.uuid === invoiceEntry.uuid);
      if (this.isChangedActiveInvoiceEntry(invoiceEntry, activeInvoiceEntry)) {
        saveRequired = true;
        return activeInvoiceEntry;
      }
      return invoiceEntry;
    });

    return {
      invoiceEntries: entries,
      isSaveRequired: saveRequired,
    };
  }

  private isChangedActiveInvoiceEntry(invoiceEntry: InvoiceEntry, activeInvoiceEntry: InvoiceEntry) {
    return activeInvoiceEntry && invoiceEntry.localModificationDate < activeInvoiceEntry.localModificationDate;
  }

  updateBrokenInvoicesWithDeletePayments() {
    const queryParams = query({
      isDraft: true,
      invoicePayments: arrayIsNotEmpty(),
    });
    return this.DbDaoService.getAllData(UPDATES_TYPES.Invoice.type, queryParams)
      .then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const invoice = new Invoice(data.data[i]);
          const invoicePaymentsTotal = invoice.getPaymentsTotal();
          if (invoicePaymentsTotal >= invoice.amount) {
            this.logger.info('Invoice is broken and should be updated and synchronized', { invoiceUuid: invoice.uuid });
            invoice.invoiceId = invoice.invoiceId || this.getInvoiceId();
            invoice.date = invoice.date || invoice.localCreationDate;
            invoice.setInvoiceAsPaid();
            this.saveInvoice(invoice).catch((err) => this.logger.error(err, 'repeatSyncForInvoices:saveInvoice'));
          }
        }
      })
      .catch((err) => {
        this.logger.error(err, 'updateBrokenInvoices:getDataByParams');
        throw err;
      });
  }

  updatePaidInvoicesWithoutSequentId() {
    const queryParams = query({
      isPaid: true,
      isDraft: false,
      sequentId: 0,
    });
    return this.DbDaoService.getAllData(UPDATES_TYPES.Invoice.type, queryParams)
      .then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const invoice = new Invoice(data.data[i]);
          this.logger.info('Invoice has not sequentId and should be updated and synchronized', { invoiceUuid: invoice.uuid });
          this.saveInvoice(invoice).catch((err) => this.logger.error(err, 'repeatSyncForInvoices:saveInvoice'));
        }
      })
      .catch((err) => {
        this.logger.error(err, 'updateBrokenInvoices:getDataByParams');
        throw err;
      });
  }

  calculateInvoiceAmountAfterDiscount(invoice: Invoice, currentCompanyCurrencyRounding: string = '') {
    currentCompanyCurrencyRounding = this.getCurrentCompanyCurrencyRounding(currentCompanyCurrencyRounding);
    invoice.calculateInvoiceAmountAfterDiscount(currentCompanyCurrencyRounding);
  }

  calculateCancellationInvoiceAmountAfterDiscount(
    invoice: Invoice,
    originInvoice: Invoice = null,
    currentCompanyCurrencyRounding: string = ''
  ) {
    currentCompanyCurrencyRounding = this.getCurrentCompanyCurrencyRounding(currentCompanyCurrencyRounding);
    let originAmountInvoice = originInvoice ? originInvoice.calculateAmountBeforeDiscount() : 0;
    originAmountInvoice = MathUtils.roundByFinalRoundingSize(originAmountInvoice, LOCALE.CurrencyRounding[currentCompanyCurrencyRounding]);
    invoice.calculateInvoiceAmountAfterDiscount(currentCompanyCurrencyRounding, originAmountInvoice);
  }

  private getCurrentCompanyCurrencyRounding(currentCompanyCurrencyRounding: string = '') {
    if (!currentCompanyCurrencyRounding) {
      const currentCompany = this.SecurityService.getLoggedCompanyData();
      return currentCompany.locale.currencyRounding;
    }
    return currentCompanyCurrencyRounding;
  }

  getTaxes(invoice: Invoice): InvoiceDetailsTaxItem[] {
    const taxes: InvoiceDetailsTaxItem[] = [];
    const invoiceEntries = invoice.getActiveInvoiceEntriesWithoutTypes(PRODUCT_TYPES.CATEGORY, PRODUCT_TYPES.SHIPPING_COST);
    let groupInvoiceEntries = this.getGroupInvoiceEntries(invoiceEntries);

    for (const entry of groupInvoiceEntries) {
      let { totalAmount } = entry;
      const discountedPrice = totalAmount;

      if (invoice.hasDiscountPercentage()) {
        const discountPercentage = MathUtils.roundHalfUp(totalAmount * (invoice.discountPercentage / 100), 2);
        totalAmount = MathUtils.normalizeSubtraction(totalAmount, discountPercentage);
      }

      if (invoice.hasCustomerDiscountPercentage()) {
        const customerDiscountPercentage = MathUtils.roundHalfUp(totalAmount * (invoice.customerDiscountPercentage / 100), 2);
        totalAmount = MathUtils.normalizeSubtraction(totalAmount, customerDiscountPercentage);
      }

      if (invoice.discountCode?.type === DISCOUNT_CODE_TYPES.PERCENTAGE) {
        const discountPercentage = MathUtils.roundHalfUp(totalAmount * (invoice.discountCode.value / 100), 2);
        totalAmount = MathUtils.normalizeSubtraction(totalAmount, discountPercentage);
      }

      if (invoice.hasDiscount() || invoice.hasCustomerDiscount() || invoice.discountCode?.type === DISCOUNT_CODE_TYPES.FIXED) {
        const amountWithoutShippingCost = invoice.getAmountWithoutShippingCost(invoice.totalAmountWithoutDiscount);
        const totalDiscount = invoice.discount + invoice.customerDiscount + invoice.discountCodeDiscount;
        const discount = (discountedPrice / amountWithoutShippingCost) * totalDiscount;
        totalAmount = MathUtils.normalizeSubtraction(totalAmount, discount);
      }
      entry.totalAmount = totalAmount;
    }

    const shippingCost = invoice.getShippingCostInvoiceEntry();
    if (shippingCost) {
      groupInvoiceEntries = this.getGroupInvoiceEntries([...groupInvoiceEntries, shippingCost]);
    }

    for (const entry of groupInvoiceEntries) {
      const { taxRate, totalTaxAmount } = entry;
      let { totalAmount } = entry;

      let netAmount = MathUtils.normalizeSubtraction(totalAmount, totalTaxAmount);
      if (invoice.isNet) {
        netAmount = totalAmount;
        totalAmount = MathUtils.normalizeAddition(totalAmount, totalTaxAmount);
      }

      const tax = taxes.find((t) => t.taxRate === taxRate);
      if (tax) {
        tax.totalTaxAmount = MathUtils.normalizeAddition(tax.totalTaxAmount, totalTaxAmount);
        tax.totalAmount = MathUtils.normalizeAddition(tax.totalAmount, totalAmount);
        tax.netAmount = MathUtils.normalizeAddition(tax.netAmount, netAmount);
      } else {
        taxes.push({ taxRate, netAmount, totalTaxAmount, totalAmount });
      }
    }
    return taxes;
  }

  private getGroupInvoiceEntries(invoiceEntries: InvoiceEntry[]): InvoiceEntry[] {
    return invoiceEntries.reduce((groupInvoiceEntries, invoiceEntry) => {
      const newInvoiceEntry = groupInvoiceEntries.find((i) => i.taxRate === invoiceEntry.taxRate);
      if (newInvoiceEntry) {
        newInvoiceEntry.totalTaxAmount = MathUtils.normalizeAddition(newInvoiceEntry.totalTaxAmount, invoiceEntry.totalTaxAmount);
        newInvoiceEntry.totalAmount = MathUtils.normalizeAddition(newInvoiceEntry.totalAmount, invoiceEntry.totalAmount);
      } else {
        groupInvoiceEntries.push(new InvoiceEntry(invoiceEntry));
      }
      return groupInvoiceEntries;
    }, []);
  }
}
