import { createAction, props } from '@ngrx/store';
import { Invoice } from '@pos-common/classes/invoice.class';
import { SelfOrderOpenInvoice } from './invoices.reducer';

const actionGroupName: string = 'Invoices';

export const LoadSelfOrderInvoicesFromLocalDB = createAction(
  `${actionGroupName} Load SelfOrder Invoices From LocalDB`
);

export const LoadedSelfOrderInvoicesFromLocalDBSuccess = createAction(
  `${actionGroupName} Loaded SelfOrder Invoices From LocalDB Success`,
  props<{ invoices: Invoice[] }>()
);

export const LoadedSelfOrderInvoicesFromLocalDBFailure = createAction(
  `${actionGroupName} Loaded SelfOrder Invoices From LocalDB Failure`,
  props<{ error: Error }>()
);

export const SetCounterOpenSelfOrderInvoices = createAction(
  `${actionGroupName} Set Counter Open SelfOrder Invoices`,
  props<{ entities: SelfOrderOpenInvoice[] }>()
);

export const UpdateInvoices = createAction(
  `${actionGroupName} Update Invoices`,
  props<{ invoice: unknown }>()
);

export const UpdateSelfOrderInvoice = createAction(
  `${actionGroupName} Update SelfOrder Invoice`,
  props<{ invoice: Invoice }>()
);

export const UpdateOpenSelfOrderInvoice = createAction(
  `${actionGroupName} Update Open SelfOrder Invoice`,
  props<{ entity: SelfOrderOpenInvoice }>()
);

export const ReceivedNewOpenSelfOrderInvoice = createAction(
  `${actionGroupName} Received New Open SelfOrder Invoice`,
  props<{ isNew: boolean }>()
);

export const ClearOpenSelfOrderInvoices = createAction(
  `${actionGroupName} Clear Open SelfOrder Invoices`
)
