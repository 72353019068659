import { Injectable, Injector } from '@angular/core';
import { AdyenTenderOptionsEnum } from '../enums';
import { StorageKeys } from '@pos-common/constants';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { IActiveTerminal } from '@pos-common/interfaces';
import { Invoice } from '@pos-common/classes/invoice.class';
import { PosSettingsService } from '@pos-common/services/system/pos-settings.service';
import { LogService } from '@pos-common/services/system/logger';
import { ILogger } from '@spryrocks/logger';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import { TippingService } from '@pos-common/services/system/tipping/tipping.service';

@Injectable()
export class AdyenTippingService {
  private readonly logger: ILogger = this.logService.createLogger('AdyenTippingService');
  constructor(
    private logService: LogService,
    private localStorage: LocalStorage,
    private injector: Injector) { }

  public get add(): AdyenTenderOptionsEnum {
    return AdyenTenderOptionsEnum.Tipping;
  }

  public get isTippingAllowed(): boolean {
    const terminal: IActiveTerminal = this.localStorage.getObject(StorageKeys.activeTerminal);
    return terminal?.tipAllowed;
  }

  public get isLegacyPosTippingAllowed(): boolean {
    return this.getLegacyTippingSettings();
  }

  public isNeedAdyenNativeTippingAddFlow(provider: string, result: PaymentResult): boolean {
    return provider === PAYMENT_PROVIDERS.PAYMASH_PAY
    && this.isTippingAllowed
    && this.isLegacyPosTippingAllowed
    && !!result?.terminalTransaction?.nativeTippingAmount
  }

  public setLegacyTippingToInvoice(invoice: Invoice, amount: number ): Invoice {
    const tippingService: TippingService = this.injector.get(TippingService);
    this.logger.info('Legacy tipping for Adyen to invoice...', { invoice, amount });

    tippingService.setTipEvent(amount);
    invoice = tippingService.addTippingInvoiceEntryToInvoice(invoice, 1, 'ZERO_DOT_ZERO_ONE');
    return invoice;
  }

  private getLegacyTippingSettings(): boolean {
    const posSettings: PosSettingsService = this.injector.get(PosSettingsService);
    return posSettings.getTippingStatus();
  }

}
