import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { RootApplicationState } from './root.reducer';
import { InitLocalDBSuccess, UpdateDataEvent } from './root.actions';

@Injectable()
export class RootApplicationStateFacade {

  constructor(private store: Store<RootApplicationState>) {
  }

  public initLocalDBSuccess(): void {
    this.store.dispatch(InitLocalDBSuccess());
  }

  public updateData(updateData: unknown, updateDataType: string): void {
    this.store.dispatch(UpdateDataEvent({ updateData, updateDataType }));
  }

}
