import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { asyncScheduler, map, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectActiveEmployee } from '@pos-stores/employees';
import { Employee } from '@pos-common/classes/employee.class';
import { EmployeeLogout } from '@pos-stores/router';
import { observeOn, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActiveEmployeeGuard implements CanActivate {

  constructor(private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.store.select(selectActiveEmployee).pipe(
      observeOn(asyncScheduler),
      map((employee: Employee): boolean => {
        return !!employee;
      }),

      tap((canActivate: boolean) => {
        if (!canActivate) {
          this.store.dispatch(EmployeeLogout());
        }
      }),

      take(1)
    );
  }

}
