import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';

@Component({
  selector: 'processing-buttons',
  styleUrls: ['processing-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'processing-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingButtonsComponent {
  @Input() PaymentProcessingActions: PaymentProcessingActions;
  @Input() showCancelButton: boolean = false;
  @Input() showRetryButton: boolean = false;
  @Input() showCheckButton: boolean = false;
  @Input() showContinueButton: boolean = false;
  @Input() cancelButtonTitle;
  @Input() retryButtonTitle;
  @Input() checkButtonTitle;
  @Input() continueButtonTitle;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onRetry = new EventEmitter<void>();
  @Output() onCheck = new EventEmitter<void>();
  @Output() onContinue = new EventEmitter<void>();

  cancelButtonEvent() {
    this.onCancel.emit();
  }

  retryButtonEvent() {
    this.onRetry.emit();
  }

  checkButtonEvent() {
    this.onCheck.emit();
  }

  continueButtonEvent() {
    this.onContinue.emit();
  }
}
