import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, NgModule, ChangeDetectorRef } from '@angular/core';
import { IonicModule, MenuController, NavController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Employee } from '@pos-common/classes/employee.class';
import { Page } from '@pos-common/classes/page.class';
import { CurrentUserModule } from '@pos-common/components/current-user/current-user.component';
import { ROUTES_LIST } from '@pos-common/constants';
import { IntercomService } from '@pos-common/services';
import { InAppBrowserService } from '@pos-common/services/system';
import { AdminPageService } from '@pos-common/services/system/admin-page.service';
import { LogService } from '@pos-common/services/system/logger';
import { RouteNavigationService } from '@pos-common/services/system/route-navigation/route-navigation.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { combineLatest, filter } from 'rxjs';
import { EmployeesFacadeStore } from '@pos-stores/employees';

@Component({
  selector: 'pos-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  public pages = ROUTES_LIST;
  private isSelectedItemMenu = false;
  private logger = this.logService.createLogger('MenuComponent');

  constructor(
    private securityService: SecurityService,
    private menuController: MenuController,
    private inAppBrowserService: InAppBrowserService,
    private routeNavigationService: RouteNavigationService,
    private navController: NavController,
    private adminPageService: AdminPageService,
    private intercomService: IntercomService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private logService: LogService,
    private employeesFacadeStore: EmployeesFacadeStore
  ) {}

  ngOnInit() {
    this.handleEmployeeUpdateEvent();
  }

  private handleEmployeeUpdateEvent() {
    const activeEmployee$ = this.employeesFacadeStore.activeEmployee$;
    const loggedCompanyDataEvent = this.securityService.loggedCompanyData.asObservable();
    combineLatest([activeEmployee$, loggedCompanyDataEvent])
      .pipe(filter(([employee]) => !!employee))
      .subscribe(([employee]) => {
        this.pages = this.getPagesList(ROUTES_LIST, employee);
        this.cdr.detectChanges();
      });
  }

  goToPage(page: Page) {
    if (this.isSelectedItemMenu) {
      return;
    }
    this.isSelectedItemMenu = true;
    this.navigateRoot(page.url)
      .then(() => this.menuController.close())
      .catch((error) => this.logger.error(error, 'goToPage:MenuController:close'))
      .finally(() => (this.isSelectedItemMenu = false));
  }

  hideSideMenu() {
    return this.menuController.close();
  }

  private async navigateRoot(url: string): Promise<boolean | void> {
    switch (url) {
      case 'Admin':
        this.adminPageService.openSecured('app#/dashboard');
        break;
      case 'Help':
        return this.intercomService.show();
      case 'Refer':
        this.inAppBrowserService.openUrl(this.translateService.instant('refer_friend_link'));
        break;
      default:
        if (this.routeNavigationService.current !== url) {
          return this.navController.navigateRoot(url);
        }
        break;
    }
  }

  private getPagesList(pagesList: Page[], employee: Employee) {
    const company = this.securityService.getLoggedCompanyData();
    return pagesList.filter((currentPage) => {
      const { companySetting, permission } = currentPage;
      if (companySetting) {
        return !!company?.[companySetting];
      }
      if (permission) {
        return !!employee?.[permission];
      }
      return true;
    });
  }
}
@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule.forChild(), CurrentUserModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
})
export class MenuComponentModule {}
