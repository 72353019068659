export enum ProtocolVersion {
  v3_0 = '3.0',
}

export enum MessageClass {
  Device = 'Device',
  Service = 'Service',
}

export enum MessageCategory {
  Payment = 'Payment',
  Print = 'Print',
  Admin = 'Admin',
  TransactionStatus = 'TransactionStatus',
  Diagnosis = 'Diagnosis',
}

export enum MessageType {
  Request = 'Request',
}

export enum DocumentQualifier {
  CashierReceipt = 'CashierReceipt',
  CustomerReceipt = 'CustomerReceipt',
}

export enum RequestName {
  PaymentRequest = 'PaymentRequest',
  PrintRequest = 'PrintRequest',
  AdminRequest = 'AdminRequest',
  TransactionStatusRequest = 'TransactionStatusRequest',
  DiagnosisRequest = 'DiagnosisRequest',
}

export enum ErrorCondition {
  MessageFormat = 'MessageFormat', // check btn
  UnavailableService ='UnavailableService', // check btn
  InProgress ='InProgress', // retry btn
  NotFound ='NotFound',  // retry btn
  Refusal ='Refusal',  // retry btn
  Aborted ='Aborted', // nothing
  Busy ='Busy',  // nothing
  Cancel ='Cancel',  // nothing
  DeviceOut ='DeviceOut',  // nothing
  InvalidCard ='InvalidCard',  // retry btn
  NotAllowed ='NotAllowed',  // nothing
  UnreachableHost ='UnreachableHost', // check btn
  WrongPIN ='WrongPIN'  // retry btn
}

export enum ResponseResult {
  Failure = 'Failure',
  Success = 'Success',
}
