import { Injectable } from '@angular/core';
import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { Product } from '@pos-common/classes/product.class';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { PosSettingsService } from '../pos-settings.service';
import { Invoice } from '@pos-common/classes/invoice.class';
import { InvoicesService } from '../invoices.service';
import { BehaviorSubject } from 'rxjs';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { LogService } from '../logger/log.service';

@Injectable()
export class TippingService {
  private _isTipping = false;
  private product: Product = null;
  private invoicesTipInvoiceEntries = new Map<string, string>();
  private _tip = new BehaviorSubject<number>(0);
  private _productUuid: string;
  private readonly logger = this.logService.createLogger('TippingService');

  constructor(
    private posSettingsService: PosSettingsService,
    private dbDaoService: DbDaoService,
    private invoicesService: InvoicesService,
    private logService: LogService
  ) {
    this.ngOnInit();
  }

  private ngOnInit() {
    this.getTippingStatusEvent().subscribe((isTipping) => {
      this._isTipping = isTipping;
      this.setTippingProduct();
    });
    this.getDefaultTippingProductEvent().subscribe(() => {
      this.product = null;
      this.setTippingProduct();
    });
    this.init();
  }

  init() {
    this._isTipping = this.posSettingsService.getTippingStatus();
    this.setTippingProduct();
  }

  get isTipping() {
    return this._isTipping;
  }

  getTippingStatusEvent() {
    return this.posSettingsService.getTippingStatusEvent();
  }

  getDefaultTippingProductEvent() {
    return this.posSettingsService.getDefaultTippingProductEvent();
  }

  get tipValue() {
    return this._tip.getValue();
  }

  getTipEvent() {
    return this._tip;
  }

  setTipEvent(value: number) {
    this._tip.next(value);
  }

  getTippingProduct() {
    return this.product;
  }

  get productUuid() {
    return this._productUuid;
  }

  private setTippingProduct() {
    if (!this.isTipping && !this.product) {
      return;
    }
    const productSettings = this.posSettingsService.getDefaultTipProduct();
    if (productSettings) {
      this._productUuid = productSettings.uuid;
      this.dbDaoService
        .getDataByUUID(UPDATES_TYPES.Product.type, productSettings.uuid)
        .then((data) => {
          this.product = new Product(data.data);
        })
        .catch((err) => this.logger.error(err, 'setTippingProduct:getDataByUUID', undefined, { productUuid: productSettings.uuid }));
    }
  }

  hasTipInvoiceEntry(invoiceUuid: string) {
    return this.invoicesTipInvoiceEntries.get(invoiceUuid);
  }

  hasOnlyTippingInvoiceEntry(invoice: Invoice): boolean {
    return invoice.getActiveInvoiceEntries().some((invoiceEntry) => !invoiceEntry.isTipping);
  }

  createTipInvoiceEntry() {
    if (!this.isTipping || !this.product) {
      return null;
    }
    const [variant] = this.product.variants as any;
    const productCategoryUuid = this.product.productCategoriesUuid.split(',')[0];
    const productCategory = productCategoryUuid === 'root' ? null : productCategoryUuid;
    const invoiceEntry = this.invoicesService.makeInvoiceEntryToAddData(variant, this.product, '', productCategory) as InvoiceEntry;
    return invoiceEntry;
  }

  clearInvoiceEntryUuids() {
    this.invoicesTipInvoiceEntries.clear();
  }

  addTippingInvoiceEntryToInvoice(invoice: Invoice, guestNumber: number = 1, currentCompanyCurrencyRounding?: string): Invoice {
    if (!this.isTipping) {
      return invoice;
    }
    let invoiceWithTipping: Invoice = null;
    let tippingInvoiceEntry = invoice.getTippingInvoiceEntry();

    if (this.tipValue > 0) {
      if (!tippingInvoiceEntry) {
        const newInvoiceEntry = this.createTipInvoiceEntry();
        newInvoiceEntry.price = this.tipValue;
        invoiceWithTipping = this.invoicesService.addInvoiceEntryToInvoice(invoice, newInvoiceEntry, PRODUCT_TYPES.TIPS, 1);
        const tippingInvoiceEntry = invoiceWithTipping.getTippingInvoiceEntry();
        if (tippingInvoiceEntry) {
          tippingInvoiceEntry.isTipping = true;
          if (guestNumber) {
            tippingInvoiceEntry.guestNumber = guestNumber;
          }
          this.invoicesTipInvoiceEntries.set(invoice.uuid, tippingInvoiceEntry.uuid);
        }
      } else {
        tippingInvoiceEntry.price = this.tipValue;
        tippingInvoiceEntry.quantity = 1;
        tippingInvoiceEntry.isTipping = true;
        tippingInvoiceEntry.increaseLocalModificationDate();
        invoiceWithTipping = new Invoice(invoice);
      }
    } else {
      if (tippingInvoiceEntry) {
        const invoiceEntries = invoice.invoiceEntries.filter((i) => i.uuid !== tippingInvoiceEntry.uuid);
        invoiceWithTipping = new Invoice(invoice);
        invoiceWithTipping.invoiceEntries = invoiceEntries;
      } else {
        invoiceWithTipping = new Invoice(invoice);
      }
    }

    this.invoicesService.calculateInvoiceAmountAfterDiscount(invoiceWithTipping, currentCompanyCurrencyRounding);
    return invoiceWithTipping;
  }

  removeTippingInvoiceEntryFromInvoice(invoice: Invoice): Invoice {
    const tippingInvoiceEntry = invoice.getTippingInvoiceEntry();
    if (tippingInvoiceEntry) {
      invoice.invoiceEntries = invoice.invoiceEntries.filter((invoiceEntry) => invoiceEntry.uuid !== tippingInvoiceEntry.uuid);
    }
    this.invoicesService.calculateInvoiceAmountAfterDiscount(invoice);
    return invoice;
  }
}
