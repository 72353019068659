import { createAction, props } from '@ngrx/store';
import { Employee } from '@pos-common/classes/employee.class';

const actionGroupName: string = 'Employees';

export const LoadListEmployeesFromLocalDB = createAction(
  `${actionGroupName} Load List from LocalDB`
);

export const LoadedListEmployeesFromLocalDBSuccess = createAction(
  `${actionGroupName} Loaded List Success from LocalDB`,
  props<{ list: Employee[] }>()
);

export const LoadedListEmployeesFromLocalDBFailure = createAction(
  `${actionGroupName} Loaded List Failure from LocalDB`,
  props<{ error: Error }>()
);

export const UpdateListEmployees = createAction(
  `${actionGroupName} Update List Employees`,
  props<{ data: unknown }>(),
);

export const UpdatedListEmployeesSuccess = createAction(
  `${actionGroupName} Update List Employees Success`,
  props<{ list: Employee[] }>()
);

export const SelectActiveEmployee = createAction(
  `${actionGroupName} Select Active Employee`,
  props<{ employee: Employee }>()
);

export const ClearActiveEmployee = createAction(
  `${actionGroupName} Clear Active Employee`
);
