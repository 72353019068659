import { createReducer } from '@ngrx/store';

export const rootApplicationKeyState = 'root';

export interface RootApplicationState {
}

export const initialState: RootApplicationState = {
};

export const reducerRootApplication = createReducer(
  initialState
);
