import { Injectable } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';

import { Employee } from '@pos-common/classes/employee.class';
import { EmployeeState } from "./employees.reducer";
import {
  selectActiveEmployee,
  selectEmployeeByUuid,
  selectEmployees,
  selectLoadingStatusEmployees,
} from './employees.selectors';
import {
  ClearActiveEmployee,
  LoadListEmployeesFromLocalDB,
  SelectActiveEmployee,
  UpdateListEmployees,
} from './employees.actions';

import { EmployeeLogout } from '@pos-stores/router';
import { map } from 'rxjs/operators';

@Injectable()
export class EmployeesFacadeStore {
  constructor(private store: Store<EmployeeState>) {
  }

  public get employees$(): Observable<Employee[]> {
    return this.store.select(selectEmployees)
      .pipe(map((employees: Employee[]): Employee[] =>
        employees.sort((a: Employee, b: Employee): number => {
          const aFirstName = a.firstName || '';
          const bFirstName = b.firstName || '';
          return aFirstName.localeCompare(bFirstName)
        })));
  }

  public get isLoading$(): Observable<boolean> {
    return this.store.select(selectLoadingStatusEmployees);
  }

  public get activeEmployee$(): Observable<Employee> {
    return this.store.pipe(
      select(selectActiveEmployee),
      switchMap((employee: Employee): Observable<Employee> => {

        if (!employee && this.isNeedReSelectEmployee) {
          this.store.dispatch(EmployeeLogout());
        }

        return of(employee);
      })
      )
  }

  public get activeEmployeeSnapshot(): Employee | null {
    let activeEmployee: Employee;
    this.activeEmployee$.pipe(take(1)).subscribe((employee: Employee) => activeEmployee = employee);
    return activeEmployee;
  }

  public set selectActiveEmployee(employee: Employee) {
    this.store.dispatch(SelectActiveEmployee({ employee }));
  }

  public set activeEmployee(employee: Employee) {
    this.store.dispatch(SelectActiveEmployee({ employee }));
  }

  public getEmployeeByUuid$(uuid: string): Observable<Employee> {
    return this.store.select(selectEmployeeByUuid(uuid));
  }

  public getEmployeeByUuidSnapshot(uuid: string): Employee {
    let selectEmployee: Employee | null = null;
    this.getEmployeeByUuid$(uuid).pipe(take(1)).subscribe((employee: Employee): Employee => selectEmployee = employee);
    return selectEmployee;
  }

  public loadEmployees(): void {
    this.store.dispatch(LoadListEmployeesFromLocalDB());
  }

  public updateEmployees(data: Employee[]): void {
    this.store.dispatch(UpdateListEmployees({ data }));
  }

  public clearActiveEmployee(): void {
    this.store.dispatch(ClearActiveEmployee());
  }

  private get isNeedReSelectEmployee(): boolean {
    const currentUrl: string =  window.location.hash;

    if (!currentUrl) {
      return true;
    }

    return !['settings', 'employee'].some((url: string) => currentUrl.includes(url))
  }
}
