import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { invoicesFeatureKey, InvoicesState, selfOrderInvoicesAdapter, SelfOrderOpenInvoice } from './invoices.reducer';

export const invoicesState = createFeatureSelector(invoicesFeatureKey);

export const selectSelfOrderOpenInvoice = createSelector(
  invoicesState,
  (state: InvoicesState) => state.openSelfOrderInvoices
);

const { selectEntities } = selfOrderInvoicesAdapter.getSelectors();

const selectOpenInvoiceEntitiesByUuidStore = createSelector(
  selectSelfOrderOpenInvoice,
  selectEntities
);

export const selectOpenInvoiceByUuidStore= (uuid: string) => createSelector(
  selectOpenInvoiceEntitiesByUuidStore,
  (data: Dictionary<SelfOrderOpenInvoice>): string[] => uuid ? data?.[uuid]?.uuids || [] : []
);

export const selectCounterOpenInvoiceByUuidStore= (uuid: string) => createSelector(
  selectOpenInvoiceByUuidStore(uuid),
  (uuids: string[]): number => uuids?.length || 0
);

export const hasOpenOpenInvoiceByUuidStore= (uuid: string) => createSelector(
  selectCounterOpenInvoiceByUuidStore(uuid),
  (number): boolean => number > 0
);
