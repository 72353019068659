import { AdyenTerminalApi } from './services/api';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { TranslateService } from '@ngx-translate/core';
import { AdyenTerminalInfo } from './classes';
import { AlertInput } from '@ionic/angular';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { AdyenApiService } from '@pos-common/services/api/adyen-api.service';
import { AdyenApiErrors } from '@pos-common/services/system/adyen/costants';
import { LogService } from '../logger';
import { ErrorTranslationHelper } from '@pos-common/classes';
import { PaymentService } from '@pos-common/services/system/payment.service';
import { PAYMENT_METHOD_NAMES } from '@pos-common/constants';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';
import { PosInfoService } from '@pos-common/services/system/pos-info.service';
import { AdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';

@Injectable()
export class AdyenTerminalProvider {
  private readonly errorTranslationHelper = new ErrorTranslationHelper();
  private readonly logger = this.logService.createLogger('AdyenTerminalProvider');

  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly terminalApi: AdyenTerminalApi,
    private readonly localStorage: LocalStorage,
    private readonly adyenApiService: AdyenApiService,
    private readonly adyenService: AdyenService,
    private readonly logService: LogService,
    private paymentService: PaymentService,
    private posInfoService: PosInfoService
  ) {
    this.setupErrorMessages();
  }

  static get isPaymashPaySelectedPaymentMethod(): boolean {
    const { localStorage } = window;

    if (!localStorage) {
      return false;
    }

    let paymentMethod: PaymentMethod;

    try {
      paymentMethod = JSON.parse(localStorage.getItem(StorageKeys.selectedPaymentMethod));
    } catch (error) {
      return false;
    }


    if (!paymentMethod) {
      return false
    }

    const { name, method  } = paymentMethod;

    if (!name || !method ) {
      return false
    }

    return name.toLowerCase().includes(PAYMENT_METHOD_NAMES.PAYMASH_PAY.toLowerCase())
      || method.toLowerCase().includes(PAYMENT_METHOD_NAMES.PAYMASH_PAY.toLowerCase())
      || method === 'CUSTOM0';
  }

  private setupErrorMessages() {
    this.errorTranslationHelper.setupMessages([[AdyenApiErrors.unauthorized, 'adyen_api_unauthorized_error_message']]);
  }

  async connectToTerminal(): Promise<AdyenTerminalInfo> {
    this.logger.debug('Start connect to terminal');
    const hasAdyenCredentials = await this.receiveAdyenCredentialsFromServer();
    if (!hasAdyenCredentials) {
      this.logger.info('No adyen credentials on server');
      this.alertService.showAlert({ message: 'paymash_pay_support_feature' });
      return;
    }
    const terminal = await this.selectAdyenTerminal();
    this.logger.info('Terminal connected successfully', { terminal });
    return terminal;
  }

  async autoConnectToTerminal(): Promise<void> {
    if (!this.adyenService.isAdyenDevice || this.isActiveTerminal) {
      return;
    }

    this.logger.info(`Start auto-connect to Adyen Terminal`);

    const deviceName: string = this.posInfoService.getDeviceModel();

    if (!deviceName) {
      this.logger.error('Error auto-connect to Adyen Terminal:', 'Could`t get deviceName from device');
      return;
    }

    const hasAdyenCredentials: boolean = await this.receiveAdyenCredentialsFromServer();

    if (!hasAdyenCredentials) {
      this.logger.error('Error auto-connect to Adyen Terminal:', 'No Adyen credentials on server');
      return;
    }

    const terminalsFromAccount: AdyenTerminalInfo[] = await this.getTerminalList();

    if (!terminalsFromAccount || terminalsFromAccount?.length === 0) {
      this.logger.error('Error auto-connect to Adyen Terminal:', 'No getting terminals list from server');
      return;
    }

    const currentTerminal: AdyenTerminalInfo = terminalsFromAccount
      .find((terminal: AdyenTerminalInfo): boolean =>
        deviceName.includes(terminal?.serialNumber) &&
        deviceName.includes(terminal?.deviceModel));

    if (!currentTerminal) {
      this.logger.error(`Error auto-connect to Adyen Terminal:', 'No found SN ${deviceName} in list ${JSON.stringify(terminalsFromAccount)}`);
      return;
    }

    const activeTerminal = {
      connected: true,
      name: TerminalNames.PAYMASH_PAY,
      title: PAYMENT_METHOD_NAMES.PAYMASH_PAY,
      tipAllowed: true,
      ...currentTerminal,
    }

    this.logger.info(`Success auto-connect to Adyen Terminal: SN ${deviceName}`);

    this.localStorage.setObject(StorageKeys.activeTerminal, activeTerminal);
    this.paymentService.createNewPaymentMethod(activeTerminal.title).then();
  }

  private get isActiveTerminal(): boolean {
    return !!this.localStorage.get(StorageKeys.activeTerminal);
  }

  private getTerminalList(): Promise<AdyenTerminalInfo[]> {
    return this.terminalApi.getTerminals({ merchantId: this.getMerchantId() });
  }

  private async receiveAdyenCredentialsFromServer(): Promise<boolean> {
    return this.adyenApiService
      .getAdyenCredentials()
      .toPromise()
      .then(({ apiKey, merchantId }): boolean => {

        if (apiKey && merchantId) {
          this.localStorage.set(StorageKeys.paymashPayKey, apiKey);
          this.localStorage.set(StorageKeys.paymashPayMerchantId, merchantId);
          return true;
        } else {
          this.localStorage.remove(StorageKeys.paymashPayKey);
          this.localStorage.remove(StorageKeys.paymashPayMerchantId);
          return false
        }

      })
      .catch((error) => {
        this.localStorage.remove(StorageKeys.paymashPayKey);
        this.localStorage.remove(StorageKeys.paymashPayMerchantId);
        if (typeof error === 'object' && error.status === 404) {
          return false;
        }
        throw error;
      });
  }

  private selectAdyenTerminal() {
    return this.getTerminalList()
      .then((terminals) => this.showSelectTerminalAlert(terminals))
      .catch((error) => {
        const message = this.errorTranslationHelper.getTranslationKey(error?.message || error);
        this.alertService.showAlert({ message });
        this.localStorage.remove(StorageKeys.paymashPayKey);
        this.localStorage.remove(StorageKeys.paymashPayMerchantId);
      });
  }

  private async showSelectTerminalAlert(terminals: AdyenTerminalInfo[]) {
    if (terminals.length === 1) {
      return terminals[0];
    }
    const terminalAlert = await this.getTerminalAlert(terminals);
    await terminalAlert.present();
    return terminalAlert.onDidDismiss().then(({ data }) => {
      return data.terminal || null;
    });
  }

  private async getTerminalAlert(terminals: AdyenTerminalInfo[]) {
    const terminalAlert = await this.alertService.create({
      cssClass: 'paymash-pay-alert',
      header: this.translateService.instant('paymash_pay_name'),
      message: this.translateService.instant('settings_select_terminal'),
      inputs: [
        ...terminals.map((terminal) => {
          return {
            name: 'terminal',
            type: 'radio',
            label: terminal.serialNumber,
            value: terminal,
          } as AlertInput;
        }),
      ],
      buttons: [
        {
          text: this.translateService.instant('common_cancel'),
        },
        {
          text: this.translateService.instant('common_save'),
          handler: (terminal: AdyenTerminalInfo) => ({ terminal: terminal || null }),
        },
      ],
    });

    if (!terminals.length) {
      terminalAlert.message = 'Terminals not found';
      terminalAlert.inputs = [];
      terminalAlert.buttons = [terminalAlert.buttons[0], terminalAlert.buttons[1]];
    }

    return terminalAlert;
  }

  private getMerchantId(): string {
    const apiKey = this.localStorage.get(StorageKeys.paymashPayMerchantId);
    if (!apiKey) throw new Error('Please provide Adyen MerchantID in the settings');
    return apiKey;
  }
}
