import { RootApplicationEffects } from '@pos-stores/root';
import { EmployeesPageEffects } from '@pos-stores/employees';
import { RouterEffects } from '@pos-stores/router';
import { InvoicesEffects } from '@pos-stores/invoices';

export const effects = [
  RootApplicationEffects,
  EmployeesPageEffects,
  RouterEffects,
  InvoicesEffects
];
