<h3>{{ title | translate }}</h3>
<h5 *ngIf="message">{{ message | translate }}</h5>
<processing-icons [icon]="activeScreen"></processing-icons>
<processing-buttons
  [cancelButtonTitle]="cancelButtonTitle"
  [retryButtonTitle]="retryButtonTitle"
  [checkButtonTitle]="checkButtonTitle"
  [showCancelButton]="showCancelButton"
  [showRetryButton]="showRetryButton"
  [showCheckButton]="showCheckButton"
  (onCancel)="cancelButtonEvent()"
  (onRetry)="retryButtonEvent()"
  (onCheck)="checkButtonEvent()"
></processing-buttons>
<pm-signature-pad *ngIf="showSignaturePad" (onResult)="onSignaturePadResult($event)"> </pm-signature-pad>
