/* tslint:disable */
// IONIC:
import { from, Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { ElementRef, EventEmitter } from '@angular/core';
import { Employee } from './common/classes/employee.class';
import { Store } from './common/classes/store.class';
import { Customer } from './common/classes/customer.class';
import { UPDATES_TYPES } from './common/constants/updates-types.const';
import { Invoice } from './common/classes/invoice.class';
import { ReceiptPrinter } from './common/services/system/receipt-printers/classes/receipt-printer.class';
import { DayReportData } from './common/classes/dayreport.class';
import { Moment } from '../typings/globals/moment/moment';
import { License } from './common/classes/license.class';
import { PRINTERS_SETTINGS_ACTIONS } from './common/services/system/printer.service';
import { Company } from './common/classes/company.class';
import { ProductSettingsInterface } from './common/interfaces';
import { ProductVariant } from './common/classes/product-variant.class';
import { SecuredResponse } from './common/classes/secured-response.class';
import { InvoiceEntry } from './common/classes/invoice-entry.class';
import { PAYMENT_PROVIDERS } from './common/constants/payment-providers.const';
import { PaymentResult } from './common/classes/payment-result.class';
import { PaymentError } from './common/constants/payment-error.enum';
import { Product } from './common/classes/product.class';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
import { AlertOptions } from '@pos-common/interfaces/alert.inteface';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';
import { InvoiceDetailsTaxItem } from '@pos-modules/invoice-details/interfaces/invoice-details-tax-item';
import { IPrintersSettings } from '@pos-common/interfaces/printers-settings.interface';
import { PayCalculator } from '@pos-common/classes/pay-calculator/pay-calculator.class';
import { CancellationTypes } from '@pos-common/constants/cancellation-types.enum';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { InvoiceEntityOrGuestType } from '@pos-common/types/invoice-entity-or-guest.type';
import { PermissionTypes } from '@pos-common/constants/permission-types.enum';
import { AnimationOptions } from '@pos-common/interfaces';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';
import { SunmiDeviceModel, SunmiPrinterWidth } from '@pos-common/services/system/devices/sunmi/sunmi.service.model';
import { InvoicePayment } from '@pos-common/classes/invoice-payment.class';
import { Query } from '@paymash/capacitor-database-plugin';
import { map } from 'rxjs/operators';
import { DbResponse } from '@pos-common/services/db/db-dao.utils';
import { GetAllDataOptions, Record } from '@pos-common/services/db/db-dao.service';
import { CALENDAR_VIEWS, CompanyProperties, SelfOrderInvoiceStatusTypesEnum } from '@pos-common/constants';
import { FilterForm } from '@pos-common/classes/calendar/filter-form.class';
import { Appointment } from '@pos-common/classes/appointment/appointment.class';
import { Route } from '@angular/router';
import { ActionSheetOptions } from '@ionic/angular';
import { AdyenTenderOptionsEnum } from '@pos-common/services/system/adyen/enums';

// from
// https://github.com/stonelasley/ionic-mocks/
// should the package be incorporated?

declare var jasmine: any;

export class AlertMock {
  public static instance(): any {
    let instance = jasmine.createSpyObj('Alert', ['present', 'dismiss']);
    instance.present.and.returnValue(true);
    instance.dismiss.and.returnValue(Promise.resolve());

    return instance;
  }
}

export class AlertControllerMock {
  public static instance(alertMock?: AlertMock): any {
    let instance = jasmine.createSpyObj('AlertController', ['create']);
    instance.create.and.returnValue(alertMock || AlertMock.instance());

    return instance;
  }
}

export class ToastMock {
  public create(): any {
    let rtn: Object = {};
    rtn['present'] = () => true;
    return rtn;
  }
}

export class ConfigMock {
  public get(): any {
    return '';
  }

  public getBoolean(): boolean {
    return true;
  }

  public getNumber(): number {
    return 1;
  }

  public setTransition(): void {
    return;
  }
}

export class DeviceMock {
  get manufacturer() {
    return 'pax';
  }
  get model() {
    return '';
  }
}

export class FormMock {
  public register(): any {
    return true;
  }
}

export class NavMock {
  public pop(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public push(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public getActive(): any {
    return {
      instance: {
        model: 'something',
      },
    };
  }

  public setRoot(): any {
    return true;
  }

  public popToRoot(): any {
    return true;
  }
}

export class PlatformMock {
  public ready(): Promise<any> {
    return new Promise((resolve) => {
      resolve('READY');
    });
  }

  public registerBackButtonAction(fn: Function, priority?: number): Function {
    return () => true;
  }

  public hasFocus(ele: HTMLElement): boolean {
    return true;
  }

  public doc(): HTMLDocument {
    return document;
  }

  public is(): boolean {
    return true;
  }

  public getElementComputedStyle(container: any): any {
    return {
      paddingLeft: '10',
      paddingTop: '10',
      paddingRight: '10',
      paddingBottom: '10',
    };
  }

  public onResize(callback: any) {
    return callback;
  }

  public registerListener(ele: any, eventName: string, callback: any): Function {
    return () => true;
  }

  public win(): Window {
    return window;
  }

  public raf(callback: any): number {
    return 1;
  }

  public timeout(callback: any, timer: number): any {
    return setTimeout(callback, timer);
  }

  public cancelTimeout(id: any) {
    // do nothing
  }

  public getActiveElement(): any {
    return document['activeElement'];
  }
}

export class AndroidPermissionsMock {
  public readonly PERMISSION: any = {};
}

export class OpenNativeSettingsMock {}

export class SplashMock {
  public hide() {
    return Promise.resolve(true);
  }
}

export class StatusMock {
  public styleDefault() {
    return Promise.resolve(true);
  }
}

export class MenuMock {
  public close(): any {
    return new Promise((resolve: Function) => {
      resolve();
    });
  }
}

export class AppMock {
  public getActiveNav(): NavMock {
    return new NavMock();
  }
}

export class NavControllerMock {
  public static instance(alertMock?: AlertMock): any {
    let instance = jasmine.createSpyObj('NavController', ['create']);
    instance.create.and.returnValue();

    return instance;
  }

  navigateRoot(url: string, options?: any): Promise<boolean> {
    return Promise.resolve(true);
  }
}

export class RootApplicationStateFacadeMock {
  public initLocalDBSuccess(): void {
  }

  public updateData(updateData: unknown, updateDataType: string): void {
  }
}

export class EmployeesFacadeStoreMock {
  private employee: Employee = new Employee({
    uuid: 'afcc2008-ebd2-4084-8471-9bd51de8470a',
    firstName: 'Margarita',
    lastName: 'Kaplunenko',
    email: 'alina.pasieka@fabware.com',
    image: null,
    shortPassword: '',
    shortPasswordType: 'NONE',
    hasReportPermission: true,
    hasCancellationPermission: true,
    hasSettingsPermission: true,
    hasAdminPermission: true,
    deleted: false,
    employeeDisplayName: 'Margarita Kaplunenko',
  });

  public get employees$(): Observable<Employee[]> {
    return of([this.employee]);
  }

  public get isLoading$(): Observable<boolean> {
    return of(true);
  }

  public get activeEmployee$(): Observable<Employee> {
    return of(this.employee);
  }

  public get activeEmployeeSnapshot(): Employee | null {
    return this.employee;
  }

  public set selectActiveEmployee(employee: Employee) {
  }

  public set activeEmployee(employee: Employee) {
    this.employee = employee;
  }


  public getEmployeeByUuid$(uuid: string): Observable<Employee> {
    return of(this.employee);
  }

  public loadEmployees(): void {
  }

  public updateEmployees(data: Employee[]): void {
  }

  public clearActiveEmployee(): void {
  }
}

export class PrinterServiceMock {
  private receiptPrintingInProgress: boolean = false;
  private printersSettings: IPrintersSettings = {
    printReceiptAutomatically: false,
    openCashRegisterAutomatically: false,
    printKitchenReceiptAutomatically: false,
    showUnprintedKitchenItems: false,
  };

  public getPrintersList(): Observable<ReceiptPrinter[]> {
    return of([
      new ReceiptPrinter({
        deviceType: 0,
        printerName: 'TM-m30',
        deviceName: '64:EB:8C:FE:26:CB',
        ipAddress: '10.0.1.6',
        macAddress: '64:EB:8C:FE:26:CB',
        printerNameForModelPick: 'TM-m30_222',
      }),
    ]);
  }

  public getPrinterListOnce(): Observable<ReceiptPrinter[]> {
    return of([
      new ReceiptPrinter({
        deviceType: 0,
        printerName: 'TM-m30',
        deviceName: '64:EB:8C:FE:26:CB',
        ipAddress: '10.0.1.6',
        macAddress: '64:EB:8C:FE:26:CB',
        printerNameForModelPick: 'TM-m30_222',
      }),
    ]);
  }

  public getActivePrinter() {
    return new ReceiptPrinter({
      deviceType: 0,
      printerName: 'TM-m30',
      deviceName: '64:EB:8C:FE:26:CB',
      ipAddress: '10.0.1.6',
      macAddress: '64:EB:8C:FE:26:CB',
      printerNameForModelPick: 'TM-m30_222',
    });
  }

  public getActiveKichenPosPrinters() {
    return [
      new ReceiptPrinter({
        deviceType: 0,
        printerName: 'TM-m30',
        deviceName: '64:EB:8C:FE:26:CB',
        ipAddress: '10.0.1.6',
        macAddress: '64:EB:8C:FE:26:CB',
        printerNameForModelPick: 'TM-m30_222',
      }),
    ];
  }

  public printReceipt(printer: ReceiptPrinter, receipt) {
    return new Promise((resolve, reject) => {
      resolve('OK');
    });
  }

  public getPrintersStatus() {
    return {
      receiptPrintingInProgress: this.receiptPrintingInProgress,
    };
  }

  public makeDayReportReceiptDataForPrinter(data: DayReportData, printer: ReceiptPrinter, reportDate: Moment): Array<any> {
    return [
      { font: 0 },
      { text: 'Printing date                   15.11.2017 19:27' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Daily report                   14. November 2017' },
      { text: '\n' },
      { text: '\n' },
      { text: '15.11.2017                                 14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Categories                                   (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: '/AGE ID Make-up                            14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'VAT                                          (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: '8.00%                                       1.05' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Payment methods                              (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Advance payment                            14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { cut: 'Nothing' },
    ];
  }

  public getPrintersSettings(): IPrintersSettings {
    return this.printersSettings;
  }

  public updatePrinterSettings(action: string): void {
    switch (action) {
      case PRINTERS_SETTINGS_ACTIONS.ENABLE_AUTO_PRINT_RECEIPT:
        this.printersSettings.printReceiptAutomatically = true;
        break;
      case PRINTERS_SETTINGS_ACTIONS.DISABLE_AUTO_PRINT_RECEIPT:
        this.printersSettings.printReceiptAutomatically = false;
        break;
      case PRINTERS_SETTINGS_ACTIONS.ENABLE_CASH_REGISTER_AUTO_OPEN:
        this.printersSettings.openCashRegisterAutomatically = true;
        break;
      case PRINTERS_SETTINGS_ACTIONS.DISABLE_CASH_REGISTER_AUTO_OPEN:
        this.printersSettings.openCashRegisterAutomatically = false;
        break;
      case PRINTERS_SETTINGS_ACTIONS.ENABLE_AUTO_PRINT_KITCHEN_RECEIPT:
        this.printersSettings.printKitchenReceiptAutomatically = true;
        break;
      case PRINTERS_SETTINGS_ACTIONS.DISABLE_AUTO_PRINT_KITCHEN_RECEIPT:
        this.printersSettings.printKitchenReceiptAutomatically = false;
        break;
    }
  }

  public openCashRegister(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, 2000);
    });
  }

  public createReceiptFromInvoice(invoice: Invoice, printer: ReceiptPrinter, receiptType?: string, openCashRegister?: boolean) {
    return Promise.resolve();
  }

  public getShowUnprintedKitchenItems(): boolean {
    return false;
  }
}

export class AlertServiceMock {
  public create(opts?: AlertOptions): Promise<any> {
    return Promise.resolve({
      present: () => Promise.resolve(),
      onDidDismiss: (callback) => Promise.resolve(),
    });
  }
  public async showAlert(opts: { message?: string; header?: string; subHeader?: string; buttons?: string[] }) {
    return this.create(opts);
  }
}

export class ActionSheetServiceMock {
  public create(opts?: ActionSheetOptions): Promise<any> {
    return Promise.resolve();
  }

  public dismiss(): void {
  }
}

export class SelfOrdersServiceMock {
  updatePreparationStatusFromCheckoutPage(invoice: Invoice, status: SelfOrderInvoiceStatusTypesEnum): void {
  }
}

export class AdyenAcquirerDataServiceMock {
  public saleToAcquirerData(isTipping: boolean): string {
    return '';
  }
}

export class AdyenTippingServiceMock {
  public get add(): AdyenTenderOptionsEnum {
    return AdyenTenderOptionsEnum.Tipping;
  }
}

export class AdyenSoftPosServiceMock {
}

export class InvoicesServiceMock {
  public createInvoice(): Invoice {
    let invoiceUUID = 'bd2afe29-b1b0-411a-80cc-b0f8e248f6a5';
    let newInvoiceData = {
      uuid: invoiceUUID,
      publicUuid: invoiceUUID.replace(/-/g, ''),
      // TODO ADJUST INVOICE PREPARATION
      invoiceId: null,
      isDraft: true,
      employee: {
        uuid: 'bd4afe29-b1b0-411a-80cc-b0f8e248a6a3',
      },
      store: {
        uuid: 'bd1afe29-b1b0-411a-80cc-b0f8e248a6a3',
      },
      inventoryStore: {
        uuid: 'bd1afe29-b1b0-411a-80cc-b0f8e248a6a3',
      },
    };

    return new Invoice(newInvoiceData);
  }

  public saveInvoice(invoice: Invoice, withoutSync?: boolean) {
    return new Promise((resolve, reject) => {
      resolve(invoice);
    });
  }

  public getDisplayId(invoice: Invoice): string {
    if (invoice.sequentId && invoice.invoiceId) return invoice.sequentId + ' (' + invoice.invoiceId + ')';
    if (invoice.invoiceId) return invoice.invoiceId;
    return '-';
  }

  public splitInvoice(invoice: Invoice): any {
    return {
      firstInvoice: new Invoice({}),
      secondInvoice: new Invoice({}),
    };
  }

  public getInvoiceId(): string {
    return '11-1234';
  }

  public addPaymentToInvoice(
    invoice: Invoice,
    method: string,
    amount: any,
    amountGiven: any,
    merchantReceipt?: string,
    cardholderReceipt?: string
  ): any {
    return new Promise((resolve, reject) => {
      resolve(invoice);
    });
  }
  public mergeUpdateAndActiveInvoiceEntries(invoiceEntries: InvoiceEntry[], activeInvoiceEntries: InvoiceEntry[]) {
    return activeInvoiceEntries;
  }
  public getSaveRequired() {
    return true;
  }

  public makeInvoiceEntryToAddData(variant: ProductVariant, product: Product, productShortName: string, productCategoryUuid: string): any {
    //TODO adjust image search
    return {
      variant: variant,
      price: variant?.price,
      taxRate: variant?.vatRate?.['value'],
      productCategory: productCategoryUuid,
      name: product?.name,
      shortName: productShortName,
      image: null,
    };
  }

  public calculateInvoiceAmountAfterDiscount(invoice: Invoice, currentCompanyCurrencyRounding: string = '') {
    invoice.calculateInvoiceAmountAfterDiscount(currentCompanyCurrencyRounding);
  }

  public getProviderByMethod(method: PaymentMethod) {
    return null;
  }

  public calculateCancellationInvoiceAmountAfterDiscount(
    invoice: Invoice,
    activeInvoice: Invoice = null,
    currentCompanyCurrencyRounding: string = ''
  ) {
    this.calculateInvoiceAmountAfterDiscount(invoice, currentCompanyCurrencyRounding);
  }

  getTaxes(invoice: Invoice): InvoiceDetailsTaxItem[] {
    return [];
  }

  public getInvoiceEntriesForKitchen(invoiceEntries: InvoiceEntry[], guestNumber: number = 0): InvoiceEntry[] {
    return invoiceEntries;
  }

  public getEntryByUuidAndCategory(type: string, data: any, invoiceEntries: InvoiceEntry[]): number[] {
    return invoiceEntries.map((invoiceEntry, index) => index);
  }

  public addInvoiceEntryToInvoice(invoice: Invoice, newEntryData: any, entryType: string, quantity: number): Invoice {
    const invoiceEntry = new InvoiceEntry(newEntryData);
    invoiceEntry.productVariant = {
      uuid: newEntryData.variant ? newEntryData.variant?.uuid : newEntryData.productVariant['uuid'],
    };
    invoiceEntry.type = entryType;
    const newInvoice = new Invoice(invoice);
    newInvoice.invoiceEntries.push(invoiceEntry);
    return newInvoice;
  }

  public deleteInvoice(invoiceToDelete: Invoice): Promise<Invoice> {
    invoiceToDelete.deleted = true;
    return Promise.resolve(invoiceToDelete);
  }
}

export class ShiftsServiceMock {
  async showMessageRequredOpenShift(): Promise<void> {
    await Promise.resolve(true)
  }

  get isCashPaymentAllowed(): boolean {
    return true;
  }
}

export class SecurityServiceMock {
  private _timeout = 1500;
  public activeEmployeeUpdateEvent: EventEmitter<any> = new EventEmitter();
  public activeStoreUpdateEvent: EventEmitter<any> = new EventEmitter();
  public loggedUserData = null;
  private statusCode: number = 200;
  public returnData: any = {};
  public loggedCompanyData: BehaviorSubject<Company> = new BehaviorSubject(null);
  public activeEmployee = new Employee({
    uuid: 'afcc2008-ebd2-4084-8471-9bd51de8470a',
    firstName: 'Margarita',
    lastName: 'Kaplunenko',
    email: 'alina.pasieka@fabware.com',
    image: null,
    shortPassword: '',
    shortPasswordType: 'NONE',
    hasReportPermission: true,
    hasCancellationPermission: true,
    hasSettingsPermission: true,
    hasAdminPermission: true,
    deleted: false,
    employeeDisplayName: 'Margarita Kaplunenko',
  });

  constructor() {
    this.loggedCompanyData.next(
      new Company({
        address: { country: 'UA' },
        defaultPaymentMethod: { uuid: 'f0cb3a49-9f51-49c7-8af3-648e72be1480' },
        email: 'c@c.ch',
        hasActiveDiscounts: false,
        id: 3777,
        isRKSVEnabled: false,
        isRestaurantEnabled: false,
        locale: {
          currency: 'CHF',
          currencyRounding: 'ZERO_DOT_ZERO_ONE',
          dateFormat: 'DD_MM_YYYY_DOT',
          numberFormat: 'BLANK_COMMA',
          startWeekOn: 'MONDAY',
          timeFormat: 'H24',
          unitSystem: 'METRIC',
          weightUnit: 'KILOGRAM',
        },
        name: 'Samsung Electronics',
        tablesEnforced: false,
        isTakeAwayEnabled: true,
        defaultTakeAwayCompanyVATRate: { uuid: '9d8fd0f8-2ac3-4adc-b161-6d3aad14c9ff' },
      })
    );
  }

  public setLoggedCompanyData(company: Company) {
    this.loggedCompanyData.next(company);
  }

  public getLoggedCompanyData(): Company {
    return this.loggedCompanyData.getValue();
  }

  public setMigratedStatus(isMigrated: boolean): void {}

  public doSecureRequest(url: string, type: string, data?: Object, optionsData?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        switch (this.statusCode) {
          case 200:
            resolve({ status: this.statusCode, data: this.returnData });
            break;
          case 404:
            reject({ status: this.statusCode });
            break;
        }
      }, this._timeout);
    });
  }

  public doSecureRequestObservable(url: string, type: string, data?: Object, optionsData?: any): Observable<SecuredResponse> {
    return new Observable((observer) => {
      this.doSecureRequest(url, type, data, optionsData)
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => observer.error(error));
    });
  }

  public observableCompanyProperties(properties: CompanyProperties[]): Observable<Company> {
    return of(new Company({}));
  }

  public setActiveEmployee(employeeData: Employee) {
    this.activeEmployee = employeeData ? new Employee(employeeData) : null;
    this.activeEmployeeUpdateEvent.emit(employeeData);
  }

  public getActiveEmployee(): Employee {
    return this.activeEmployee ? new Employee(this.activeEmployee) : this.activeEmployee;
  }

  public checkLicenseExpiration(licenseInfo: License): boolean {
    if (!licenseInfo.isActive) {
      return false;
    }
    if (!licenseInfo.isPosActivated) {
      return false;
    }
    if (licenseInfo.isNumberOfConcurrentUsersExceeded) {
      return true;
    }
    return true;
  }

  public getActiveStore(): Store {
    return new Store({
      uuid: '7065a174-7300-46e5-b2f4-7096433458db',
      name: 'Drinks & Smoothies',
      email: '',
      homepage: '',
      isWebshopStore: false,
      deleted: false,
      openingHours: '',
      address: { country: 'UA' },
    });
  }

  public getLoggedUserData(): any {
    return {
      accessToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1MDI3MjY0MzgsInN1YiI6ImFmY2MyMDA4LWViZDItNDA4NC04NDcxLTliZDUxZGU4NDcwYSIsImRtbiI6ImFwcC5wYXltYXNoLmNvbSIsImF1ZCI6ImUwZDI2ZDRhLWFiOTQtNDVhNS1iYzA4LTVjYWMwZjQ5ZDc4MiIsImlzcyI6IlBheW1hc2giLCJkaWQiOiIzN2RlMzMyNC04Y2Q3LTQwZDEtYjNkYy1lZWVjNTUxNzRhZTIiLCJpYXQiOjE1MDI3MjYxMzh9.4UoRFcdVUMxUkpJGGIDXjWejC5Mq-kFJSOwxANoIu2M',
      refreshToken: '264f9423-4085-4685-8ed1-0c1b273fbf90',
      company: {
        id: 5307,
        uuid: 'e0d26d4a-ab94-45a5-bc08-5cac0f49d782',
        hashedId: 'w1ek',
        isRKSVEnabled: false,
        name: 'Veganz GmbH',
        address: { country: 'UA', phoneNrMobile: '1111111111' },
        locale: {
          dateFormat: 'DD_MM_YYYY_SLASH',
          timeFormat: 'H24',
          startWeekOn: 'MONDAY',
          numberFormat: 'DOT_COMMA',
          currency: 'UAH',
          currencyRounding: 'ZERO_DOT_ZERO_FIVE',
          unitSystem: 'METRIC',
          weightUnit: 'POUND',
        },
        email: 'alina.pasieka@fabware.com',
        receiptText: '',
        isQRCodeOnReceiptEnabled: true,
        isRestaurantEnabled: true,
        tablesEnforced: false,
        isPickupDeliveryEnabled: true,
        hasActiveDiscounts: true,
        image: {
          uuid: 'd87fbde4-cd26-4b06-9fbf-2b42dee016d3',
          src: '/public/documents/d87fbde4-cd26-4b06-9fbf-2b42dee016d3?height=300',
          images: [
            {
              url: 'http://paymash-test.s3.amazonaws.com/companies/5307/2ab5fb84-d019-4110-9491-db53ea82eb74/600_600.JPEG',
              size: 'big',
            },
            {
              url: 'http://paymash-test.s3.amazonaws.com/companies/5307/2ab5fb84-d019-4110-9491-db53ea82eb74/160_160.JPEG',
              size: 'medium',
            },
            {
              url: 'http://paymash-test.s3.amazonaws.com/companies/5307/2ab5fb84-d019-4110-9491-db53ea82eb74/50_50.JPEG',
              size: 'small',
            },
            {
              url: 'http://paymash-test.s3.amazonaws.com/companies/5307/2ab5fb84-d019-4110-9491-db53ea82eb74/300_300.JPEG',
              size: 'normal',
            },
          ],
        },
        defaultPaymentMethod: { uuid: 'cacd9d33-b6ce-405c-94e8-125fbc968f7e' },
        defaultVATRate: { uuid: '28323b4d-1fe6-49c0-a850-547034229ca6' },
      },
      pos: { channelId: 91 },
    };
  }

  public getNextInvoiceNumber(): number {
    return 123;
  }

  public increaseNextInvoiceNumber() {
    return;
  }

  public decreaseNextInvoiceNumber() {}

  public logout() {}

  public showEmployeeDoesntAllowedPopup() {}

  public instanceChangedGastroOrEnforce(): Observable<any> {
    return of(this.loggedCompanyData);
  }

  public getStoreList() {
    return new BehaviorSubject<Store[]>([]);
  }

  public getCompanyLogo() {
    return null;
  }

  public getDeviceIdentifier() {
    return '';
  }
}

export class TranslateServiceMock {
  readonly onLangChange = new EventEmitter();
  readonly onTranslationChange = new EventEmitter();
  readonly onDefaultLangChange = new EventEmitter();

  public get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
    return of(key);
  }

  public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return key;
  }
}

export class GoogleAnalyticsServiceMock {
  public trackEvent(
    category: string,
    action: string,
    label?: string,
    value?: string,
    newSession?: boolean,
    successCallback?: Function,
    errorCallback?: Function
  ): void {}

  public trackView(screenName: string): void {}
}

export class NetworkServiceMock {
  public internetStatusChangeEvent: EventEmitter<boolean> = new EventEmitter();
  private connectionStatus = true;
  public getConnectionStatus(): boolean {
    return this.connectionStatus;
  }

  public setConnectionStatus(isOffline: boolean) {
    this.connectionStatus = isOffline;
  }

  public get isOfflineSubject() {
    return this.internetStatusChangeEvent;
  }
}

export class CustomerServiceMock {
  private newCustomerEvent: EventEmitter<any> = new EventEmitter();
  public customerInListSelected: Subject<Customer> = new BehaviorSubject<Customer>(null);

  public updateLastVisitDate(customer: Customer, lastVisit: any) {
    return;
  }

  public getNewCustomerEvent() {
    return this.newCustomerEvent;
  }
}

export class CustomerDisplayServiceMock {
  public customerDisplayConnectedStatus: EventEmitter<any> = new EventEmitter();
  public async processInvoiceChange(updatedInvoice: Invoice) {}
}

export class CollectionViewServiceMock {
  private categoryHideEvent: EventEmitter<any> = new EventEmitter();
  private cellsQuantityChangeEvent: EventEmitter<any> = new EventEmitter();
  private cellWidthChangeEvent = new EventEmitter<number>();
  private sidebarSlidesChangeEvent = new EventEmitter<number>();
  private productVariantModalDataChangeEvent = new EventEmitter<any>();
  private paySeparatelyModeChangeEvent = new EventEmitter<boolean>();
  private isImagesActive: boolean = true;
  private currentSidebarSlide: number = 0;
  private newProductItemEvent = new Subject<any>();

  public getCellsQuantity(): number {
    return 5;
  }

  public getCellWidth(): number {
    return 10;
  }

  public getCellWidthEmitter(): EventEmitter<any> {
    return this.cellWidthChangeEvent;
  }
  public getCellsQuantityEmitter(): EventEmitter<any> {
    return this.cellsQuantityChangeEvent;
  }

  public getCategoryHideEvent(): EventEmitter<any> {
    return this.categoryHideEvent;
  }

  public getRenderImagesStatus(): boolean {
    return this.isImagesActive;
  }

  public setCurrentSidebarSlide(currentSidebarSlide: number): void {
    this.currentSidebarSlide = currentSidebarSlide;
    this.sidebarSlidesChangeEvent.next(currentSidebarSlide);
  }

  public getCurrentSidebarSlide(): number {
    return this.currentSidebarSlide;
  }

  public setNewProductItemEvent(productItem: any) {
    this.newProductItemEvent.next(productItem);
  }

  public getNewProductItemEvent() {
    return this.newProductItemEvent;
  }

  public getCurrentSidebarSlideEmitter(): EventEmitter<any> {
    return this.sidebarSlidesChangeEvent;
  }
  public setProducVariantModalData(productData: any): void {
    this.productVariantModalDataChangeEvent.emit(productData);
  }

  public getActiveVatRatesSwitcher() {
    return this.paySeparatelyModeChangeEvent;
  }

  public getPaySeparatelyModeChangeEvent() {
    return this.paySeparatelyModeChangeEvent;
  }

  public setPaySeparatelyModeChangeEvent(paySeparatelyModeActive: boolean) {
    this.paySeparatelyModeChangeEvent.next(paySeparatelyModeActive);
  }
}

export class CartServiceMock {
  public activeInvoiceUpdated = new EventEmitter();
  private invoiceTableChangeEvent: EventEmitter<any> = new EventEmitter();
  private invoicePaymentsChangeEvent: EventEmitter<any> = new EventEmitter();
  public invoiceCustomerChanged: EventEmitter<any> = new EventEmitter();
  public invoiceEntryChangeEvent: EventEmitter<any> = new EventEmitter();
  public invoiceForSeparationChangeEvent: EventEmitter<any> = new EventEmitter();
  private activeInvoice: Invoice = new Invoice({
    uuid: '56197e27-142a-4209-8145-e87b0190dde7',
    publicUuid: '56197e27142a42098145e87b0190dde7',
    invoiceId: null,
    isDraft: true,
    employee: { uuid: '107583f8-7960-456a-b463-ee3fa78f9b9b' },
    store: { uuid: 'f52fbf76-62a9-40a5-8673-37871f3a51b6' },
    inventoryStore: { uuid: 'f52fbf76-62a9-40a5-8673-37871f3a51b6' },
  });
  public saveRequired: boolean = false;
  public paymentChanged: Subject<any> = new BehaviorSubject<any>(null);
  public paymentsMethodWatcher = () => this.paymentChanged.asObservable();
  public paymentsMethodSetter = (payment) => this.paymentChanged.next(payment);

  public getActiveInvoice(): Invoice {
    return this.activeInvoice;
  }

  public setActiveInvoice(invoice: Invoice) {
    this.activeInvoice = invoice;
    this.activeInvoiceUpdated.emit(this.activeInvoice);
  }

  public getInvoiceTableChangeEvent() {
    return this.invoiceTableChangeEvent;
  }

  public getInvoicePaymentsChangeEvent(): EventEmitter<any> {
    return this.invoicePaymentsChangeEvent;
  }

  public getInvoiceEntryChangeEvent(): EventEmitter<any> {
    return this.invoiceEntryChangeEvent;
  }

  public getInvoiceForSeparation() {
    return null;
  }

  public getInvoiceForSeparationChangeEvent(): EventEmitter<any> {
    return this.invoiceForSeparationChangeEvent;
  }

  public setCustomer(customerData: Customer, saveNotRequired?: boolean) {
    if (!this.activeInvoice) this.createInvoice();
    if (this.activeInvoice.isPaid && !customerData) return;
    this.activeInvoice = this.setCustomerDataToInvoice(this.activeInvoice, customerData);
    if (this.activeInvoice) this.calculateTotalCount(saveNotRequired);
    this.invoiceCustomerChanged.emit(customerData);
  }

  public calculateTotalCount(saveNotRequired?: boolean) {
    this.activeInvoice && this.activeInvoice.calculateInvoiceAmountAfterDiscount('ZERO_DOT_ZERO_ONE');

    if (!saveNotRequired) {
    } else {
      this.activeInvoiceUpdated.emit(this.activeInvoice);
    }
  }

  public setSeparetedInvoice(invoice: Invoice) {}
  public hasSeparetedInvoice() {}
  public createInvoice() {}

  public setDataAfterSuccessPay() {}

  public tableChangedEvent: Observable<any[]> = of([]);

  public processAutoPrinting(invoice: Invoice, method: string) {}

  public setCustomerDataToInvoice(invoice: Invoice, customerData: Customer): Invoice {
    if (customerData) {
      invoice.customer = {
        uuid: customerData.uuid,
        dataToShowInList: customerData.dataToShowInList || null,
      };
      invoice.billingAddress = customerData.address;
      if (customerData.discount) {
        invoice.customerDiscount = customerData.discount;
        invoice.customerDiscountPercentage = 0;
      } else if (customerData.discountPercentage) {
        invoice.customerDiscount = 0;
        invoice.customerDiscountPercentage = customerData.discountPercentage;
      } else {
        invoice.customerDiscount = 0;
        invoice.customerDiscountPercentage = 0;
      }
    } else if (invoice) {
      invoice.unlinkCustomerFromInvoice();
    }
    return invoice;
  }

  public setCustomerToSeparetedInvoice(invoice: Invoice, customerForSubtract: Customer): Promise<void> {
    return Promise.resolve();
  }

  public addEntry(data: any, type: string, quantity: number) {}

  public calculateGastronomyTableInvoices(invoice: Invoice | any): Promise<boolean> {
    return new Promise<boolean>((resolve) => resolve(true));
  }

  public getActiveTableName() {
    return null;
  }

  public mergeInvoiceEntries(invoiceEntries: InvoiceEntry[], activeInvoiceEntries: InvoiceEntry[]) {
    this.saveRequired = false;
    return activeInvoiceEntries;
  }

  public getSaveRequired() {
    return this.saveRequired;
  }

  public addPaymentToInvoice(method: string, amount: any, amountGiven: any): Promise<Invoice> {
    return new InvoicesServiceMock().addPaymentToInvoice(this.activeInvoice, method, amount, amountGiven).then((invoice: Invoice) => {
      invoice.sequentId = 12;
      return invoice;
    });
  }

  public checkPartialPaymentInInvoice(showAlert: boolean = true): boolean {
    return this.activeInvoice.isInvoiceHasPayments() && !this.activeInvoice.isPaid;
  }

  public changeEntryQuantity(entry: InvoiceEntry, changeValue: number): void {}
  public addNewGuest() {}
  public changeEntries(entries: InvoiceEntry[]) {}

  public setPaymentsToActiveInvoice(payments: InvoicePayment[]) {}
}

export class ModalServiceMock {
  presentModal(component: any, data?: any, opts?: any): Promise<any> {
    return Promise.resolve({ onDidDismiss: () => {}, present: () => {} });
  }
  getTop() {
    return Promise.resolve(undefined);
  }
}

export class RouterMock {
  private mocksFromJson: MocksFromJson = new MocksFromJson();
  getCurrentNavigation() {
    const invoice = new Invoice(this.mocksFromJson.servicesJson.invoices.invoices.invoiceWithGiftCardEntry);
    return { extras: { state: { invoice } } };
  }
}

export class TableEnforceServiceMock {
  checkForTableEnforcement() {
    return true;
  }

  checkForTableEnforcementAndShowAlert() {
    return false;
  }
}

export class ImageLoaderMock {
  preload(imageUrl: string) {
    return Promise.resolve();
  }
}

export class UpdatesServiceMock {
  public updatesTypeKeys: Array<string> = [];
  public updatesEmitters: Object = new Object();
  public updatesTypeInProgressStatus: Object = new Object();
  public newCategoryEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {
    // save keys of UPDATE_TYPE in Array for further usage in other places
    this.updatesTypeKeys = Object.keys(UPDATES_TYPES).filter((key) => !!UPDATES_TYPES[key].URL);

    // create Event Emitter for each UPDATE_TYPE
    // each component will be subscribed for needed Event Emitters
    // on update event emitter will emit new data to component
    for (let i = 0; i < this.updatesTypeKeys.length; i++) {
      this.updatesEmitters[this.updatesTypeKeys[i]] = new EventEmitter();
      this.updatesTypeInProgressStatus[this.updatesTypeKeys[i]] = false;
    }
  }

  public handleUpdateData(dataArray, updateData, type, source, parentCategory?) {
    return [...updateData];
  }

  public getSyncGettingDataStatusEvent(): Observable<boolean> {
    return of(false);
  }

  public getUpdateEmmiterByType(type: string) {
    return this.updatesEmitters[type];
  }
}

export class DbDaoServiceMock {
  public dbData: any = {
    [UPDATES_TYPES.Product.type]: [],
    [UPDATES_TYPES.ProductCategory.type]: [],
  };

  private getAllDataFromCollection(collectionName: string): Promise<any[]> {
    return new Promise((resolve) => {
      switch (collectionName) {
        case UPDATES_TYPES.PaymentMethod.type:
          const data = [
            {
              uuid: '3ef436ac-53d7-4b0d-9545-aa29775e27ac',
              name: null,
              method: 'CUSTOM8',
              visiblePOS: true,
              position: 16,
              deleted: false,
              meta: { revision: 0, created: 1502720738943, version: 0 },
              $loki: 17,
            },
            {
              uuid: 'cacd9d33-b6ce-405c-94e8-125fbc968f7e',
              name: 'CASH',
              method: 'CASH',
              visiblePOS: true,
              position: 0,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 1,
            },
            {
              uuid: 'c1d7522d-5394-4898-a085-c4cf57808a44',
              name: 'MAESTRO',
              method: 'MAESTRO',
              visiblePOS: true,
              position: 1,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 2,
            },
            {
              uuid: 'b6f4ea0a-6789-4f9a-9ffc-a48557176a75',
              name: 'POSTFINANCE',
              method: 'POSTFINANCE',
              visiblePOS: true,
              position: 2,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 3,
            },
            {
              uuid: 'a98fdcaf-9b65-4b4e-8985-9fd820c88c6b',
              name: 'VISA',
              method: 'VISA',
              visiblePOS: true,
              position: 3,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 4,
            },
            {
              uuid: '1dd94099-8f05-4014-9367-e1d3a63d404a',
              name: 'MASTERCARD',
              method: 'MASTERCARD',
              visiblePOS: true,
              position: 4,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 5,
            },
            {
              uuid: '14a3aaea-3a2e-4136-a18a-c832ebc01f88',
              name: 'AMEX',
              method: 'AMEX',
              visiblePOS: true,
              position: 5,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 6,
            },
            {
              uuid: '20fd28ce-f538-41a9-ad9b-44e6572dcbe2',
              name: 'GIFTCARD',
              method: 'GIFTCARD',
              visiblePOS: true,
              position: 6,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 7,
            },
            {
              uuid: '21ee2006-d11c-4cf0-8379-3fb188ca2ab2',
              name: 'EC',
              method: 'EC',
              visiblePOS: false,
              position: 7,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 8,
            },
            {
              uuid: 'c9491825-f6da-4b34-81c1-3bf1886c4903',
              name: 'PREPAY',
              method: 'PREPAY',
              visiblePOS: true,
              position: 8,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 9,
            },
            {
              uuid: 'fdee86dc-5bb4-4b78-b129-c788ce87084d',
              name: 'ON_PICK_UP',
              method: 'ON_PICK_UP',
              visiblePOS: false,
              position: 9,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 10,
            },
            {
              uuid: '19ee5959-0348-4092-a92f-0f87b70f18b0',
              name: 'GENERIC_CC',
              method: 'GENERIC_CC',
              visiblePOS: true,
              position: 10,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 11,
            },
            {
              uuid: '5e925cc2-0cfd-4de4-ae29-d36c2033c155',
              name: 'BANK_TRANSFER',
              method: 'BANK_TRANSFER',
              visiblePOS: true,
              position: 11,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 12,
            },
            {
              uuid: '394f4fa4-79cd-48cd-bef8-36907bbee1b3',
              name: 'PAYPAL',
              method: 'PAYPAL',
              visiblePOS: true,
              position: 12,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 13,
            },
            {
              uuid: '2b45481c-5859-4901-8eaf-52a24b19ab57',
              name: 'SumUp',
              method: 'SUMUP',
              visiblePOS: true,
              position: 13,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 14,
            },
            {
              uuid: '3ef436ac-53d7-4b0d-9545-aa29775e27ab',
              name: 'Österreich',
              method: 'CUSTOM0',
              visiblePOS: true,
              position: 14,
              deleted: false,
              meta: { revision: 0, created: 1502720738943, version: 0 },
              $loki: 15,
            },
            {
              uuid: '3ef436ac-53d7-4b0d-9545-aa29775e27ac',
              name: 'TWINT',
              method: 'CUSTOM1',
              visiblePOS: true,
              position: 15,
              deleted: false,
              meta: { revision: 0, created: 1502720738943, version: 0 },
              $loki: 16,
            },
            {
              uuid: '20fd28ce-f538-41a9-ad9b-44e6572dcbe3',
              name: 'GIFTCARD_NEW',
              method: 'GIFTCARD_NEW',
              visiblePOS: true,
              position: 16,
              deleted: false,
              meta: { revision: 0, created: 1502707937416, version: 0 },
              $loki: 17,
            },
            {
              uuid: '3ef436ac-53d7-4b0d-9545-aa29775e27ad',
              name: 'myPOS',
              method: 'CUSTOM2',
              visiblePOS: true,
              position: 17,
              deleted: false,
              meta: { revision: 0, created: 1502720738946, version: 0 },
              $loki: 18,
            },
          ];
          // let paymentMethodsResponse = {
          //   status: 200,
          //   data,
          //   dbMessage: true,
          // };
          resolve(data);
          break;
      }
    });
  }

  public getAllData(collectionName: string, queryParams: any): Promise<DbResponse> {
    return this.getAllDataRaw(collectionName, queryParams)
      .then((data) => ({
        status: 200,
        collection: collectionName,
        data,
        message: 'Data successfully FOUND!',
        dbMessage: true,
      }))
      .catch(() => ({
        status: 404,
        collection: collectionName,
        data: null,
        message: 'Data NOT FOUND',
        dbMessage: true,
      }));
  }

  getAllDataRaw(collectionName: string, queryParams?: Query): Promise<Record[]> {
    if (!queryParams) return this.getAllDataFromCollection(collectionName);

    let foundedItems: any[] = [];
    if (this.dbData[collectionName]) {
      foundedItems.push(...this.dbData[collectionName]);
    }

    return new Promise((resolve, reject) => {
      if (this.dbData[collectionName]) {
        switch (collectionName) {
          case UPDATES_TYPES.Invoice.type:
          case UPDATES_TYPES.Customer.type:
          case UPDATES_TYPES.PaymentMethod.type:
            resolve(foundedItems);
            break;
        }
      } else {
        reject();
      }
    });
  }

  getAllDataChunked(collection: string, query?: any): Observable<any> {
    return from(this.getAllData(collection, query)).pipe(map((data) => data.data));
  }

  // public checkPermissionsOnAndroid(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     resolve(true);
  //   });
  // }

  public upsertDataToCollection(type: string, dataArray: Array<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.dbData[type]) this.dbData[type] = [];
      this.dbData[type].push(...dataArray);
      resolve({ status: 200, data: dataArray });
    });
  }

  public getDataByUUID(collectionName: string, uuid: string): Promise<DbResponse> {
    this.dbData[UPDATES_TYPES.ProductVariant.type] = [
      new ProductVariant({
        id: 2780663,
        defaultCost: 0,
        deleted: false,
        images: [],
        options: [],
        price: 10,
        product: { uuid: '1772ced4-4acc-4a3f-9a23-ab3ea795410a' },
        title: 'With VAT take away',
        trackInventory: true,
        isTakeAwayAllowed: true,
        uuid: '87c0aa73-78b7-4749-a14e-044d8f47e855',
        vatRate: {
          id: 28410,
          deleted: false,
          uuid: '9a6091d4-e9e5-4ca8-a714-cd365c3b927b',
          value: 0.077,
        },
        wasPrice: 0,
        weight: 0,
        unit: 'UNIT',
        quantity: 0,
        position: 0,
        productUuid: '1772ced4-4acc-4a3f-9a23-ab3ea795410a',
        meta: { revision: 0, created: 1530621941037, version: 0 },
        $loki: 3,
      }),
      new ProductVariant({
        id: 2774879,
        defaultCost: 0,
        deleted: false,
        images: [],
        options: [],
        price: 3,
        product: { uuid: '2ecbfa27-8029-4467-b2d4-9824829aa9f7' },
        title: 'mPrime',
        trackInventory: true,
        isTakeAwayAllowed: false,
        uuid: '8f9ff800-cc49-42bf-8564-3767f5f7b2ac',
        vatRate: {
          id: 28410,
          deleted: false,
          uuid: '9a6091d4-e9e5-4ca8-a714-cd365c3b927b',
          value: 0.077,
        },
        wasPrice: 0,
        weight: 0,
        unit: 'UNIT',
        quantity: 0,
        position: 0,
        productUuid: '2ecbfa27-8029-4467-b2d4-9824829aa9f7',
        meta: { revision: 0, created: 1530621941037, version: 0 },
        $loki: 2,
      }),
    ];
    return new Promise((resolve, reject) => {
      if (this.dbData[collectionName]) {
        switch (collectionName) {
          case UPDATES_TYPES.Invoice.type:
            resolve({
              status: 200,
              collection: collectionName,
              data: new Invoice({ uuid: uuid }),
              message: 'Data successfully FOUND!',
              dbMessage: true,
            });
            break;
          case UPDATES_TYPES.Customer.type:
            resolve({
              status: 200,
              collection: collectionName,
              data: new Customer({ uuid: uuid }),
              message: 'Data successfully FOUND!',
              dbMessage: true,
            });
            break;
          case UPDATES_TYPES.ProductVariant.type:
            const productVariant = this.dbData[collectionName].find((item) => item.uuid === uuid);
            resolve({
              status: 200,
              collection: collectionName,
              data: new ProductVariant(productVariant),
              message: 'Data successfully FOUND!',
              dbMessage: true,
            });
            break;
          case UPDATES_TYPES.Product.type:
            const product = this.dbData[collectionName].find((item) => item.uuid === uuid);
            resolve({
              status: 200,
              collection: collectionName,
              data: new Product(product),
              message: 'Data successfully FOUND!',
              dbMessage: true,
            });
            break;
        }
      } else {
        reject({
          status: 404,
          collection: collectionName,
          data: uuid,
          message: 'Data NOT FOUND',
          dbMessage: true,
        });
      }
    });
  }

  public updateItemFieldsInCollection(collectionName: string, fieldsToUpdate: { uuid: string }): Promise<DbResponse> {
    return new Promise((resolve, reject) => {
      const findResult = { record: this.dbData[collectionName].find((item) => item.uuid === fieldsToUpdate.uuid) };
      if (findResult.record) {
        const result = findResult.record;
        const fields: string[] = Object.keys(fieldsToUpdate);
        fields.forEach((field) => {
          result[field] = fieldsToUpdate[field];
        });
        resolve({
          status: 200,
          collection: collectionName,
          data: findResult.record,
          message: 'Fields successfully UPDATED!',
          dbMessage: true,
        });
      } else {
        reject({
          status: 404,
          collection: collectionName,
          data: null,
          message: 'Not found',
          dbMessage: true,
        });
      }
    });
  }

  public removeDataFromCollection(collectionName: string, dataToRemove: any, dontSaveDb?: boolean): Promise<DbResponse> {
    return new Promise((resolve, reject) => {
      resolve({
        status: 200,
        collection: collectionName,
        data: dataToRemove,
        message: 'Data successfully REMOVed!',
        dbMessage: true,
      });
    });
  }

  deleteDatabase(collection: string): Promise<void> {
    return Promise.resolve();
  }

  initDatabase(companyPrefix: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  count(collection: string, query?: Query): Promise<number> {
    if (!this.dbData[collection]) this.dbData[collection] = [];
    return Promise.resolve(this.dbData[collection].length);
  }

  remove(collection: string, query?: Query): Promise<DbResponse> {
    return Promise.resolve({
      status: 200,
      collection,
      data: null,
      message: 'Data already REMOVed!',
      dbMessage: true,
    });
  }
}

export class InvoicesApiServiceMock {
  private _statusCode: number = 200;
  private _data: any;
  private _timeout = 500;

  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
  }

  set statusCode(value: number) {
    this._statusCode = value;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  public upsertInvoice(invoiceData) {
    return this.getResult(invoiceData);
  }

  public deleteInvoiceDraft(invoiceData) {
    return this.getResult(invoiceData);
  }

  public updateInvoice(invoiceData) {
    return this.getResult(invoiceData);
  }

  private getResult = (invoiceData): Promise<any> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        switch (this.statusCode) {
          case 200:
            resolve(invoiceData);
            break;
          case 400:
            const statusCode: number = this.statusCode;
            const data: number = this.data;
            this.statusCode = 200;
            reject({
              status: statusCode,
              data: data,
            });
            break;
          case 404:
            reject({ status: this.statusCode });
            break;
          case 500:
            reject({ status: this.statusCode });
            break;
        }
      }, this._timeout);
    });
  };
}

export class CustomersApiServiceMock {
  private _statusCode: number = 200;
  private _data: any;
  private _timeout = 500;

  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
  }

  set statusCode(value: number) {
    this._statusCode = value;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  public upsertCustomer(invoiceData) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        switch (this.statusCode) {
          case 200:
            resolve(invoiceData);
            break;
          case 400:
            const statusCode: number = this.statusCode;
            const data: number = this.data;
            this.statusCode = 200;
            reject({
              status: statusCode,
              data: data,
            });
            break;
          case 404:
            reject({ status: this.statusCode });
            break;
          case 500:
            reject({ status: this.statusCode });
            break;
        }
      }, this._timeout);
    });
  }

  public deleteCustomer(invoiceData) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        switch (this.statusCode) {
          case 200:
            resolve(invoiceData);
            break;
          case 400:
            const statusCode: number = this.statusCode;
            const data: number = this.data;
            this.statusCode = 200;
            reject({
              status: statusCode,
              data: data,
            });
            break;
          case 404:
            reject({ status: this.statusCode });
            break;
          case 500:
            reject({ status: this.statusCode });
            break;
        }
      }, 500);
    });
  }
}

export class PaymentMethodsApiServiceMock {
  create(name: string) {
    return of();
  }
}

export class LoadingServiceMock {
  public loadingItem;
  public loadingInProgress: boolean = false;
  private actionInTimeout: any;
  private syncInProgressTimeout: any;
  private syncInProgressStopTimeout: any;
  private syncIsRunuing: boolean = false;
  public syncStatusChangeEvent: EventEmitter<any> = new EventEmitter();

  public showLoadingItem(duration?: number, content?: string, withBlur?: boolean) {
    this.loadingInProgress = true;
    this.actionInTimeout = setTimeout(() => {
      if (this.loadingInProgress && !this.loadingItem) {
        this.loadingItem = new Object();
      }
    }, 50);
  }

  public hideLoadingItem() {
    this.loadingInProgress = false;
    if (this.actionInTimeout) {
      clearTimeout(this.actionInTimeout);
    }
    if (this.loadingItem) {
      this.loadingItem = null;
    }
  }

  public syncStart() {
    if (this.syncInProgressStopTimeout) clearTimeout(this.syncInProgressStopTimeout);
    if (!this.syncIsRunuing) {
      this.syncInProgressTimeout = setTimeout(() => {
        this.setSyncIsRuningStatus(true);
      }, 300);
    }
  }

  public syncFinish() {
    if (this.syncInProgressTimeout) clearTimeout(this.syncInProgressTimeout);
    if (this.syncIsRunuing) {
      if (this.syncInProgressStopTimeout) clearTimeout(this.syncInProgressStopTimeout);
      this.syncInProgressStopTimeout = setTimeout(() => {
        this.setSyncIsRuningStatus(false);
      }, 300);
    }
  }

  public setSyncIsRuningStatus(syncIsRunuingStatus: boolean) {
    this.syncIsRunuing = syncIsRunuingStatus;
    this.syncStatusChangeEvent.next(syncIsRunuingStatus);
  }
}

export class SyncServiceMock {
  private itemsInSyncList: Subject<number> = new BehaviorSubject(0);
  public isDataPresentInSyncListStatus: boolean = false;
  private syncPushingDataStatusEvent: Subject<boolean> = new Subject<boolean>();
  private dataToSaveSubject: Subject<any> = new Subject();
  public synchronizationInProgressSubject: Subject<boolean> = new Subject<boolean>();
  public synchronizationCountSubject: Subject<number> = new BehaviorSubject<number>(0);

  public addDataToSyncList(data) {
    return Promise.resolve();
  }

  public removeDataFromSyncList(uuidToRemove: string) {}

  public getDataFromSyncList(uuid: string) {
    return null;
  }

  public checkSyncList(): void {}

  public getItemsInSyncListCount(): Observable<number> {
    return this.itemsInSyncList.asObservable();
  }

  public isDataPresentInSyncList(type: string, uuidToCheck: string): boolean {
    return this.isDataPresentInSyncListStatus;
  }

  public getSyncPushingDataStatusEvent(): Observable<boolean> {
    return this.syncPushingDataStatusEvent;
  }

  get dataToSave(): Observable<any> {
    return this.dataToSaveSubject.asObservable();
  }
}

export class LocalizationUtilsMock {
  getFormattedCurrency(value: number, showCurrency: any, currencyPosition?: any) {
    return value.toFixed(2);
  }

  getRoundedCurrency(value: any): number {
    return parseFloat(value.toFixed(2));
  }

  getRoundedFormattedCurrency(value: number, showCurrency: any, currencyPosition?: any) {
    return value.toFixed(2);
  }

  getFormattedDate(value: any, format: any) {
    return '';
  }
}

export class RequestsWbServiceMock {
  public static supported: boolean = true;
  private responseStatus: number = 200;
  private workerReturnError: boolean = false;

  public load(config: any, msgFn: any, errorFn?: any): number {
    if (!this.workerReturnError) {
      let _body: string = this.responseStatus === 200 ? '' : '{"message":"Protected Realm","errors":[]}';
      let httpResponseData = {
        status: this.responseStatus,
        statusText: '',
        url: config.url,
        _body: _body,
      };
      msgFn({ data: httpResponseData });
    } else {
      let httpResponseData = {
        status: 0,
        statusText: 'worker_error',
      };
      errorFn({ data: httpResponseData });
    }
    return Math.floor(Date.now() / 1000) + Math.floor(Math.random() * 1000000000000);
  }

  public terminate(id: number): void {}
}

export class HttpMock {}

export class ChangeDetectorRefMock {
  public detectChanges(): void {}
}

export class NavParamsMock {
  static returnParam = null;

  public get(key: string): any {
    if (key.includes('Event')) {
      return of(null);
    } else if (NavParamsMock.returnParam) {
      return NavParamsMock.returnParam;
    }
    return undefined;
  }

  static setParams(value) {
    NavParamsMock.returnParam = value;
  }
}

export class InvoicesFilterServiceMock {
  public updateInvoiceList(): void {}
}

export class InvoiceListServiceMock {}

export class NullifyModalServiceMock {}

export class NotifyPopupsServiceMock {}

export class MockElementRef implements ElementRef {
  nativeElement = {};
}

export class ViewControllerMock {
  public readReady: any = {
    emit(): void {},
    subscribe(): any {},
  };

  public writeReady: any = {
    emit(): void {},
    subscribe(): any {},
  };

  public contentRef(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public didEnter(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public didLeave(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public onDidDismiss(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public onWillDismiss(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public willEnter(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public willLeave(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public willUnload(): any {
    return new Promise(function (resolve: Function): void {
      resolve();
    });
  }

  public dismiss(): any {
    return Promise.resolve();
  }

  public enableBack(): any {
    return true;
  }

  public getContent(): any {
    return true;
  }

  public hasNavbar(): any {
    return true;
  }

  public index(): any {
    return true;
  }

  public isFirst(): any {
    return true;
  }

  public isLast(): any {
    return true;
  }

  public pageRef(): any {
    return true;
  }

  public setBackButtonText(): any {
    return true;
  }

  public showBackButton(): any {
    return true;
  }

  public _setHeader(): any {
    return true;
  }

  public _setIONContent(): any {
    return true;
  }

  public _setIONContentRef(): any {
    return true;
  }

  public _setNavbar(): any {
    return true;
  }

  public _setContent(): any {
    return true;
  }

  public _setContentRef(): any {
    return true;
  }

  public _setFooter(): any {
    return true;
  }
}

export class PaymentServiceMock {
  activeTerminals() {}
  public makeTransaction(
    paymentProvider: PAYMENT_PROVIDERS,
    amount: number,
    currency: string,
    transactionType?: string
  ): Promise<PaymentResult> {
    return new Promise((resolve, reject) => {
      if (paymentProvider !== PAYMENT_PROVIDERS.OPI && transactionType) {
        reject(PaymentError.REFUND_NOT_SUPPORTED);
        return;
      }
      resolve(null);
    });
  }

  public showPaymentError(err: any) {}
}

export class TimApiServiceMock {}

export class IngenicoServiceMock {}

export class ReceiptBuilderServiceMock {
  public makeDayReportReceiptDataForPrinter(data: DayReportData, printer: ReceiptPrinter, reportDate: Moment): Array<any> {
    return [
      { font: 0 },
      { text: 'Printing date                   15.11.2017 19:27' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Daily report                   14. November 2017' },
      { text: '\n' },
      { text: '\n' },
      { text: '15.11.2017                                 14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Categories                                   (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: '/AGE ID Make-up                            14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'VAT                                          (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: '8.00%                                       1.05' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Payment methods                              (1)' },
      { text: '\n' },
      { text: '\n' },
      { text: 'Advance payment                            14.00' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { text: '\n' },
      { cut: 'Nothing' },
    ];
  }
}

export class ImageLoadingServiceMock {
  public findAndPrepareImages(item) {}

  public async savePreloadImages(): Promise<any> {}
}

export class CameraScannerServiceMock {
  private _barcodeScannerOpenedChangeEvent: Subject<boolean> = new BehaviorSubject(false);
  private _newBarcodeEvent: Subject<string> = new Subject();

  public get newBarcodeEvent(): Observable<string> {
    return this._newBarcodeEvent.asObservable();
  }

  public getBarcodeScannerOpenedStateEvent(): Observable<boolean> {
    return this._barcodeScannerOpenedChangeEvent.asObservable();
  }

  public closeBarcodeScanner() {}
}

export class PosSettingsServiceMock {
  private defaultPaymentMethod = 'CASH';
  private oneClickPaymentStatus = false;
  private tippingStatus = false;
  private defaultTipProduct: ProductSettingsInterface = {
    uuid: '0e6bf99a-aea2-43a4-8345-ead495f61373',
    name: 'Camouflage Cream (05)',
  };
  private tippingStatusEvent = new Subject<boolean>();
  private defaultTippingProductEvent = new Subject<ProductSettingsInterface>();

  public getOneClickPaymentStatus(): boolean {
    return this.oneClickPaymentStatus;
  }

  public getDefaultPaymentMethod(): string {
    return this.defaultPaymentMethod;
  }

  public setDefaultPaymentMethod(defaultPaymentMethod: string) {
    this.defaultPaymentMethod = defaultPaymentMethod;
  }

  public getInvoicesKeepDurationDateFormat(): string {
    return '2025-02-24T00:00:00.000Z';
  }

  setOneClickPaymentStatus(oneClickPaymentStatus: boolean) {
    this.oneClickPaymentStatus = oneClickPaymentStatus;
  }

  public getTippingStatus(): boolean {
    return this.tippingStatus;
  }

  public getDefaultTipProduct() {
    return this.defaultTipProduct;
  }

  public setDefaultTipProduct(defaultTipProduct: ProductSettingsInterface) {
    this.defaultTippingProductEvent.next(defaultTipProduct);
    this.defaultTipProduct = defaultTipProduct;
  }

  public getTippingStatusEvent() {
    return this.tippingStatusEvent;
  }

  public getMultipleGuestsStatus() {
    return false;
  }

  public getDefaultTippingProductEvent() {
    return this.defaultTippingProductEvent;
  }

  public getOnInvocePaymentStatus() {
    return true;
  }
}

export class SetTimeoutUtilMock {
  addVisualEffect(time: number = 0) {
    return Promise.resolve();
  }

  fixAngularZoneConflict(time: number = 0) {
    return Promise.resolve();
  }
}

export class PrintTransactionReceiptsMock {
  checkTransactionReceipt() {
    return;
  }
}

export class DbEntityProvicerMock {
  get listOfItems() {
    return [];
  }

  getByUuid(uuid: string): Observable<any> {
    return new Observable((observer) => {
      const items = [...this.listOfItems];
      const filtered = items.filter((i) => i['uuid'] === uuid);
      observer.next(filtered[0]);
      observer.complete();
    });
  }

  getList(): Observable<any[]> {
    return new Observable((observer) => {
      observer.next(this.listOfItems);
      observer.complete();
    });
  }

  getListByParams(queryParams: Query, options?: GetAllDataOptions): Observable<PaymentMethod[]> {
    return of(this.listOfItems);
  }
}

export class InvoicesProviderMock extends DbEntityProvicerMock {
  getInvoiceWithoutCancelledInvoices(uuid): Observable<Invoice> {
    return of(new Invoice({}));
  }
}

export class PaymentMethodsDbEntityProviderMock extends DbEntityProvicerMock {}

export class CustomersProviderMock extends DbEntityProvicerMock {}

export class InvoiceSendServiceMock {}

export class MyPosFacadeMock {}

export class ReportApiServiceMock {
  getReportData() {
    return of();
  }
}

export class StoreApiServiceMock {
  updateInventory(storeId: number, variantId: number, data: any) {
    return of('');
  }
}

export class FileReaderUtilsMock {
  static getFileReader(): FileReader {
    return new FileReader();
  }
}

export class NativeStorageMock {}

export class ReceiptPrintersServiceMock {
  get printersList(): Observable<ReceiptPrinter[]> {
    return of([
      new ReceiptPrinter({
        deviceType: 0,
        printerName: 'TM-m30',
        deviceName: '64:EB:8C:FE:26:CB',
        ipAddress: '10.0.1.6',
        macAddress: '64:EB:8C:FE:26:CB',
        printerNameForModelPick: 'TM-m30_222',
      }),
    ]);
  }

  addToSaved(printer: ReceiptPrinter) {}
}

export class MarketMock {}
export class AppVersionMock {}
export class PosApiServiceMock {}
export class AppointmentApiServiceMock {}
export class ToastServiceMock {}
export class CameraScannerAdapterServiceMock {}
export class UuidServiceMock {
  generate() {
    const uuid = Math.floor(10000 + Math.random() * 90000);
    return `${uuid}ee0-fffc-4656-8ace-d25562e9849e`;
  }
}

export class NetworkInterfaceMock {}
export class AppointmentModalServiceMock {}
export class CalendarServiceMock {
  getFilterForm(view: CALENDAR_VIEWS) {
    return of(new FilterForm());
  }
  getDefaultFilterForm(view: CALENDAR_VIEWS) {
    return new FilterForm();
  }
  getFilterFormValue(view: CALENDAR_VIEWS) {
    return of(new FilterForm());
  }
  setFilterForm(view: CALENDAR_VIEWS, filterForm: FilterForm) {}
  getUpdateStatus() {
    return of({ status: '' });
  }
}
export class PaymentProcessingServiceMock {}
export class VirtualPrintersProviderMock extends DbEntityProvicerMock {}
export class EmployeesProviderMock {
  getListByParams(queryParams: Query): Observable<Employee[]> {
    return of([]);
  }
}
export class StoresProviderMock {
  getListByParams(queryParams: Query): Observable<Employee[]> {
    return of([]);
  }
}
export class VatRateProviderMock {}
export class GastronomyHallsProviderMock {}
export class ProductVariantProviderMock extends DbEntityProvicerMock {}
export class ProductProviderMock extends DbEntityProvicerMock {}
export class ProductCategoryProviderMock extends DbEntityProvicerMock {}
export class GiftCardsProviderAbstractMock {}
export class CompanyLocalizedMock {
  transform(value: any, format: string) {
    return '';
  }
}
export class MyPosMiniFacadeMock {}
export class InvoicePaymentsMock {}

export class DbUtilsServiceMock {}
export class PreloadNextModulesMock {
  preload(route: Route, load: Function): Observable<any> {
    return of();
  }
  preloadNextPages(currentPage: string) {}
}

export class CheckoutAsideServiceMock {
  private payCalculator = new PayCalculator();

  setPayCalculator(payCalculator: PayCalculator) {}

  getPayCaclulator() {
    return of(this.payCalculator);
  }

  setIsOnInvoiceActive(value: boolean) {}

  getIsOnInvoiceActive() {
    return of(false);
  }
}

export class InvoiceCancellationServiceMock {
  isAllowCreateCancelModal(invoiceUUID: string): Observable<boolean> {
    return of(false);
  }
  getCancelComponent(сancellationType: CancellationTypes) {}
  getCancellationType(invoice: Invoice): Promise<CancellationTypes> {
    return Promise.resolve(CancellationTypes.all);
  }
  showCancelPopover(invoice: Invoice, event: any): Promise<any> {
    return Promise.resolve({});
  }
  dismissCancelPopover() {
    return Promise.resolve({});
  }
}

export class InvoiceProcessingServiceMock {
  processPaidInvoice(invoice: Invoice): Promise<Invoice> {
    const newInvoice = new Invoice(invoice);
    newInvoice.sequentId = 123;
    return new Promise<Invoice>((resolve) => resolve(newInvoice));
  }
}

export class KeyboardServiceMock {
  public isOpen = false;
}

export class MultipleGuestsServiceMock {
  public nextGuestNumber;
  get activeGuestNumber() {
    return 1;
  }
  get guestNumbers() {
    return 1;
  }
  getAddNewGuestEvent() {
    return of();
  }
  setAddNewGuestEvent() {}
  reduceGuestNumbers(guestNumber: number) {}
  setGuestNumbers(value: number[]) {}
  increaseNumbers() {}
  reset() {}
  setActiveInvoiceEntryGuest(invoiceEntryGuest: InvoiceEntryGuest) {}

  getInvoiceEntryGuests(invoiceEntries: InvoiceEntry[]): InvoiceEntryGuest[] {
    return invoiceEntries.filter((entry) => entry.isGuest).map((entry) => new InvoiceEntryGuest({ ...entry, invoiceEntryCount: 1 }));
  }

  getInvoiceEntryOrGuestList(invoiceEntryGuests: InvoiceEntryGuest[], invoiceEntries: InvoiceEntry[]): InvoiceEntityOrGuestType[] {
    if (!invoiceEntryGuests.length) {
      return invoiceEntries;
    }

    return invoiceEntryGuests.reduce((guests, guest) => {
      const invoiceEntry = invoiceEntries.filter((entry) => entry.guestNumber === guest.guestNumber && !entry.isGuest);
      if (invoiceEntry) {
        return [...guests, guest, ...invoiceEntry];
      }
      return [...guests, guest];
    }, []);
  }

  createNewGuest(): InvoiceEntryGuest {
    return new InvoiceEntryGuest({ guestNumber: this.nextGuestNumber, position: this.nextGuestNumber });
  }

  removeActiveGuest(guestNumber: number, invoiceEntries: InvoiceEntry[]): Promise<InvoiceEntry[]> {
    invoiceEntries.forEach((invoiceEntry) => {
      if (invoiceEntry.guestNumber === guestNumber) {
        invoiceEntry.deleted = true;
      }
    });
    return Promise.resolve(invoiceEntries);
  }

  calculateSplitInvoiceWithGuests(invoiceEntries: InvoiceEntry[]): InvoiceEntry[] {
    return invoiceEntries;
  }

  setDefaultGuestNumbers(invoiceEntryGuests: InvoiceEntryGuest[]) {}
  setGuestNumberForPay(value: number) {}
  deleteEmptyGuestsFromPaidInvoice(invoice: Invoice) {
    return invoice;
  }
}

export class MyPosServiceMock {
  get isMyPosDevice() {
    return false;
  }
}

export class MyposGlassServiceMock {
  get isActiveMyPosGlassTerminal(): boolean {
    return false;
  }
}

export class AdyenServiceMock {
  get isAdyenDevice() {
    return false;
  }
  get isAdyenTerminal() {
    return false;
  }
}

export class PermissionServiceMock {
  checkPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    return Promise.resolve(true);
  }
  requestPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    return Promise.resolve(true);
  }
  checkAndRequestPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    return Promise.resolve(true);
  }
}

export class PlatformServiceMock {
  get isAndroid() {
    return true;
  }
  get isMobile() {
    return true;
  }
  get isWeb() {
    return false;
  }
  get isNativePlatform() {
    return false;
  }
  public ready() {
    return Promise.resolve();
  }
}

export class ReceiptAutoPrintingServiceMock {
  processAutoPrinting(invoice: Invoice, method: string): void {}
}

export class RouteNavigationServiceMock {
  get data() {
    return {};
  }
  loadRouting() {
    throw new Error('Method not implemented.');
  }
  back(options?: AnimationOptions): Promise<any> {
    throw new Error('Method not implemented.');
  }
  isFirstRoute(): boolean {
    throw new Error('Method not implemented.');
  }
  setInitializationFinished(isFinish: boolean) {
    throw new Error('Method not implemented.');
  }
  isInitializationFinished(): boolean | Promise<boolean> {
    return Promise.resolve(true);
  }
}

export class TippingServiceMock {
  createTipInvoiceEntry(): InvoiceEntry {
    return new InvoiceEntry({});
  }
  getTipEvent(): Observable<number> {
    return of(0);
  }
  addTippingInvoiceEntryToInvoice(invoice: Invoice, guestNumber?: number): Invoice {
    return invoice;
  }
  setTipEvent(value: number) {}
  removeTippingInvoiceEntryFromInvoice(invoice: Invoice): Invoice {
    return invoice;
  }
}

export class UserNotificationServiceMock {
  showInvoiceContainsPaymentAlert() {}

  showEmployeeDoesntAllowedPopup() {}

  showMessage(message: string) {}

  showNoConnectionMessage() {}

  showInvoiceContainsGiftCardsAlert() {}

  showNoConnectionGiftCardsMessage() {}

  showGiftCardProcessingErrorMessage() {}
}

export class GiftCardServiceMock extends GiftCardProvider {
  openAssignGiftCardModal(giftCardCode: string): Promise<GiftCard> {
    return Promise.resolve(new GiftCard({ code: giftCardCode }));
  }
  getGiftCardByCode(code: string): Observable<GiftCard> {
    if (code) {
      return of(new GiftCard({ code }));
    }
    return of(null);
  }
  getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard> {
    return of(null);
  }
  isOffline(): boolean {
    return false;
  }
  helperGiftCardPayCalculate(card: GiftCard, invoiceAmount: number): { given: number; change: number } {
    return { given: 0, change: 0 }
  };
  makeCardObfuscation(value: string, showStars?: boolean, defaultSing?: string, addBrackets?: boolean): string {
    return value;
  }
}

export class SunmiServiceMock {
  get hasPrinter(): boolean {
    return true;
  }

  get hasAppScanner(): boolean {
    return true;
  }

  get hasBroadcastScanner(): boolean {
    return true;
  }

  get isSunmiDevice(): boolean {
    return true;
  }

  get printerWidth(): SunmiPrinterWidth {
    return SunmiPrinterWidth.w80mm;
  }

  get sunmiDeviceModel(): SunmiDeviceModel {
    return SunmiDeviceModel.T2s;
  }
}

export class SumUpServiceMock {}
export class SearchUtilsMock {
  replaceForSearch(value: string): string {
    return value;
  }
}
export class LogServiceMock {
  debug(component: string, message: string) {}
  info(component: string, message: string, options?: any) {}
  warn(component: string, message: string) {}
  error(component: string, message: string, nestedError?: any, options?: any) {}
}

export class AdyenApiHelperMock {}
export class AdyenPaymentServiceMock {}
export class AdyenUtilsMock {}
export class AppointmentServiceMock {
  hasAppointmentChanges(defaultAppoinment: Appointment, activeAppointment: Appointment) {
    return false;
  }
}

interface IServicesJsons {
  invoices: {
    partial: {
      originInvoice: any;
      originInvoiceAfterPartialCancel: any;
      partyallyCancelInvoice: any;
    };
    invoices: {
      origin: any;
      originDraft: any;
      invoiceWithGiftCardEntry: any;
      invoiceWithGiftCardPaymentMethod: any;
      invoiceWithOnInvoicePaymentMethod: any;
      invoiceWithNegativeInvoceEntry: any;
      invoiceWithPercentageDiscount: any;
      invoiceWithAbsoluteDiscount: any;
      invoiceWithCustomerAbsoluteDiscount: any;
      invoiceWithIndividualProduct: any;
      invoiceWithPartialPayment: any;
      invoiceWithExcludingVat: any;
      invoiceWithGuestNumbers: any;
      invoicesWithTippingInvoiceEntry: any;
      invoiceWithNegativeTax: any;
      invoiceWithGuests: any;
      invoiceForSplit: any;
      invoiceForSplitWithDeletedEntity: any;
      invoicesWithuoutItemTip: any;
    };
    broken: any[];
    webshop: {
      invoiceUnpaid: any;
      invoicePartiallyPaid: any;
      invoicePaid: any;
      invoiceWithDiscountCode: any;
      invoiceWithDiscountCodeAbsolute: Invoice;
    };
  };
  employee: Employee;
  giftCard: GiftCard;
  updates: {
    invoicesUpdatesResponse: any;
    invoicesPaidUpdatesResponse: any;
  };
}

interface DataJson {
  paymentMethods: any[];
  products: any;
  productCategories: any[];
  customers: {
    customer: any;
    customerList: any[];
  };
  companies: {
    original: any;
  };
  payments: {
    sumup: any;
    myPos: any;
    myPosMiniAndroid: any;
    myPosMiniIos: any;
    adyen: {
      failure_1: any;
      failure_2: any;
      failure_3: any;
      success_1: any;
    };
  };
  printers: {
    'TM-P20': any;
    'TM-M30': any;
    StarMicronics: any;
  };
  dayReport: any;
}

interface SyncJson {
  status: {
    original: any;
    statusMigrated: any;
    statusCompanyModified: any;
    statusWithExpiredLicense: any;
  };
  updates: {
    updateObject: any[];
    localUpdateLinks: any;
    updateOfInvoceResponseDraft: any;
    updateOfInvoceResponseDraftWithMore: any;
    updateOfInvoceResponsePaid: any;
  };
}

export class MocksFromJson {
  public servicesJson: IServicesJsons;
  public dataJson: DataJson;
  public syncJson: SyncJson;
  constructor() {
    this.servicesJson = {
      invoices: {
        partial: require('./mocks/services/invoices/partial-invoices.json'),
        invoices: require('./mocks/services/invoices/invoices.json'),
        broken: require('./mocks/services/invoices/broken-invoices.json'),
        webshop: require('./mocks/services/invoices/webshop-invoices.json'),
      },
      employee: require('./mocks/services/employees/employee.json'),
      giftCard: require('./mocks/services/giftcards/giftcard.json'),
      updates: require('./mocks/services/updates/updates.json'),
    };
    this.dataJson = {
      paymentMethods: require('./mocks/data/paymentMethods.json'),
      products: require('./mocks/data/products.json'),
      productCategories: require('./mocks/data/productCategories.json'),
      customers: require('./mocks/data/customers.json'),
      companies: require('./mocks/data/companies.json'),
      payments: require('./mocks/data/payments.json'),
      printers: require('./mocks/data/printers.json'),
      dayReport: require('./mocks/data/day-report.json'),
    };
    this.syncJson = {
      status: require('./mocks/sync/status.json'),
      updates: require('./mocks/sync/updates.json'),
    };
  }

  getPaymentMethodByMethod(method: string) {
    return this.dataJson.paymentMethods.find((paymentMethod) => paymentMethod.method === method);
  }
}

export const deepCopy = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepCopy(obj[key]);
      return newObj;
    }, {});
  }
};

export const deepCopyJSON = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};
/* tslint:enable */
