import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmployeeLogout } from '@pos-stores/router';
import { map } from 'rxjs/operators';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { NavController } from '@ionic/angular';
import { ClearActiveEmployee } from '@pos-stores/employees';
import { ILogger } from '@spryrocks/logger';
import { LogService } from '@pos-common/services/system/logger';



@Injectable()
export class RouterEffects {
  private readonly logger: ILogger = this.logService.createLogger('RouterStateEffects');

  constructor(
    private actions$: Actions,
    private navController: NavController,
    private logService: LogService
    ) {}

  employeeLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EmployeeLogout),
      map(() => {

        this.navController
          .navigateRoot(ROUTE_URLS.employee, { animated: true, animationDirection: 'back' })
          .catch((err) => this.logger.error(err, 'RouterEffects-employeeLogout$: logout:navController:navigateRoot:employee'));

        return ClearActiveEmployee()
      })
    )
  })
}
