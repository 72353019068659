import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InitLocalDBSuccess, UpdateDataEvent, UpdateEmptyAction } from '@pos-stores/root';
import { map } from 'rxjs/operators';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { LoadListEmployeesFromLocalDB, UpdateListEmployees } from '@pos-stores/employees';
import { ClearOpenSelfOrderInvoices, LoadSelfOrderInvoicesFromLocalDB, UpdateInvoices } from '@pos-stores/invoices';

@Injectable()
export class RootApplicationEffects {

  constructor(
    private actions$: Actions
  ) {}

  initLocalDBSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(InitLocalDBSuccess),
    map(() => (
      LoadListEmployeesFromLocalDB()
    ))
  ))

  updateDataStream$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateDataEvent),
    map(({ updateData, updateDataType }) => {
      switch (updateDataType) {

        case UPDATES_TYPES.Employee.type: {
          return UpdateListEmployees({ data: updateData })
        }

        case UPDATES_TYPES.Invoice.type: {
          return UpdateInvoices({ invoice: updateData })
        }

        case UPDATES_TYPES.Company.type: {

          // TODO MUST BE MOVE TO COMPANY EFFECTS WHEN A COMPANY STORE IS CREATED
          if (updateData?.['properties'] && updateData?.['properties']?.isSelfOrderingEnabled) {
           return LoadSelfOrderInvoicesFromLocalDB();
          } else {
            return ClearOpenSelfOrderInvoices();
          }
        }

        default: return UpdateEmptyAction();
      }
    })
  ))

}
