<a tappable (click)="logout()">
  <ng-container *ngIf="ActiveEmployee | async as employee" >
    <div class="info" [class.active-store]="showStore && ActiveStore">
      <p class="employee-name">
        <span *ngIf="employee">{{ employee.firstName }} {{ employee.lastName }}</span>
      </p>
      <p *ngIf="showStore" class="store-name">
        <span *ngIf="ActiveStore">{{ ActiveStore.name }}</span>
      </p>
    </div>
    <pos-image-loader-cap *ngIf="employee?.image" [url]="employee.image | ImageUrl : IMAGE_SIZES.SMALL"> </pos-image-loader-cap>
    <person-placeholder [class.show]="!employee?.image" width="30" height="30" fillColor="#007aff"></person-placeholder>
  </ng-container>
</a>
