import { createAction, props } from '@ngrx/store';

const actionGroupName: string = 'Root';

export const InitLocalDBSuccess = createAction(
  `${actionGroupName} Init Local DB Success`
);

export const UpdateDataEvent = createAction(
  `${actionGroupName} Update Data event`,
  props<{ updateData: unknown, updateDataType: string }>()
);

export const UpdateEmptyAction = createAction(
  `${actionGroupName} Update Empty Action`
);
