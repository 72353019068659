/**
 * Created by maksymkunytsia on 10/23/16.
 */

import { ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { MenuController } from '@ionic/angular';

// Pages
import { Store } from '../../classes/store.class';
import { Employee } from '../../classes/employee.class';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { SecurityService } from '../../services/system/security.service';
import { UpdatesService } from '../../services/system/updates.service';
import { IMAGE_SIZES } from '../../classes/image.class';
import { LogService } from '../../services/system/logger/log.service';
import { NavController } from '@ionic/angular';
import { ROUTE_URLS } from '../../constants/route-urls.const';
import { SubSink } from 'subsink';
import { UpdateTypeInterface } from '@pos-common/interfaces/update-type.interface';
import { ChangeDetectorRef } from '@angular/core';
import { RouteNavigationService } from '@pos-common/services/system/route-navigation/route-navigation.service';
import { PersonPlaceholderModule } from '../person-placeholder/person-placeholder.component';
import { ImageLoaderCapComponentModule } from '@pos-modules/shared/components';
import { ImageUrlPipeModule } from '@pos-common/pipes';
import { CommonModule } from '@angular/common';
import { EmployeesFacadeStore } from '@pos-stores/employees';
import { Observable } from 'rxjs';

@Component({
  selector: 'current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUser {
  @Input() showStore = true;
  public IMAGE_SIZES: any = IMAGE_SIZES;
  public ActiveStore: Store = null;
  public requiredUpdatesTypes: UpdateTypeInterface[] = [UPDATES_TYPES.Store, UPDATES_TYPES.Employee];
  private subs = new SubSink();
  private shouldShowStore = false;

  public ActiveEmployee: Observable<Employee> = this.employeesStateFacade.activeEmployee$;

  private readonly logger = this.logService.createLogger('CurrentUser');

  constructor(
    private securityService: SecurityService,
    private navController: NavController,
    private updatesService: UpdatesService,
    private menuController: MenuController,
    private cdr: ChangeDetectorRef,
    private routeNavigationService: RouteNavigationService,
    private logService: LogService,
    private employeesStateFacade: EmployeesFacadeStore
  ) {}

  ngOnInit() {
    this.shouldShowStore = this.showStore;
    for (let i = 0; i < this.requiredUpdatesTypes.length; i++) {
      const currentUpdateType = this.requiredUpdatesTypes[i];
      this.subs.sink = this.updatesService.updatesEmitters[currentUpdateType['type']].subscribe((data) => {
        if (data) this.handleUpdateData(data, currentUpdateType['type']);
      });
    }

    this.subs.sink = this.securityService.activeStoreUpdateEvent.subscribe((store) => {
      this.setActiveStore(store);
      this.cdr.detectChanges();
    });

    this.subs.sink = this.securityService.getStoreList().subscribe((stores) => {
      this.setShowStore(stores);
      this.cdr.detectChanges();
    });

    this.routeNavigationService.isInitializationFinished().then(() => {
      this.setActiveStore(this.securityService.getActiveStore());
      const stores = this.securityService.getStoreList().getValue();
      this.setShowStore(stores);
      this.cdr.detectChanges();
    });
  }

  private setActiveStore(store: Store) {
    this.ActiveStore = store ? new Store(store) : null;
  }

  private setShowStore(stores: Store[]) {
    if (!this.shouldShowStore) {
      return;
    }

    this.showStore = false;
    if (stores && stores.length > 1) {
      this.showStore = true;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public handleUpdateData(updateData, type) {
    let uuidToFind;
    let dataToUpdate;
    let itemClass;

    switch (type) {
      case UPDATES_TYPES.Store.type:
        if (this.ActiveStore) uuidToFind = this.ActiveStore.uuid;
        dataToUpdate = 'ActiveStore';
        itemClass = Store;
        break;
    }
    // go through updateData array
    for (let j = 0; j < updateData.length; j++) {
      let currentItem = updateData[j].data;

      // check if active data item exist in updateData
      if (currentItem && currentItem.uuid === uuidToFind) {
        if (currentItem.deleted) {
          this.logout();
          this.securityService['set' + dataToUpdate](null);
        } else {
          this.securityService['set' + dataToUpdate](currentItem);
          this[dataToUpdate] = new itemClass(currentItem);
        }
      }
    }
  }

  public logout() {
    this.menuController.close().catch((err) => this.logger.error(err, 'logout:MenuController:close'));
    // TODO START NEED TO REMOVE IN 2.9.0
    this.securityService.setActiveEmployee(null);
    // TODO END NEED TO REMOVE IN 2.9.0
    this.employeesStateFacade.clearActiveEmployee();
    this.navController
      .navigateRoot(ROUTE_URLS.employee, { animated: true, animationDirection: 'back' })
      .catch((err) => this.logger.error(err, 'logout:navController:navigateRoot:employee'));
  }
}
@NgModule({
  imports: [CommonModule, PersonPlaceholderModule, ImageLoaderCapComponentModule, ImageUrlPipeModule],
  declarations: [CurrentUser],
  exports: [CurrentUser],
})
export class CurrentUserModule {}
