<div class="t-max-height">
  <div #sHeight class="processing-box" [ngClass]="{ 'fade-anim': fadeAnim }">
    <processing-content
      [activeScreen]="activeScreen"
      [terminalMessage]="terminalMsg"
      [retryButtonOff]="retryButtonOff"
      [cancelButtonOff]="cancelButtonOff"
      [checkButtonOff]="checkButtonOff"
      (userAction)="onUserActionEvent($event)"
    ></processing-content>
  </div>
</div>
