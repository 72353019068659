<div class="btn btn-cancel" *ngIf="showCancelButton" (click)="cancelButtonEvent()" tappable>
  {{ cancelButtonTitle | translate }}
</div>
<div class="btn btn-retry" *ngIf="showRetryButton" (click)="retryButtonEvent()" tappable>
  {{ retryButtonTitle | translate }}
</div>
<div class="btn btn-retry" *ngIf="showCheckButton" (click)="checkButtonEvent()" tappable>
  {{ checkButtonTitle | translate }}
</div>
<div class="btn btn-retry" *ngIf="showContinueButton" (click)="continueButtonEvent()" tappable>
  {{ continueButtonTitle | translate }}
</div>
