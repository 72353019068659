export const unpack = (data: string): any => {
  let r = {};

  try {
    r = JSON.parse(String.fromCharCode.apply(null, new Uint16Array(JSON.parse(`[${atob(data)}]`))));
  } catch(e) {
    // console.warn(e);
  }
  return r;
}

