import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ClearOpenSelfOrderInvoices,
  SetCounterOpenSelfOrderInvoices,
  UpdateOpenSelfOrderInvoice,
} from '@pos-stores/invoices/invoices.actions';

export const invoicesFeatureKey = 'invoices';

export interface SelfOrderOpenInvoice {
  storeUuid: string;
  uuids: string[];
}

export interface InvoicesState {
  openSelfOrderInvoices: EntityState<SelfOrderOpenInvoice>
}

export function selectedProductCartUuid(order: SelfOrderOpenInvoice): string {
  return order.storeUuid;
}

export const selfOrderInvoicesAdapter: EntityAdapter<SelfOrderOpenInvoice> = createEntityAdapter<SelfOrderOpenInvoice>({
  selectId: selectedProductCartUuid,
});

export const initialState: InvoicesState = {
  openSelfOrderInvoices: selfOrderInvoicesAdapter.getInitialState({})
};

export const reducerInvoices = createReducer(
  initialState,
  on(SetCounterOpenSelfOrderInvoices, (state: InvoicesState, { entities }) => {
    return {
      ...state,
      openSelfOrderInvoices: selfOrderInvoicesAdapter.setAll(entities, state.openSelfOrderInvoices)
    }
  }),
  on(UpdateOpenSelfOrderInvoice, (state: InvoicesState, { entity }) => {
    return {
      ...state,
      openSelfOrderInvoices: selfOrderInvoicesAdapter.upsertOne(entity, state.openSelfOrderInvoices)
    }
  }),
  on(ClearOpenSelfOrderInvoices, (state: InvoicesState) => {
    return {
      ...state,
      openSelfOrderInvoices: selfOrderInvoicesAdapter.removeAll(state.openSelfOrderInvoices)
    }
  })
);
