import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ClearActiveEmployee,
  LoadedListEmployeesFromLocalDBFailure,
  LoadedListEmployeesFromLocalDBSuccess,
  LoadListEmployeesFromLocalDB,
  SelectActiveEmployee,
  UpdatedListEmployeesSuccess,
  UpdateListEmployees,
} from './employees.actions';
import { Employee } from '@pos-common/classes/employee.class';

export const employeesFeatureKey = 'employees';

export interface EmployeeState extends EntityState<Employee> {
  isLoading: boolean;
  activeEmployeeUuid: string | null;
}

export const employeesPageAdapter: EntityAdapter<Employee> = createEntityAdapter<Employee>({
  selectId: selectedEmployeeUuid,
});

export function selectedEmployeeUuid(employee: Employee): string {
  return employee?.uuid;
}

export const initialState: EmployeeState = employeesPageAdapter.getInitialState({
  isLoading: false,
  activeEmployeeUuid: null
});

export const reducerEmployeesPage = createReducer(
  initialState,
  on(LoadListEmployeesFromLocalDB, (state: EmployeeState) => ({
    ...state,
    isLoading: true
  })),
  on(LoadedListEmployeesFromLocalDBSuccess, (state: EmployeeState, { list }) =>
    employeesPageAdapter.setAll(list, {
      ...state,
      isLoading: false
    })),
  on(LoadedListEmployeesFromLocalDBFailure, (state: EmployeeState) => ({
    ...state,
    isLoading: false
  })),
  on(UpdateListEmployees, (state: EmployeeState) => ({
    ...state,
    isLoading: true
  })),
  on(UpdatedListEmployeesSuccess, (state: EmployeeState, { list }) =>
    employeesPageAdapter.upsertMany(list, {
      ...state,
      isLoading: false
    })),
  on(SelectActiveEmployee, (state: EmployeeState, { employee }) => ({
    ...state,
    activeEmployeeUuid: employee.uuid
  })),
  on(ClearActiveEmployee, (state: EmployeeState) => ({
    ...state,
    activeEmployeeUuid: null
  }))
);
