import { SecurityService } from '@pos-common/services/system/security.service';
import { AdyenTerminalInfo } from './classes';
import { IActiveTerminal } from '@pos-common/interfaces';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { Injectable } from '@angular/core';
import { CommonRequestOptions } from '@pos-common/services/system/adyen/adyen-types';

@Injectable()
export class AdyenUtils {
  constructor(private readonly securityService: SecurityService, private readonly localStorage: LocalStorage) {}

  private generateServiceID(): string {
    const time = new Date().getTime().toString();
    const maxLength = 10;
    return time.length > maxLength ? time.substring(time.length - maxLength) : time;
  }

  private createSaleID(): string {
    const deviceIdentifier = this.securityService.getDeviceIdentifier();
    const company = this.securityService.getLoggedCompanyData();
    return `POS_${company.uuid}_${deviceIdentifier}`;
  }

  private getTerminalInternal(): IActiveTerminal {
    const terminal: IActiveTerminal = this.localStorage.getObject(StorageKeys.activeTerminal);
    if (terminal.name !== 'paymashpay') return undefined;
    return terminal;
  }

  get isPaymashPayTerminal(): boolean {
    return !!this.getTerminal();
  }

  getTerminal(): AdyenTerminalInfo | undefined {
    const terminal: IActiveTerminal = this.getTerminalInternal();
    if (!terminal) return undefined;
    return new AdyenTerminalInfo(terminal.deviceModel, terminal.serialNumber);
  }

  createCommonRequestOptions(): CommonRequestOptions {
    const terminal: AdyenTerminalInfo = this.getTerminal();
    if (!terminal) throw new Error('settings_provide_terminal_info');
    return {
      serviceID: this.generateServiceID(),
      saleID: this.createSaleID(),
      poiID: {
        deviceModel: terminal.deviceModel,
        serialNumber: terminal.serialNumber,
      },
    };
  }
}
